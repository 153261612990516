// @vue/component
import {useGoalDetailsStore} from "@/stores/WheelAnalysis/goalDetails";
import {useCriticalGoalsStore} from "@/stores/WheelAnalysis/criticalGoals";
import {useChallengeStore} from "@/stores/WheelAnalysis/challenge";
import { storeToRefs } from 'pinia'

export default {
    name: 'GoalDetails',

    components: {},

    mixins: [],

    props: {},

    setup() {
        const goalDetailsStore = useGoalDetailsStore()
        const criticalGoalsStore = useCriticalGoalsStore()
        const challengeStore = useChallengeStore()

        const { currentGoalId }=  storeToRefs(goalDetailsStore)
        const { currentTargetId }=  storeToRefs(goalDetailsStore)
        const { currentDetailType } = storeToRefs(goalDetailsStore)

        const { selectedCriticalGoals } = storeToRefs(criticalGoalsStore)
        const { editMode } = storeToRefs(challengeStore)

        return { goalDetailsStore, currentGoalId, currentDetailType, currentTargetId, selectedCriticalGoals, editMode  }

    },
    data () {
        return {
            goalDescription: "",
            targetDescription: "",
            indicators: [],

        }
    },

    computed: {},

    watch: {
        currentGoalId(newGoalId) {
            //console.log(this.goalDetailsStore.allGoalDetails)
            let selectedGoal = this.goalDetailsStore.allGoalDetails.find(goal => goal.id === newGoalId);
            //console.log(selectedGoal)
            this.goalDescription = selectedGoal.description
            this.currentDetailType = "goal"
        },
        currentTargetId(newTargetId) {
            //console.log("new target!")
            //console.log(newTargetId)
            //console.log(this.goalDetailsStore.allTargetDetails)
            //console.log(JSON.stringify(this.goalDetailsStore.allTargetDetails))
            let selectedTarget = this.goalDetailsStore.allTargetDetails.find(target => target.id == newTargetId);
            //console.log(selectedTarget)
            if (selectedTarget !== undefined) {
                this.targetDescription = selectedTarget.name
                this.currentDetailType = "target"
                this.indicators = selectedTarget.indicators
            }

        }

    },
    created () {


    },

    methods: {}
}
