<template>
  <div class="AdditionalGoals">


  <div id="form-div">

    <div class="row">
      <div class="col-md-12 px-4">
        <span>Using your SDG Visualizations</span>
        <div class="sidebar-instructions" style="text-align: left">
          Now you are ready to review the visualization that you have prepared and use it as the basis for participatory discussion with clients, communities and other stakeholders. Here are some questions to guide your discussion:
          <br/>
          <br/>
          1. What potential benefits of addressing this challenge did SDG 360 Thinking reveal? This should include the direct planned <strong>benefits and co-benefits</strong> that you did not see before. (For example, increasing access to nutrition might lead to better performance at school or work).
          <br/>
          <br/>
          2. What contradictions or <strong>unintended negative consequences</strong> of action do you see? How might you take action to make the innovation more effective?
          <br/>
          <br/>
          3. Consider <strong>equity, trade-offs and inequities</strong> associated with addressing this challenge? How can those be eliminated? What compensatory or enhancing measures can be taken so that the effort improves equity as it solves other challenges?
        </div>

        <br/>
        <MDBTextarea :disabled = "!editMode" label="Notes and Analysis" rows="5" v-model="reviewNotes" @blur="allGoalsStore.reviewNotesChanged"/>

      </div>
    </div>
  </div>
</div>

  <router-view />

</template>

<script src="./_ReviewGoals.js" lang="js"></script>
<style src="./_ReviewGoals.less" lang="less"></style>
