// @vue/component
import {useCrosswalkChallengeStore} from "@/stores/CrossWalkAnalysis/challenge";
import {storeToRefs} from "pinia/dist/pinia";

export default {
  name: 'CrosswalkChallengeView',

  components: {},

  mixins: [],

  props: {},

  setup() {
    const crosswalkChallengeStore = useCrosswalkChallengeStore()

    const { challengeType } =  storeToRefs(crosswalkChallengeStore)
    const { planUrl } =  storeToRefs(crosswalkChallengeStore)
    const { editMode } =  storeToRefs(crosswalkChallengeStore)

    return { crosswalkChallengeStore, challengeType, planUrl, editMode }

  },

  computed: {},

  watch: {
  },

  mounted () {
    this.crosswalkChallengeStore.fetchCrosswalkAnalysis()
  },

  methods: {}
}
