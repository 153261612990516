import { useQuery } from '@tanstack/vue-query'
import axios from "axios";
import {useUserInfoStore} from "@/stores/Setup/userInfo";

export default () => {
    const userInfoStore = useUserInfoStore()
    //console.log("setting up usequery")
    return useQuery({
        queryKey: ['userInfo'],
        /*queryFn: async () => {
            //const response = await fetch('https://sdg360thinking/api/v1/user_infos/1'); // TODO: how to get ID in there
            const response = await fetch('https://ll0qg.wiremockapi.cloud/user_infos/1');
            const data = await response.json();
            select: (response) => response.data
            return data;
        },*/
        /*queryFn: () => {
            //const response = await fetch('https://sdg360thinking/api/v1/user_infos/1'); // TODO: how to get ID in there
            axios.call("get", process.env.VUE_APP_API_ROOT + "api/v1/user_infos/" + userInfoStore.userInfoId).then(response => {
                //console.log("queryFn response")
                console.log(response)
                const data = response
                return data
            }).catch(error => {
                console.error("There was an error!", error);
                return error
            })
        },
        select: (response) =>  response.data,*/
        queryFn: async () => {
            const response = await axios.call("get", process.env.VUE_APP_API_ROOT + "api/v1/user_infos/" + userInfoStore.userInfoId)
            //const data = await response.data;
            //console.log("queryFn response")
            console.log(response)
            userInfoStore.firstName = response.data.first_name
            userInfoStore.lastName = response.data.last_name
            userInfoStore.email = response.data.email
            userInfoStore.jobTitle = response.data.job_title
            userInfoStore.city = response.data.city
            userInfoStore.state = response.data.state
            userInfoStore.orgName = response.data.org_name
            userInfoStore.userInfoId = response.data.id
            return response;
        },
        //queryFn: () => axios.call("get", process.env.VUE_APP_API_ROOT + "api/v1/user_infos/" + userInfoStore.userInfoId),
        //select: (response) =>  response.data , // extract data from Axios response
        staleTime: 1000
    })
}
