import { createWebHistory, createRouter } from "vue-router";
import {useUserInfoStore} from "@/stores/Setup/userInfo";

const routes = [
    {
        path: '/',
        name: "LandingView",
        component: () =>
            import("../views/LandingView/LandingView.vue")
    },
    {
        path: '/about',
        name: "AboutView",
        component: () =>
            import("../views/AboutView/AboutView.vue")
    },
    {
        path: "/get-started",
        name: "HomeView",
        component: () =>
            import("../views/HomeView/HomeView.vue")
    },
    {
        path: "/register",
        name: "Register",
        component: () =>
            import("../views/RegisterView/RegisterView.vue")
    },{
        path: "/login",
        name: "Login",
        component: () =>
            import("../views/LoginView/LoginView.vue")
    }
    ,{
        path: "/password/reset",
        name: "PasswordReset",
        component: () =>
            import("../views/PasswordReset/PasswordReset.vue")
    }
    ,{
        path: "/password/update",
        name: "PasswordUpdate",
        component: () =>
            import("../views/PasswordUpdate/PasswordUpdate.vue")
    }
    ,{
        path: "/my-analyses",
        name: "MyAnalyses",
        component: () =>
            import("../views/MyAnalyses/MyAnalyses.vue"),
        meta: { requiresAuth: true }
    }
    ,{
        path: "/wheel_analysis/:id/:mode",
        name: "LoadWheelAnalysis",
        component: () =>
            import("../views/WheelAnalysis/LoadAnalysis/LoadAnalysis.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/crosswalk_analysis/:id/:mode",
        name: "LoadMatrixAnalysis",
        component: () =>
            import("../views/CrossWalkAnalysis/LoadAnalysis/LoadAnalysis.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/using-tool",
        name: "UsingTool",
        component: () =>
            import("../views/Setup/UsingTool/UsingTool.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/wheel/new",
        name: "WheelView",
        component: () =>
            import("../views/WheelAnalysis/WheelView.vue"),
        meta: { requiresAuth: false }
    },
    {
        path: "/about-me",
        name: "AboutMe",
        component: () =>
            import("../views/Setup/AboutMe/AboutMe.vue"),
        meta: { requiresAuth: false }
    },
    {
        path: "/about-org",
        name: "AboutOrg",
        component: () =>
            import("../views/Setup/AboutOrg/AboutOrg.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/challenge",
        name: "ChallengeView",
        component: () =>
            import("../views/WheelAnalysis/ChallengeView/ChallengeView.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/crosswalk-challenge",
        name: "CrosswalkChallengeView",
        component: () =>
            import("../views/CrossWalkAnalysis/ChallengeView/ChallengeView.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/matrix-type",
        name: "MatrixType",
        component: () =>
            import("../views/CrossWalkAnalysis/MatrixType/MatrixType.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/goal-11-targets",
        name: "Goal11Targets",
        component: () =>
            import("../views/WheelAnalysis/Goall11Targets/Goal11Targets.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/critical-goals",
        name: "CriticalGoals",
        component: () =>
            import("../views/WheelAnalysis/CriticalGoals/CriticalGoals.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/goal-a",
        name: "GoalA",
        component: () =>
            import("../views/WheelAnalysis/GoalA/GoalA.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/goal-b",
        name: "GoalB",
        component: () =>
            import("../views/WheelAnalysis/GoalB/GoalB.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/additional-goals",
        name: "AdditionalGoals",
        component: () =>
            import("../views/WheelAnalysis/AdditionalGoals/AdditionalGoals.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/image",
        name: "ImageView",
        component: () =>
            import("../views/WheelAnalysis/ImageView/ImageView.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/keywords",
        name: "KeywordView",
        component: () =>
            import("../views/WheelAnalysis/KeywordView/KeywordView.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/strategy-setup",
        name: "StrategySetup",
        component: () =>
            import("../views/CrossWalkAnalysis/StrategySetup/StrategySetup.vue"),
        meta: { requiresAuth: true }
    },
    {
        path: "/crosswalk/new",
        name: "MatrixView",
        component: () =>
            import("../views/CrossWalkAnalysis/MatrixView/MatrixView.vue"),
        meta: { requiresAuth: false }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {

    const userInfoStore = useUserInfoStore()
    const loggedIn = !!userInfoStore.userId
    //console.log(localStorage)

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!loggedIn) {
            next({
                path: '/login'
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router;
