// @vue/component
import {storeToRefs} from "pinia/dist/pinia";
import {useCrosswalkChallengeStore} from "@/stores/CrossWalkAnalysis/challenge";

export default {
  name: 'CrosswalkAnalysisName',

  components: {},

  mixins: [],

  props: {},

  setup() {
    const challengeStore = useCrosswalkChallengeStore()

    const { name } =  storeToRefs(challengeStore)
    const { editMode } =  storeToRefs(challengeStore)


    return { challengeStore, name, editMode }

  },

  computed: {},

  watch: {
  },

  mounted () {
    this.challengeStore.fetchCrosswalkAnalysis()
  },

  methods: {}
}
