// @vue/component
import {useStrategyDetailsStore} from "@/stores/CrossWalkAnalysis/strategyDetails";
import {useCriticalGoalsStore} from "@/stores/WheelAnalysis/criticalGoals";
import { storeToRefs } from 'pinia'
import {useCrosswalkChallengeStore} from "@/stores/CrossWalkAnalysis/challenge";


export default {
    name: 'StrategyDetails',

    components: {

    },

    mixins: [],

    props: {},

    setup() {
        const strategyDetailsStore = useStrategyDetailsStore()
        const criticalGoalsStore = useCriticalGoalsStore()
        const challengeStore = useCrosswalkChallengeStore()

        const { currentGoalId }=  storeToRefs(strategyDetailsStore)
        const { currentStrategyId }=  storeToRefs(strategyDetailsStore)
        const { currentDetailType } = storeToRefs(strategyDetailsStore)
        const { selectedStrategies } = storeToRefs(strategyDetailsStore)
        const { allStrategyDetails } = storeToRefs(strategyDetailsStore)
        const { selectedTargets } = storeToRefs(strategyDetailsStore)
        const { allTargetDetails } = storeToRefs(strategyDetailsStore)
        const { strategyNotes } = storeToRefs(strategyDetailsStore)
        const { selectedSubstrategies } = storeToRefs(strategyDetailsStore)
        const { matrixType } = storeToRefs(strategyDetailsStore)
        const { goalNumber } = storeToRefs(strategyDetailsStore)
        const { currentTargetId } = storeToRefs(strategyDetailsStore)
        const { editMode } =  storeToRefs(challengeStore)


        return { strategyDetailsStore, currentGoalId, currentDetailType, currentStrategyId, selectedStrategies, allStrategyDetails, criticalGoalsStore, selectedTargets, allTargetDetails, strategyNotes, selectedSubstrategies, matrixType, goalNumber, currentTargetId, editMode  }

    },
    data () {
        return {
            goalDescription: "",
            strategyDescription: "",
            /*strategyHeaderColors: ['rgb(59,98,170)', 'rgb(100,165,190)','rgb(223,134,98)','rgb(105,75,152)','rgb(119,159,98)','rgb(68,71,152)'],*/
            strategyHeaderColors: ['#3b71ca', '#3b71ca','#3b71ca','#3b71ca','#3b71ca','#3b71ca', '#3b71ca',
            '#4493F0'],
            targetAccordion: 1,
            strategyOptions: ["Yes", "No",  "Developing", "For Discussion"]

        }
    },

    computed: {},

    watch: {
        selectedStrategies: {
            deep: true,
            handler(newSelectedStrategies) {
                console.log(newSelectedStrategies)
                this.strategyDetailsStore.selectedStrategiesChanged()
            }
        },
        selectedSubstrategies: {
            deep: true,
            handler() {
                this.strategyDetailsStore.selectedSubstrategiesChanged()
            }
        },
        strategyNotes: {
            deep: true,
            handler() {
                this.strategyDetailsStore.strategyNotesChanged()
            }
        },
        selectedTargets: {
            deep: true,
            handler(newSelectedTargets) {

                //this.criticalGoalsStore.selectedCriticalGoals = newCheckedCriticalGoals
                //console.log("newSelecteTargets:")
                console.log(newSelectedTargets)
                this.strategyDetailsStore.selectedTargetsChanged()
            }
        }
    },
    mounted () {
        this.strategyDetailsStore.fetchCrosswalkAnalysis()
    },

    methods: {
        headerStyles(index) {
            //console.log(index)
            return {
                "background-color": this.strategyHeaderColors[index],
                "color": "white",
                "padding": "20px",
                "margin-bottom": "20px",
                "margin-top": "-35px"
            };
        },
    }
}
