<template>
    <div class="Challenge">
      <div id="form-div">

        <div class="row justify-content-center align-items-center">
          <div class="col-md-10">
        Please express in one sentence the challenge that you hope to work on using SDG 360 Thinking.
          <MDBTextarea :disabled = "!editMode" label="" rows="8" v-model="challengeStatement" @blur = "challengeStore.challengeStatementChanged"
                       />
          </div>
        </div>

        <br/>
        <Br/>
        <!--<div class="sidebar-instructions px-4">What is the problem? What is the future state you would like to see? How will you measure it?</div>-->
        <div class="sidebar-instructions px-4">This challenge statement format is designed to help you to develop a complete and clear challenge statement, and to prompt you to think holistically and include key stakeholders in the conversation. Feel free to modify it as needed for you work.</div>
      </div>


    </div>

  <router-view />
</template>

<script src="./_ChallengeStatement.js" lang="js"></script>
<style src="./_ChallengeStatement.less" lang="less" scoped></style>
