import {useUserInfoStore} from "@/stores/Setup/userInfo";
import {useAnalysisStore} from "@/stores/Setup/analysis";
import {storeToRefs} from "pinia/dist/pinia";
export default {
  name: 'AboutOrg',

  components: {},

  mixins: [],

  props: {},

  setup() {
    const userInfoStore = useUserInfoStore()
    const { orgName } =  storeToRefs(userInfoStore)
    const { firstName } =  storeToRefs(userInfoStore)
    const { lastName } =  storeToRefs(userInfoStore)

    const analysisStore = useAnalysisStore()
    const { analysisType } =  storeToRefs(userInfoStore)
    const { editMode } = storeToRefs(userInfoStore)

    return { userInfoStore, orgName, analysisStore, analysisType, firstName, lastName, editMode}
  },

  computed: {},

  watch: {
  },

  mounted () {
    this.userInfoStore.fetchUserInfo()
  },
  methods: {}
}