// @vue/component
import {useAllGoalsStore} from "@/stores/WheelAnalysis/allGoals";
import {storeToRefs} from "pinia/dist/pinia";
import {useChallengeStore} from "@/stores/WheelAnalysis/challenge";

export default {
  name: 'ReviewGoals',

  components: {},

  mixins: [],

  props: {},

  setup() {
    const allGoalsStore = useAllGoalsStore()
    const challengeStore = useChallengeStore()

    const { reviewNotes } =  storeToRefs(allGoalsStore)
    const { editMode } =  storeToRefs(challengeStore)

    return { reviewNotes, allGoalsStore, editMode }
  },


  computed: {},

  mounted () {
    this.allGoalsStore.fetchWheelAnalysis()
  },

  methods: {}
}
