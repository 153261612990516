<template>
  <div class="AdditionalGoals PrimaryGoals">

  <div id="form-div">

    <div class="row justify-content-left align-items-left">
      <div class="col-md-12 px-4">
        <span v-if="primaryGoalsViewMode === 'primary'" >Determine the primary SDG and most relevant sub-targets</span>
        <span v-if="primaryGoalsViewMode === 'secondary'" >Select 1 or 2 secondary SDGs and the most relevant sub-targets</span>
        <span v-if="primaryGoalsViewMode === 'sweep'" >Do a sweep of the remaining SDGs for additional relevant sub-targets</span>
        <span v-if="primaryGoalsViewMode === 'review'" >Review and the selected list of sub-targets</span>

        <div v-if="primaryGoalsViewMode === 'primary'" class="sidebar-instructions">Begin with the SDG that is most relevant for your challenge. Indicate which of the sub-targets you want to take into account as you analyze the problem, and develop measures related to it.</div>
        <div v-if="primaryGoalsViewMode === 'secondary'" class="sidebar-instructions">Most complex challenges relate to a critical cluster of SDGs rather than just one. Identify 1 or two more SDGs and indicate which of the sub-targets you want to take into account as you analyze the problem, and develop measures related to it.</div>
        <div v-if="primaryGoalsViewMode === 'sweep'" class="sidebar-instructions">SDG 360 Thinking helps you to develop a systemic perspective on your challenge. Do a quick review of the other SDGs to see if there are additional relevant sub-targets. This step does not usually lead to a large number of new sub-targets, but can help you to make new connections that you might not have seen before.</div>
        <div v-if="primaryGoalsViewMode === 'review'" class="sidebar-instructions">Now that you have taken all the SDGs into account, you will want to review your work to choose the most relevant sub-targets. Remove targets that are redundant or less important. Add targets that seem important now that you have reviewed the whole framework. Try to get your list to 10 to 25 targets. This will be manageable, but still support complex analysis.</div>

        <MDBAccordion v-model="activeGoalItem" borderless>
          <div v-for="(goal, index) in goals" :key="goal.id" >
            <div>
              <MDBAccordionItem
                  :headerTitle="goal.name"
                  :collapseId="'collapse' + goal.id"
                  :icon="'icon-sdg icon-sdg-' + String(goal.id).padStart(2, '0')"
              >

                <MDBCheckbox :disabled = "!editMode" v-model="selectedGoals[index]" :id="goal.id" :label="goal.id + ': ' + goal.description" size="large" style="font-weight:bold" />
                <br/>
                <strong>Targets:</strong>
                <div v-for="(target, targetIndex) in goal.targets" :key="target.id" >

                  <MDBCheckbox :disabled = "!editMode" v-model="selectedTargets[index][targetIndex]" :id="target.id" :label="target.id + ': ' + target.name" size="small" />
                </div>

              </MDBAccordionItem>
            </div>
          </div>

        </MDBAccordion>
      </div>
    </div>

    <!-- <div class="row justify-content-left align-items-left">
       <div class="offset-3 col-md-6">
       <span>(OPTIONAL) You have covered a lot of ground by starting with 5 critical SDGS. If you would like to do a quick sweep of the other 12 to select items that may be relevant, please check all that apply from the lists here. Taking this optional step will take a bit more time, but will lead you to a more comprehensive and detailed analysis. It may help you to see connections that are less obvious and identify co-benefits or unintended negative consequences. (This step can be taken at a later time, and all responses can be revised and refined as you discuss the results with others.)</span>
         <MDBAccordion v-model="activeGoalItem" borderless>


           <MDBAccordionItem
               headerTitle="Accordion Item #3"
               collapseId="collapseThree"
           >
             <strong>This is the third item's accordion body.</strong> It is
             hidden by default, until the collapse plugin adds the
             appropriate classes that we use to style each element. These
             classes control the overall appearance, as well as the showing
             and hiding via CSS transitions. You can modify any of this with
             custom CSS or overriding our default variables. It's also worth
             noting that just about any HTML can go within the
             MDBAccordionItem, though the transition does limit
             overflow.
           </MDBAccordionItem>
         </MDBAccordion>
         <div v-for="(goal, index) in goals" :key="goal.id" >
           <div class="row justify-content-center align-items-center">
             <div class="el-col-offset-2 el-col-md-6">
               <MDBAccordionItem
                   :headerTitle="goal.name"
                   collapseId="collapse1"
               >
                 <strong>This is the first item's accordion body.</strong>
                 <MDBCheckbox v-model="checkedGoals[index]" :id="goal.id" :label="goal.name" size="large"/>
               </MDBAccordionItem>

             </div>
           </div>
         </div>
       </div>
     </div>-->

  </div>
</div>

  <router-view />

</template>

<script src="./_PrimaryGoals.js" lang="js"></script>
<style src="./_PrimaryGoals.less" lang="less"></style>
