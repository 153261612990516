import {useStrategyDetailsStore} from "@/stores/CrossWalkAnalysis/strategyDetails";
import {useCrosswalkChallengeStore} from "@/stores/CrossWalkAnalysis/challenge";
import {storeToRefs} from "pinia/dist/pinia";



export default {
    name: 'CrosswalkLegend',
    components: {},

    mixins: [],

    props: {},

    setup() {
        const strategyDetailsStore = useStrategyDetailsStore()
        const challengeStore = useCrosswalkChallengeStore()

        const { allStrategyDetails } =  storeToRefs(strategyDetailsStore)
        const { selectedStrategies } =  storeToRefs(strategyDetailsStore)
        const { matrixType } =  storeToRefs(strategyDetailsStore)
        const { goalNumber } =  storeToRefs(strategyDetailsStore)
        const { currentTargetId } =  storeToRefs(strategyDetailsStore)
        const { challengeType } =  storeToRefs(challengeStore)
        const { editMode } =  storeToRefs(challengeStore)

        return { strategyDetailsStore, allStrategyDetails, selectedStrategies, matrixType, goalNumber, currentTargetId, challengeType, editMode }
    },

    mounted() {

    }
};