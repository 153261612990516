<template>
    <div class="AdditionalGoals AllGoals">
        <div id="form-div">

          <div class="row justify-content-left align-items-left">
            <div class="col-md-12">
              <MDBAccordion v-model="activeGoalItem" borderless>
                <div v-for="(goal, index) in goals" :key="goal.id" >

                  <div v-if="goal.id === currentGoalId" class="offset-1 col-md-10">
                  <div>
                    <!--<MDBAccordionItem
                        :headerTitle="goal.name"
                        :collapseId="'collapse' + goal.id"
                        :icon="'icon-sdg icon-sdg-' + String(goal.id).padStart(2, '0')"
                    >

                      <span>{{goal.description}}</span>
                      <br/>-->

                    <img :src="'/images/E-WEB-Goal-' + String(currentGoalId).padStart(2, '0')  + '.png'" style="height:100px; width:auto; ">
                    <br/><br/><strong>Targets:</strong>
                    <br/>
                      <div v-for="(target, targetIndex) in goal.targets" :key="target.id" >
                        <MDBCheckbox :disabled = "!editMode" v-model="selectedTargets[index][targetIndex]" :id="target.id" :label="target.id + ': ' + target.name" size="small" />
                      </div>

                    <!--</MDBAccordionItem>-->
                  </div>
                  </div>
                </div>

              </MDBAccordion>
            </div>
          </div>


        </div>
    </div>
</template>

<script src="./_AllGoals.js" lang="js"></script>
<style src="./_AllGoals.less" lang="less"></style>


