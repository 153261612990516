<template>
  <div class="AboutOrg px-4">
    <!--<div id="title-header-div" >
      <br>
      <h1>About My Organization</h1>
      <br>
    </div>-->



    <div class="">Identify yourself, your organization, or the community you are serving</div>

    <br/>
    <div class="col-md-12">
      <i class="icon icon-profile"></i>
      <label for="first-name">
        Name
      </label>
    </div>

    <div class="row ">
      <div class="col-md-6">
        <input
            v-model="firstName"
            type="text"
            id="first-name"
            name="firstName"
            @blur="userInfoStore.exitedField"
            @change="userInfoStore.firstNameChanged"
            value
            placeholder="First Name"
            class="col-md-12"
            :disabled = "!editMode"
        />
      </div>

      <div class="col-md-6">
        <input
            v-model="lastName"
            type="text"
            id="last-name"
            name="lastName"
            @blur = "userInfoStore.exitedField"
            @change = "userInfoStore.lastNameChanged"
            :disabled = "!editMode"
            value
            placeholder="Last Name"
            class="col-md-12"
        />
      </div>
    </div>
    <br/>

      <div class="row justify-content-center align-items-center">
        <div class="col-md-12">
          <i class="icon icon-profile"></i>
          <label for="org-name">
            Community/Organization Name
          </label>
        </div>
        <div class="col-md-12">
          <input
              v-model="orgName"
              type="text"
              id="org-name"
              name="orgName"
              @blur="userInfoStore.exitedField"
              @change="userInfoStore.orgNameChanged"
              :disabled = "!editMode"
              value
              placeholder="City Name"
              class="col-md-12"
          />
        </div>
      </div>

    <br/>
    <br/>
      <div class="sidebar-instructions p-4">
      This is a place-based approach, where you are encouraged to focus on work that is in your scope of responsibilities and expertise.
      </div>

      <!--<div v-if="analysisType==='CrossWalk'">
        <div class="prev-next">
          <a href="/about-me" class="btn btn-primary">Previous</a>
          <a href="/crosswalk-challenge" class="btn btn-primary">Next</a>
        </div>
      </div>
      <div v-else>
        <div class="prev-next">
          <a href="/about-me" class="btn btn-primary">Previous</a>
          <a href="/challenge" class="btn btn-primary">Next</a>
        </div>
      </div>-->

  </div>
</template>

<script src="./_AboutOrg.js" lang="js"></script>
<style src="./_AboutOrg.less" lang="less" scoped></style>
