<template>
    <div class="RightSideBar">
      <div id= "RightSideBarContainer" class="RightSideBarContainer" style="">
      <div v-if="viewMode === 'wheel' && (rightSidebarView === 'goal' || rightSidebarView == 'target')">
      <MDBTabs v-model="activeTabId1">
        <!-- Tabs navs -->
        <MDBTabNav fill tabsClasses="mb-2">
          <MDBTabItem tabId="details" href="details">{{ goalOrTarget }} Details</MDBTabItem>
          <MDBTabItem tabId="selections" href="selections">Target Selections</MDBTabItem>
        </MDBTabNav>
        <!-- Tabs content -->
        <MDBTabContent>
          <MDBTabPane tabId="details">
           <GoalDetails>

           </GoalDetails>
          </MDBTabPane>
          <MDBTabPane tabId="selections">
            <AllGoals>

            </AllGoals>

          </MDBTabPane>
        </MDBTabContent>
        <!-- Tabs content -->
      </MDBTabs>
      </div>

        <div v-if="rightSidebarView === 'AnalysisName' && viewMode === 'wheel' ">
          <MDBTabs v-model="activeTabId1">
            <!-- Tabs navs -->
            <MDBTabNav fill tabsClasses="mb-2">
              <MDBTabItem tabId="selections" href="details">Analysis Name</MDBTabItem>
            </MDBTabNav>
            <!-- Tabs content -->
            <MDBTabContent>
              <MDBTabPane tabId="selections">
                <AnalysisName></AnalysisName>
              </MDBTabPane>
            </MDBTabContent>
          </MDBTabs>
        </div>

        <div v-if="rightSidebarView === 'AnalysisName' && viewMode === 'matrix' ">
          <MDBTabs v-model="activeTabId1">
            <!-- Tabs navs -->
            <MDBTabNav fill tabsClasses="mb-2">
              <MDBTabItem tabId="selections" href="details">Analysis Name</MDBTabItem>
            </MDBTabNav>
            <!-- Tabs content -->
            <MDBTabContent>
              <MDBTabPane tabId="selections">
                <CrosswalkAnalysisName></CrosswalkAnalysisName>
              </MDBTabPane>
            </MDBTabContent>
          </MDBTabs>
        </div>

        <div v-if="viewMode === 'matrix' && rightSidebarView === 'StrategyDetails' ">
          <MDBTabs v-model="activeTabId1">
            <!-- Tabs navs -->
            <MDBTabNav fill tabsClasses="mb-2">
              <MDBTabItem tabId="selections" href="details">Details</MDBTabItem>
            </MDBTabNav>
            <!-- Tabs content -->
            <MDBTabContent>
              <MDBTabPane tabId="selections">
                <StrategyDetails></StrategyDetails>
              </MDBTabPane>
            </MDBTabContent>
          </MDBTabs>
        </div>

        <div v-if="viewMode === 'matrix' && (rightSidebarView === 'SingleStrategySetup' || strategySetupMode === true) && rightSidebarView !== 'Legend'">
          <MDBTabs v-model="activeTabId1">
            <!-- Tabs navs -->
            <MDBTabNav fill tabsClasses="mb-2">
              <MDBTabItem tabId="selections" href="details">Strategy Setup</MDBTabItem>
            </MDBTabNav>
            <!-- Tabs content -->
            <MDBTabContent>
              <MDBTabPane tabId="selections">
                <SingleStrategySetup></SingleStrategySetup>
              </MDBTabPane>
            </MDBTabContent>
          </MDBTabs>

        </div>

        <div v-if="viewMode === 'matrix' && rightSidebarView === 'Legend' ">
          <MDBTabs v-model="activeTabId1">
            <!-- Tabs navs -->
            <MDBTabNav fill tabsClasses="mb-2">
              <MDBTabItem tabId="selections" href="details">Legend</MDBTabItem>
            </MDBTabNav>
            <!-- Tabs content -->
            <MDBTabContent>
              <MDBTabPane tabId="selections">
                <CrosswalkLegend></CrosswalkLegend>
              </MDBTabPane>
            </MDBTabContent>
          </MDBTabs>
        </div>

        <div v-if="viewMode === 'wheel' && rightSidebarView === 'Legend' ">
          <MDBTabs v-model="activeTabId1">
            <!-- Tabs navs -->
            <MDBTabNav fill tabsClasses="mb-2">
              <MDBTabItem tabId="selections" href="details">Legend</MDBTabItem>
            </MDBTabNav>
            <!-- Tabs content -->
            <MDBTabContent>
              <MDBTabPane tabId="selections">
                <WheelLegend></WheelLegend>
              </MDBTabPane>
            </MDBTabContent>
          </MDBTabs>
        </div>

        <div v-if="viewMode === 'matrix' && (rightSidebarView === 'ChallengeType' )">
          <MDBTabs v-model="activeTabId1">
            <!-- Tabs navs -->
            <MDBTabNav fill tabsClasses="mb-2">
              <MDBTabItem tabId="selections" href="details">Plan Type</MDBTabItem>
            </MDBTabNav>
            <!-- Tabs content -->
            <MDBTabContent>
              <MDBTabPane tabId="selections">
                <ChallengeView></ChallengeView>
              </MDBTabPane>
            </MDBTabContent>
          </MDBTabs>

        </div>

        <div v-if="rightSidebarView === 'ImageView'">
          <MDBTabs v-model="activeTabId1">
            <!-- Tabs navs -->
            <MDBTabNav fill tabsClasses="mb-2">
              <MDBTabItem tabId="selections" href="details">Image</MDBTabItem>
            </MDBTabNav>
            <!-- Tabs content -->
            <MDBTabContent>
              <MDBTabPane tabId="selections">
                <ImageView></ImageView>
              </MDBTabPane>
            </MDBTabContent>
          </MDBTabs>

        </div>

        <div v-if="rightSidebarView === 'AboutOrg'">
          <MDBTabs v-model="activeTabId1">
            <!-- Tabs navs -->
            <MDBTabNav fill tabsClasses="mb-2">
              <MDBTabItem tabId="selections" href="details">Organization</MDBTabItem>
            </MDBTabNav>
            <!-- Tabs content -->
            <MDBTabContent>
              <MDBTabPane tabId="selections">
                <AboutOrg></AboutOrg>
              </MDBTabPane>
            </MDBTabContent>
          </MDBTabs>

        </div>
        <div v-if="rightSidebarView === 'KeywordView'">
          <MDBTabs v-model="activeTabId1">
            <!-- Tabs navs -->
            <MDBTabNav fill tabsClasses="mb-2">
              <MDBTabItem tabId="selections" href="details">Keywords</MDBTabItem>
            </MDBTabNav>
            <!-- Tabs content -->
            <MDBTabContent>
              <MDBTabPane tabId="selections">
                <KeywordView></KeywordView>
              </MDBTabPane>
            </MDBTabContent>
          </MDBTabs>

        </div>
        <div v-if="viewMode !== 'matrix' && rightSidebarView === 'ChallengeType'">
          <MDBTabs v-model="activeTabId1">
            <!-- Tabs navs -->
            <MDBTabNav fill tabsClasses="mb-2">
              <MDBTabItem tabId="selections" href="details">Challenge Type</MDBTabItem>
            </MDBTabNav>
            <!-- Tabs content -->
            <MDBTabContent>
              <MDBTabPane tabId="selections">
                <ChallengeType></ChallengeType>
              </MDBTabPane>
            </MDBTabContent>
          </MDBTabs>

        </div>

        <div v-if="rightSidebarView === 'ReviewWheel'">
          <MDBTabs v-model="activeTabId1">
            <!-- Tabs navs -->
            <MDBTabNav fill tabsClasses="mb-2">
              <MDBTabItem tabId="selections" href="details">Review Visualization</MDBTabItem>
            </MDBTabNav>
            <!-- Tabs content -->
            <MDBTabContent>
              <MDBTabPane tabId="selections">
                <ReviewGoals></ReviewGoals>
              </MDBTabPane>
            </MDBTabContent>
          </MDBTabs>

        </div>
        <div v-if="rightSidebarView === 'ChallengeStatement'">
          <MDBTabs v-model="activeTabId1">
            <!-- Tabs navs -->
            <MDBTabNav fill tabsClasses="mb-2">
              <MDBTabItem tabId="selections" href="details">Challenge Statement</MDBTabItem>
            </MDBTabNav>
            <!-- Tabs content -->
            <MDBTabContent>
              <MDBTabPane tabId="selections">
                <ChallengeStatement></ChallengeStatement>
              </MDBTabPane>
            </MDBTabContent>
          </MDBTabs>

        </div>
        <div v-if="viewMode === 'matrix' && (rightSidebarView === 'MatrixType' )">
          <MDBTabs v-model="activeTabId1">
            <!-- Tabs navs -->
            <MDBTabNav fill tabsClasses="mb-2">
              <MDBTabItem tabId="selections" href="details">Analysis Options</MDBTabItem>
            </MDBTabNav>
            <!-- Tabs content -->
            <MDBTabContent>
              <MDBTabPane tabId="selections">
                <MatrixType></MatrixType>
              </MDBTabPane>
            </MDBTabContent>
          </MDBTabs>

        </div>
        <div v-if="rightSidebarView === 'AboutMe'">
          <MDBTabs v-model="activeTabId1">
            <!-- Tabs navs -->
            <MDBTabNav fill tabsClasses="mb-2">
              <MDBTabItem tabId="selections" href="details">About Me</MDBTabItem>
            </MDBTabNav>
            <!-- Tabs content -->
            <MDBTabContent>
              <MDBTabPane tabId="selections">
                <AboutMe></AboutMe>
              </MDBTabPane>
            </MDBTabContent>
          </MDBTabs>

        </div>

        <div v-if="rightSidebarView === 'PrimaryGoals'">
          <MDBTabs v-model="activeTabId1">
            <!-- Tabs navs -->
            <MDBTabNav fill tabsClasses="mb-2">
              <MDBTabItem tabId="selections" href="details">{{primaryGoalTitle}}</MDBTabItem>
            </MDBTabNav>
            <!-- Tabs content -->
            <MDBTabContent>
              <MDBTabPane tabId="selections">
                <PrimaryGoals></PrimaryGoals>
              </MDBTabPane>
            </MDBTabContent>
          </MDBTabs>

        </div>

        <div class="prev-next">
          <a v-if="showPrevious"  href="#" @click="clickPrevious" class="btn btn-outline-primary">Previous</a>
          <a v-if="showNext" href="#" @click="clickNext" class="btn btn-primary">Next</a>
        </div>

        <br/>
        <br/>

    </div>

      <br/>
      <br/>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import AllGoals from '@/views/WheelAnalysis/AllGoals/AllGoals.vue';
    import PrimaryGoals from '@/views/WheelAnalysis/PrimaryGoals/PrimaryGoals.vue';
    import GoalDetails from '@/views/WheelAnalysis/GoalDetails/GoalDetails.vue';
    import StrategyDetails from '@/views/CrossWalkAnalysis/StrategyDetails/StrategyDetails.vue';
    import SingleStrategySetup from '@/views/CrossWalkAnalysis/SingleStrategySetup/SingleStrategySetup.vue';
    import { useGoalDetailsStore } from '@/stores/WheelAnalysis/goalDetails'
    import { useAllGoalsStore } from '@/stores/WheelAnalysis/allGoals'
    import { useStrategyDetailsStore } from '@/stores/CrossWalkAnalysis/strategyDetails'
    import ChallengeView from "@/views/CrossWalkAnalysis/ChallengeView/ChallengeView.vue";
    import AboutOrg from "@/views/Setup/AboutOrg/AboutOrg.vue";
    import MatrixType from "@/views/CrossWalkAnalysis/MatrixType/MatrixType.vue";
    import AboutMe from "@/views/Setup/AboutMeSidebar/AboutMe.vue";
    import ChallengeType from "@/views/WheelAnalysis/ChallengeType/ChallengeType.vue";
    import ChallengeStatement from "@/views/WheelAnalysis/ChallengeStatement/ChallengeStatement.vue";
    import KeywordView from "@/views/WheelAnalysis/KeywordView/KeywordView.vue";
    import ImageView from "@/views/WheelAnalysis/ImageView/ImageView.vue";
    import CrosswalkLegend from "@/views/CrossWalkAnalysis/CrosswalkLegend/CrosswalkLegend.vue";
    import {storeToRefs} from "pinia/dist/pinia";
    import WheelLegend from "@/views/WheelAnalysis/WheelLegend/WheelLegend.vue";
    import ReviewGoals from "@/views/WheelAnalysis/ReviewGoals/ReviewGoals.vue";
    import AnalysisName from "@/views/WheelAnalysis/AnalysisName/AnalysisName.vue";
    import CrosswalkAnalysisName from "@/views/CrossWalkAnalysis/CrosswalkAnalysisName/CrosswalkAnalysisName.vue";


    export default {
      components: {
        CrosswalkAnalysisName,
        AnalysisName,
        ReviewGoals,
        WheelLegend,
        CrosswalkLegend,
        ImageView,
        AboutOrg,
        ChallengeView,
        AllGoals, PrimaryGoals, GoalDetails, StrategyDetails, SingleStrategySetup,
        MatrixType,
        AboutMe,
        ChallengeType,
        ChallengeStatement,
        KeywordView
      },
        name: 'RightSideBar',
        data () {
          return {
            goalOrTarget: "Target",
            strategySetupMode: false,
            setupStrategyId: 0,
            primaryGoalTitle: ""


          }
        },
      setup() {
        const strategyDetailsStore = useStrategyDetailsStore()
        const { rightSidebarView } = storeToRefs(strategyDetailsStore)

        const goalDetailsStore = useGoalDetailsStore()
        const allGoalsStore = useAllGoalsStore()
        const activeTabId1 = ref('selections');

        const { showNext } = storeToRefs(strategyDetailsStore)
        const { showPrevious } = storeToRefs(strategyDetailsStore)
        const { nextPage } = storeToRefs(strategyDetailsStore)
        const { previousPage } = storeToRefs(strategyDetailsStore)

        const { primaryGoalsViewMode } = storeToRefs(allGoalsStore)
        const { nextPrimaryGoalsViewMode } = storeToRefs(allGoalsStore)
        const { previousPrimaryGoalsViewMode } = storeToRefs(allGoalsStore)


        return {strategyDetailsStore, rightSidebarView, goalDetailsStore, activeTabId1, showNext, showPrevious, nextPage, previousPage, primaryGoalsViewMode, nextPrimaryGoalsViewMode, previousPrimaryGoalsViewMode}
      },
        mounted () {


          this.nextPage = "AboutOrg"
          this.rightSidebarView = "ChallengeType"
          this.nextPrimaryGoalsViewMode = "primary"
          // if goal details change, set details tab to active
          this.goalDetailsStore.$subscribe(() => {
            this.activeTabId1 = ref('details');
            this.goalOrTarget = this.goalDetailsStore.currentDetailType

          }, {detached: true})


          this.strategyDetailsStore.$subscribe(() => {
            //alert("callback!")
            this.activeTabId1 = ref('selections');
            this.strategySetupMode = this.strategyDetailsStore.strategySetupMode
            this.setupStrategyId = this.strategyDetailsStore.setupStrategyId

          }, {detached: true})
        },
      methods: {
        clickNext() {
          this.showNext = true
          this.showPrevious = true
          this.rightSidebarView = this.nextPage
          if (this.viewMode.toLowerCase() === "wheel") {
            switch (this.rightSidebarView) {
              case "ChallengeType":
                this.nextPage = "AboutOrg"
                this.previousPage = ""
                break;
              case "AboutOrg":
                this.nextPage = "ChallengeStatement"
                this.previousPage = "ChallengeType"
                break;
              case "ChallengeStatement":
                this.nextPage = "KeywordView"
                this.previousPage = "AboutOrg"
                break;
              case "KeywordView":
                this.nextPrimaryGoalsViewMode = "primary"
                this.nextPage = "ImageView"
                this.previousPage = "ChallengeStatement"
                break;
              case "ImageView":
                this.nextPrimaryGoalsViewMode = "primary"
                this.nextPage = "PrimaryGoals"
                this.previousPage = "KeywordView"
                break;
              case "PrimaryGoals":
                this.primaryGoalsViewMode = this.nextPrimaryGoalsViewMode
                if (this.primaryGoalsViewMode === "primary") {
                  this.nextPrimaryGoalsViewMode = "secondary"
                  this.previousPrimaryGoalsViewMode = ""
                  this.primaryGoalTitle = "Primary SDG"
                  this.nextPage = "PrimaryGoals"
                  this.previousPage = "ImageView"
                  scroll(0, 0)
                } else if (this.primaryGoalsViewMode === "secondary") {
                  this.nextPrimaryGoalsViewMode = "sweep"
                  this.previousPrimaryGoalsViewMode = "primary"
                  this.primaryGoalTitle = "Secondary SDGs"
                  this.nextPage = "PrimaryGoals"
                  this.previousPage = "PrimaryGoals"
                  scroll(0, 0)
                } else if (this.primaryGoalsViewMode === "sweep") {
                  this.nextPrimaryGoalsViewMode = "review"
                  this.previousPrimaryGoalsViewMode = "secondary"
                  this.primaryGoalTitle = "Additional Targets"
                  this.nextPage = "PrimaryGoals"
                  this.previousPage = "PrimaryGoals"
                  scroll(0, 0)
                } else if (this.primaryGoalsViewMode === "review") {
                  this.nextPrimaryGoalsViewMode = "primary"
                  this.previousPrimaryGoalsViewMode = "sweep"
                  this.primaryGoalTitle = "Review SDGs"
                  this.nextPage = "ReviewWheel"
                  this.previousPage = "PrimaryGoals"
                }
                break;
              case "ReviewWheel":
                this.nextPrimaryGoalsViewMode = "primary"
                this.nextPage = "PrimaryGoals"
                this.previousPage = "PrimaryGoals"
                this.showNext = false
                break;
              case "AnalysisName":
                this.nextPrimaryGoalsViewMode = ""
                this.nextPage = ""
                this.previousPage = ""
                this.showNext = false
                break;
              case "CrosswalkAnalysisName":
                this.nextPrimaryGoalsViewMode = ""
                this.nextPage = ""
                this.previousPage = ""
                this.showNext = false
                break;
            }
          }
        },
        clickPrevious() {
          this.showNext = true
          this.showPrevious = true
          this.rightSidebarView = this.previousPage
          if (this.viewMode.toLowerCase() === "wheel") {
            switch (this.rightSidebarView) {
              case "ChallengeType":
                this.nextPage = "AboutOrg"
                this.previousPage = ""
                this.showPrevious = false
                break;
              case "AboutOrg":
                this.nextPage = "ChallengeStatement"
                this.previousPage = "ChallengeType"
                break;
              case "ChallengeStatement":
                this.nextPage = "KeywordView"
                this.previousPage = "AboutOrg"
                break;
              case "KeywordView":
                this.nextPrimaryGoalsViewMode = "primary"
                this.nextPage = "ImageView"
                this.previousPage = "ChallengeStatement"
                break;
              case "ImageView":
                this.nextPrimaryGoalsViewMode = "primary"
                this.nextPage = "PrimaryGoals"
                this.previousPage = "KeywordView"
                break;
              case "PrimaryGoals":
                this.primaryGoalsViewMode = this.previousPrimaryGoalsViewMode
                if (this.primaryGoalsViewMode === "primary") {
                  this.nextPrimaryGoalsViewMode = "secondary"
                  this.previousPrimaryGoalsViewMode = ""
                  this.primaryGoalTitle = "Primary SDG"
                  this.nextPage = "PrimaryGoals"
                  this.previousPage = "ImageView"
                  scroll(0, 0)
                } else if (this.primaryGoalsViewMode === "secondary") {
                  this.nextPrimaryGoalsViewMode = "sweep"
                  this.previousPrimaryGoalsViewMode = "primary"
                  this.primaryGoalTitle = "Secondary SDGs"
                  this.nextPage = "PrimaryGoals"
                  this.previousPage = "PrimaryGoals"
                  scroll(0, 0)
                } else if (this.primaryGoalsViewMode === "sweep") {
                  this.nextPrimaryGoalsViewMode = "review"
                  this.previousPrimaryGoalsViewMode = "secondary"
                  this.primaryGoalTitle = "Additional Targets"
                  this.nextPage = "PrimaryGoals"
                  this.previousPage = "PrimaryGoals"
                  scroll(0, 0)
                } else if (this.primaryGoalsViewMode === "review") {
                  this.nextPrimaryGoalsViewMode = "primary"
                  this.previousPrimaryGoalsViewMode = "sweep"
                  this.primaryGoalTitle = "Review SDGs"
                  this.nextPage = "ReviewWheel"
                  this.previousPage = "PrimaryGoals"
                }
                break;
              case "ReviewWheel":
                this.nextPrimaryGoalsViewMode = "primary"
                this.nextPage = "PrimaryGoals"
                this.previousPage = "PrimaryGoals"
                this.showNext = false
                break;
              case "AnalysisName":
                this.nextPrimaryGoalsViewMode = ""
                this.nextPage = ""
                this.previousPage = "ReviewWheel"
                this.showNext = false
                break;
              case "CrosswalkAnalysisName":
                this.nextPrimaryGoalsViewMode = ""
                this.nextPage = ""
                this.previousPage = ""
                this.showNext = false
                break;
            }
          }

        },
      },
      props: {
        viewMode: {
          default: "wheel",
        },
      }

    };
</script>
