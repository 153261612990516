<template>
  <div class="CrosswalkLegend">
    <div class="legend-body">
      <div class="legend-inner-body" style="text-align:left">
        <div v-for="(goal, index) in goals"  v-bind:key="index" class="px-5 " >
          <div v-if="selectedTargets[index].indexOf(true) !== -1">
            <div class="" style="float:left; padding-top: 10px; width:100%; font-weight:500; font-size: 16px; line-height:18px" ><img :src="'/images/E-WEB-Goal-' + String(goal.id).padStart(2, '0')  + '.png'" style="height:40px; width:auto; margin-left:-10px; margin-right:10px "/> {{goal.name}} </div>
            <div v-for="(target, targetindex) in goal.targets"  v-bind:key="targetindex" class="px-5" >
              <div v-if="selectedTargets[index][targetindex]">
                <div class="" style="float:left;font-size: 12px; line-height:14px" >{{target.number}}: {{target.name}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script src="./_WheelLegend.js" lang="js"></script>
<style src="./_WheelLegend.scss" lang="scss"></style>



