<template>
    <div class="StrategyDetails">
          <div class="row justify-content-left align-items-left">
            <div class="col-md-12">
              <br/>
              <div v-show="currentDetailType==='goal'">
                <div class="">
                <div class="offset-2 col-md-8">
                <!--<img :src="'/sdg360/images/E-WEB-Goal-' + String(currentGoalId).padStart(2, '0')  + '.png'" style="height:120px; width:auto; ">-->
                </div>
                <div class="offset-1 col-md-11" style="text-align:left">
                  <br/>
                  <strong>Goal {{ currentGoalId }}:</strong> {{ goalDescription}}
                </div>
                </div>
                <br/>
                <div class="" >
                <!--<a :href="'https://sdgs.un.org/goals/goal'+ currentGoalId" target="_blank" class="btn btn-secondary">More Info</a>-->
                </div>
                <br/>
                <!--<div class="">
                  <div class="offset-1 col-md-11" style="text-align:left">
                    Related Challenges:<br/>
                    Related Keywords:
                  </div>
                </div>-->
              </div>
              <div v-if="allStrategyDetails">
              <div v-if="currentDetailType==='strategy' && allStrategyDetails[currentStrategyId]" >

                <div :style="headerStyles(currentStrategyId)">{{allStrategyDetails[currentStrategyId].name}}</div>
                <div class="row justify-content-center align-items-center">
                  <div v-if="matrixType === 'Targets' && typeof goalNumber === 'string' && criticalGoalsStore.goals[this.goalNumber - 1].targets[currentTargetId] !== undefined">
                    <img :src="'/images/GOAL_' + goalNumber + '_TARGET_'  + criticalGoalsStore.goals[this.goalNumber - 1].targets[currentTargetId].number.toUpperCase()  + '.png'" style="height:100px; width:auto; margin-bottom: 10px"/>
                  </div>
                  <div v-else>
                    <img :src="'/images/E-WEB-Goal-' + String(parseInt(currentGoalId) + 1).padStart(2, '0')  + '.png'" style="height:100px; width:auto; margin-bottom: 10px" >
                  </div>
                  <div class="" style="padding-left:20px">
                    <div v-if="matrixType=== 'Targets' && criticalGoalsStore.goals[this.goalNumber - 1].targets[currentTargetId] !== undefined" >
                    <strong>{{criticalGoalsStore.goals[this.goalNumber - 1].targets[currentTargetId].number}}: {{criticalGoalsStore.goals[this.goalNumber - 1].targets[currentTargetId].name}}</strong>
                    </div>
                    <div v-else>
                    <strong>{{criticalGoalsStore.goals[currentGoalId].name}}</strong>
                    </div>
                    <br/>
                    <div v-if="matrixType === 'Targets'">
                      <MDBRadio :disabled = "!editMode" label="Yes" value="Yes" v-model="selectedStrategies[currentStrategyId][currentTargetId]" inline name="inlineRadioOptions" />
                      <MDBRadio :disabled = "!editMode" label="No" value="No" v-model="selectedStrategies[currentStrategyId][currentTargetId]" inline name="inlineRadioOptions" />
                      <MDBRadio :disabled = "!editMode" label="Developing" value="Developing" v-model="selectedStrategies[currentStrategyId][currentTargetId]" inline name="inlineRadioOptions"/>
                      <MDBRadio :disabled = "!editMode" label="For Discussion" value="For Discussion" v-model="selectedStrategies[currentStrategyId][currentTargetId]" inline name="inlineRadioOptions"/>
                    </div>
                    <div v-else>
                      <MDBRadio :disabled = "!editMode" label="Yes" value="Yes" v-model="selectedStrategies[currentStrategyId][currentGoalId]" inline name="inlineRadioOptions" />
                      <MDBRadio :disabled = "!editMode" label="No" value="No" v-model="selectedStrategies[currentStrategyId][currentGoalId]" inline name="inlineRadioOptions" />
                      <MDBRadio :disabled = "!editMode" label="Developing" value="Developing" v-model="selectedStrategies[currentStrategyId][currentGoalId]" inline name="inlineRadioOptions"/>
                      <MDBRadio :disabled = "!editMode" label="For Discussion" value="For Discussion" v-model="selectedStrategies[currentStrategyId][currentGoalId]" inline name="inlineRadioOptions"/>
                    </div>

                  </div>
                </div>

                <br/>
                <div v-if="matrixType !== 'Targets'">
                <MDBAccordion v-model="activeGoalItem" borderless>
                <MDBAccordionItem
                    headerTitle="Targets:"
                    collapseId='targetAccordion'
                >
                  <div v-for="(target, index) in allTargetDetails[currentGoalId]" :key="target.number" >
                    <div class="row justify-content-center align-items-center">
                      <div class="" style="margin-left:20px">
                        <MDBCheckbox :disabled = "!editMode" v-model="selectedTargets[currentGoalId][currentStrategyId][index]" :id="target.number" :label="target.number + ': ' + target.name" size="large"/>
                      </div>
                    </div>

                  </div>

                </MDBAccordionItem>
                </MDBAccordion>
                </div>


                <!--<MDBCheckbox v-model="selectedStrategies[currentGoalId][currentStrategyId]" :label="allStrategyDetails[currentStrategyId].name" size="small"/>-->

                <MDBAccordion v-model="activeGoalItem" borderless>
                  <MDBAccordionItem
                      headerTitle="Sub-strategies:"
                      collapseId='targetAccordion'
                  >
                    <div v-for="(subStrategy, subStrategyIndex) in allStrategyDetails[currentStrategyId].subStrategies" :key="subStrategyIndex" class="row offset-3 col-md-12">
                      <div class="row justify-content-center align-items-center">
                        <div class="" style="margin-left:20px">
                          <div v-if="matrixType === 'Targets'">
                            <MDBCheckbox :disabled = "!editMode" v-model="selectedSubstrategies[currentTargetId][currentStrategyId][subStrategyIndex]" :label="subStrategy.name" size="large"/>
                          </div>
                          <div v-else>
                            <MDBCheckbox :disabled = "!editMode" v-model="selectedSubstrategies[currentGoalId][currentStrategyId][subStrategyIndex]" :label="subStrategy.name" size="large"/>
                          </div>
                      </div>
                      </div>
                    </div>

                  </MDBAccordionItem>
                </MDBAccordion>


                <hr/>
                <div style="margin-left:20px; margin-right: 20px">
                Notes:
                  <div v-if="matrixType === 'Targets' && currentStrategyId !== undefined && strategyNotes[currentTargetId] !== undefined ">
                    <MDBTextarea :disabled = "!editMode" label="" rows="2" v-model="strategyNotes[currentTargetId][currentStrategyId]" />
                  </div>
                  <div v-if="matrixType !== 'Targets' && currentStrategyId !== undefined && strategyNotes[currentGoalId] !== undefined">
                      <MDBTextarea :disabled = "!editMode" label="" rows="2" v-model="strategyNotes[currentGoalId][currentStrategyId]" />
                  </div>
                </div>
              </div>
              <br/>
              </div>
            </div>
          </div>

    </div>
</template>

<script src="./_StrategyDetails.js" lang="js"></script>
<style src="./_StrategyDetails.less" lang="less"></style>


