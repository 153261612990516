import { defineStore } from 'pinia'
import useWheelAnalysisQuery from "@/composables/useWheelAnalysisQuery";
import axios from "axios";
import {useAnalysisStore} from "@/stores/Setup/analysis";
import {useUserInfoStore} from "@/stores/Setup/userInfo";
import {useCriticalGoalsStore} from "@/stores/WheelAnalysis/criticalGoals";
import {useGoalAStore} from "@/stores/WheelAnalysis/goalA";
import {useAdditionalGoalsStore} from "@/stores/WheelAnalysis/additionalGoals";
import {useGoalBStore} from "@/stores/WheelAnalysis/goalB";
import {useAllGoalsStore} from "@/stores/WheelAnalysis/allGoals";
import {useKeywordStore} from "@/stores/WheelAnalysis/keyword";
import {useImageStore} from "@/stores/WheelAnalysis/image";
import {useGoal11TargetsStore} from "@/stores/WheelAnalysis/goal11Targets";

export const useChallengeStore = defineStore('challenge', {
    state: () => {
        return {
            challengeType:'',
            challengeTypeOther: '',
            challengeStatement: 'In _(GEOGRAPHIC LOCATION, ORGANIZATION, OR COMMUNITY)_ we aim to improve quality of life sustainable development by addressing _(CHALLENGE OR BENEFIT)_.  We will use the SDGs to realize co-benefits related to equity, economic prosperity and environmental care, and avoid unintended harms. \n' +
                '\n' +
                '(Optional part 2) We anticipate making this change through _(TYPE OF ACTION/SOLUTION)_, working through the _(LIST SECTOR(s))_, using skills and knowledge from _(LIST ACADEMIC DISCIPLINE(S))_.\n' +
                '\n',
            name: '',
            editMode: true
        }
    },
    getters: {
    },
    actions: {
        async fetchWheelAnalysis() {
            await useWheelAnalysisQuery()
            //console.log(data)
        },
        nameChanged() {
            const analysisStore = useAnalysisStore()
            if (analysisStore.wheelAnalysisId !== null) {  // TODO: add header with token
                const challengeData = { "name": this.name}
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/wheel_analyses/' + analysisStore.wheelAnalysisId  + '/name', challengeData).then(response => {
                    console.log(response)
                })
            }

        },
        challengeTypeChanged() {
            const analysisStore = useAnalysisStore()
            if (analysisStore.wheelAnalysisId !== null) {  // TODO: add header with token
                const challengeData = { "challenge_type": this.challengeType}
                //console.log(challengeData)
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/wheel_analyses/' + analysisStore.wheelAnalysisId  + '/challenge_type', challengeData).then(response => {
                    console.log(response)
                })
            }

        },
        challengeTypeOtherChanged() {
            const analysisStore = useAnalysisStore()
            if (analysisStore.wheelAnalysisId !== null) {  // TODO: add header with token
                const challengeData = { "challenge_type_other": this.challengeTypeOther}
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/wheel_analyses/' + analysisStore.wheelAnalysisId  + '/challenge_type_other', challengeData).then(response => {
                    console.log(response)
                })
            }
        },
        challengeStatementChanged() {
            const analysisStore = useAnalysisStore()
            if (analysisStore.wheelAnalysisId !== null && this.challengeStatement !== null) {  // TODO: add header with token
                const challengeData = { "challenge_statement": this.challengeStatement }
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/wheel_analyses/' + analysisStore.wheelAnalysisId + '/challenge_statement', challengeData).then(response => {
                    console.log(response)
                })
            }

        },
        exitedField() {
            // When a field has lost focus, if there is no current record, create it
            // (subsequent data will be updated on changing of any field)
            //console.log("called exited field")

            const analysisStore = useAnalysisStore()
            const userInfoStore = useUserInfoStore()
            const criticalGoalsStore = useCriticalGoalsStore()
            const goalAStore = useGoalAStore()
            const goalBStore = useGoalBStore()
            const additionalGoalsStore = useAdditionalGoalsStore()
            const allGoalsStore = useAllGoalsStore()
            const keywordStore = useKeywordStore()
            const imageStore = useImageStore()
            const goal11TargetsStore = useGoal11TargetsStore()

            //console.log(analysisStore.wheelAnalysisId)
            //console.log(analysisStore.creatingWheelAnalysis)
            if (analysisStore.wheelAnalysisId === null && !analysisStore.creatingWheelAnalysis) {

                //console.log("not null")
                analysisStore.creatingWheelAnalysis = true
                const hexCode = [...Array(10)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
                //console.log(hexCode)
                const wheelAnalysisData = {
                    analyses_id: userInfoStore.userInfoId,
                    challenge_type: this.challengeType,
                    challenge_statement: this.challengeStatement,
                    goal_11_selected_targets: [goal11TargetsStore.selectedTargets].toString(),
                    selected_critical_goals: [criticalGoalsStore.selectedCriticalGoals].toString(),
                    goal_a_index: criticalGoalsStore.goalAIndex,
                    goal_b_index: criticalGoalsStore.goalBIndex,
                    goal_a_selected_targets: [goalAStore.selectedTargets].toString(),
                    goal_b_selected_targets: [goalBStore.selectedTargets].toString(),
                    additional_goals_selected_goals: [additionalGoalsStore.selectedGoals].toString(),
                    additional_goals_selected_targets: JSON.stringify(additionalGoalsStore.selectedTargets),
                    all_goals_selected_goals: JSON.stringify(allGoalsStore.selectedGoals),
                    all_goals_selected_targets: JSON.stringify(allGoalsStore.selectedTargets),
                    keyword1: keywordStore.keyword1,
                    keyword2: keywordStore.keyword2,
                    keyword3: keywordStore.keyword3,
                    challenge_type_other: this.challengeTypeOther,
                    notes:allGoalsStore.reviewNotes,
                    hex_code: hexCode,
                    image_file: imageStore.previewImage,
                    name: this.name
                }
                /*const wheelAnalysisData = { "challenge_type": this.challengeType, "challenge_statement": this.challengeStatement,           "analyses_id": userInfoStore.userInfoId,
                    "goal_11_selected_targets": "false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false",
                    "selected_critical_goals": "false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false",
                    "goal_a_index": null,
                    "goal_b_index": null,
                    "goal_a_selected_targets": "false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false",
                    "goal_b_selected_targets": "false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false",
                    "additional_goals_selected_goals": "false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false",
                    "additional_goals_selected_targets": "[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]",
                    "all_goals_selected_goals": "[]",
                    "all_goals_selected_targets": "[]",
                    "keyword1": "",
                    "keyword2": "",
                    "keyword3": "",
                    "image_file": "",
                    "challenge_type_other": "",
                    "notes":"",
                    "hex_code": hexCode,
                    "name": this.name
                }*/

                analysisStore.wheelAnalysisHexCode = hexCode

                // TODO: add header with token
                axios.post(process.env.VUE_APP_API_ROOT + 'api/v1/wheel_analyses/create', wheelAnalysisData).then(response => {
                    console.log(response)
                    analysisStore.wheelAnalysisId = response.data.id
                    analysisStore.creatingWheelAnalysis = false
                }).catch(error => {
                    this.error = error.message;
                    console.error("There was an error!", error);
                    analysisStore.creatingWheelAnalysis = false
                })
            }
        }
    },
    persist: true
})