// @vue/component
import {useStrategyDetailsStore} from "@/stores/CrossWalkAnalysis/strategyDetails";
import {storeToRefs} from "pinia/dist/pinia";
import { MDBBtn, MDBTooltip } from 'mdb-vue-ui-kit';
import { ref } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
library.add(faPlus)
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import {useCrosswalkChallengeStore} from "@/stores/CrossWalkAnalysis/challenge";
library.add(faMinus)

export default {
  name: 'SingleStrategySetup',

  components: {MDBBtn, MDBTooltip, FontAwesomeIcon},

  mixins: [],

  props: {},

  setup() {
    const strategyDetailsStore = useStrategyDetailsStore()
    const challengeStore = useCrosswalkChallengeStore()

    const { allStrategyDetails } =  storeToRefs(strategyDetailsStore)
    const removesubtooltip = ref([[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]);
    const addsubtooltip = ref([]);
    const removetooltip = ref([]);
    const addtooltip = ref(false);
    const strategy = ref(strategyDetailsStore.allStrategyDetails[strategyDetailsStore.setupStrategyId]);
    const { setupStrategyId } =  storeToRefs(strategyDetailsStore)
    const { editMode } =  storeToRefs(challengeStore)

    return { strategyDetailsStore, allStrategyDetails, removesubtooltip, addsubtooltip, removetooltip, addtooltip, strategy, setupStrategyId, editMode}

  },

  data () {
    return {

    }
  },

  computed: {
    keyword(strategyId) {
      return this.strategyDetailsStore.allStrategyDetails[strategyId]?.keyword
    }

  },

  watch: {},

  mounted () {
    this.strategyDetailsStore.fetchCrosswalkAnalysis()
    this.strategy = this.strategyDetailsStore.allStrategyDetails[this.strategyDetailsStore.setupStrategyId]
    //console.log("strategy:")
    //console.log(this.strategy)

  },

  methods: {
    addSubStrategy(strategyIndex) {
      if (this.allStrategyDetails[strategyIndex] == null) {
        this.allStrategyDetails[strategyIndex] = {name:"", subStrategies: []}
      }
      this.allStrategyDetails[strategyIndex].subStrategies.push({name:""})
      this.strategyDetailsStore.allStrategyDetailsChanged()
    },
    removeSubStrategy(strategyIndex, subStrategyIndex) {
      //console.log( this.allStrategyDetails[strategyIndex].subStrategies);
      //console.log("")
      this.allStrategyDetails[strategyIndex].subStrategies.splice(subStrategyIndex,1);
      this.strategyDetailsStore.allStrategyDetailsChanged()
    },
    clickRemoveStrategy() {
      //console.log("removing...")
      //console.log(this.allStrategyDetails)
      //console.log(this.setupStrategyId)
      this.allStrategyDetails.splice(this.setupStrategyId,1);
      this.strategyDetailsStore.allStrategyDetailsChanged()
    },
  }
}
