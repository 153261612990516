<template>
  <div class="Challenge">

    <!--<div id="title-header-div" >
      <br>
      <h1>Keywords</h1>
      <br>
    </div>-->
    <div id="form-div">

      <!--<div class="row justify-content-center align-items-center">
        <div class="col-md-10">
          Please review the challenge statement that you have provided, editing as needed.
          <MDBTextarea label="" rows="2" v-model="challengeStatement"  @change = "challengeStore.challengeStatementChanged()" />
        </div>
      </div>-->
      <div class="row justify-content-center align-items-center">
        <div class="col-md-10">
          Please enter 3 keywords here:

        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="col-md-10">
          <div class="col-md-12" style="float:left">
            <MDBInput :disabled = "!editMode" label="Keyword 1" v-model="keyword1" @change = "keywordStore.keyword1Changed()"/>
          </div>
          <br/>
          <br/>
          <div class="col-md-12" style="float:left">
            <MDBInput :disabled = "!editMode" label="Keyword 2" v-model="keyword2"  @change = "keywordStore.keyword2Changed()"/>
          </div>
          <br/>
          <br/>
          <div class="col-md-12" style="float:left">
            <MDBInput :disabled = "!editMode" label="Keyword 3" v-model="keyword3"  @change = "keywordStore.keyword3Changed()"/>
          </div>

        </div>
      </div>
    </div>



    <!--<div class="prev-next">
      <a href="/image" class="btn btn-primary">Previous</a>
      <a href="/wheel" class="btn btn-primary">Next</a>
    </div>-->
  </div>

  <router-view />
</template>

<script src="./_KeywordView.js" lang="js"></script>
<style src="./_KeywordView.less" lang="less" scoped></style>
