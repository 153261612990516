import { defineStore } from 'pinia'
import useWheelAnalysisQuery from "@/composables/useWheelAnalysisQuery";
import axios from "axios";
import {useAnalysisStore} from "@/stores/Setup/analysis";
import {useCriticalGoalsStore} from "@/stores/WheelAnalysis/criticalGoals";


export const useAdditionalGoalsStore = defineStore('additionalGoals', {
    state: () => {
        return {
            selectedGoals: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
            selectedTargets: [[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]
        }
    },
    getters: {
    },
    actions: {
        updateSelectedGoals() {
            let criticalGoalsStore = useCriticalGoalsStore()
            this.selectedGoals = criticalGoalsStore.selectedCriticalGoals
        },
        async fetchWheelAnalysis() {
            await useWheelAnalysisQuery()
            //console.log(data)
        },
        selectedGoalsChanged() {
            const analysisStore = useAnalysisStore()
            if (this.selectedGoals !== null) {  // TODO: add header with token
                const data = { "additional_goals_selected_goals": [this.selectedGoals].toString()}
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/wheel_analyses/' + analysisStore.wheelAnalysisId + '/additional_goals_selected_goals', data).then(response => {
                    console.log(response)
                }).catch(error => {
                    this.error = error.message;
                    console.error("There was an error!", error);
                })
            }

        },
        selectedTargetsChanged() {
            const analysisStore = useAnalysisStore()
            if (this.selectedTargets !== null) {  // TODO: add header with token
                const data = { "additional_goals_selected_targets": JSON.stringify(this.selectedTargets)}
                //console.log(data)
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/wheel_analyses/' + analysisStore.wheelAnalysisId +'/additional_goals_selected_targets', data).then(response => {
                    console.log(response)
                }).catch(error => {
                    this.error = error.message;
                    console.error("There was an error!", error);
                })
            }

        },
    },
    persist: true
})