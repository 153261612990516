import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import WheelView from '@/views/WheelAnalysis/WheelView.vue'
import RightSideBar from '@/components/RightSideBar/RightSideBar.vue'
import vueAwesomeSidebar from 'vue-awesome-sidebar'
import 'vue-awesome-sidebar/dist/vue-awesome-sidebar.css'
import 'mdb-vue-ui-kit/css/mdb.min.css';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import './assets/styles/styles.css'
import { VueQueryPlugin } from "@tanstack/vue-query";
import { MDBRadio, MDBTextarea, MDBCheckbox, MDBFile, MDBInput, MDBAccordion, MDBAccordionItem, MDBTabs, MDBTabNav, MDBTabItem, MDBTabPane, MDBTabContent} from 'mdb-vue-ui-kit';
import 'core-js/stable';
import 'regenerator-runtime/runtime';



global.jQuery = require('jquery');
let $ = global.jQuery;
window.$ = $;


const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app = createApp(App)
app.config.globalProperties.$baseUrl = process.env.VUE_APP_BASE_URL;
app.config.globalProperties.$apiRoot = process.env.VUE_APP_API_ROOT;
app.component('WheelView', WheelView)
app.component('MDBRadio', MDBRadio)
app.component('MDBTextarea', MDBTextarea)
app.component('MDBCheckbox', MDBCheckbox)
app.component('MDBFile', MDBFile)
app.component('MDBInput', MDBInput)
app.component('MDBAccordionItem', MDBAccordionItem)
app.component('MDBAccordion', MDBAccordion)
app.component('RightSideBar', RightSideBar)
app.component('MDBTabs', MDBTabs)
app.component('MDBTabNav', MDBTabNav)
app.component('MDBTabItem', MDBTabItem)
app.component('MDBTabContent', MDBTabContent)
app.component('MDBTabPane', MDBTabPane)


app
    .use(router)
    .use(vueAwesomeSidebar)
    .use(pinia)
    .use(VueQueryPlugin)


// add more functionality to app

// now we're ready to mount
app.mount('#app')
