<template>
  <div class="MatrixType"></div>
  <!--<h1>Matrix Type</h1>-->
  <br/>
  <span>Select options for the crosswalk analysis:</span>
    <div class="col-md-9 offset-1">
      <MDBRadio
          style="text-align:left"
          label="Goals"
          name="matrixType"
          v-model="matrixType"
          value="Goals"
          @blur="strategyDetailsStore.exitedField"
          :disabled = "!editMode"
      />
      <MDBRadio
          style="text-align:left"
          label="Targets within Goal:"
          name="matrixType"
          v-model="matrixType"
          value="Targets"
          @blur="strategyDetailsStore.exitedField"
          :disabled = "!editMode"
      />
    </div>

  <div class="row">
  <div class="col-md-10 offset-1">
    <select v-model="goalNumber" class="form-control select-input" :disabled="matrixType == 'Goals'" >
      <option disabled value="">Please Select</option>
      <option v-for="option in goals" :value="option.id" :key="option.id">{{option.name}}</option>
    </select>
  </div>
  </div>


  <br/><br/>
  <!--<div class="prev-next">
    <a href="/crosswalk-challenge" class="btn btn-primary">Previous</a>
    <a href="/strategy-setup" class="btn btn-primary">Next</a>
  </div>-->
</template>

<script src="./_MatrixType.js" lang="js"></script>
<style src="./_MatrixType.less" lang="less" scoped></style>
