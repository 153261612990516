import { useQuery } from '@tanstack/vue-query'
import axios from "axios";
import {useAnalysisStore} from "@/stores/Setup/analysis";
import {useAllGoalsStore} from "@/stores/WheelAnalysis/allGoals";
import {useAdditionalGoalsStore} from "@/stores/WheelAnalysis/additionalGoals";
import {useCriticalGoalsStore} from "@/stores/WheelAnalysis/criticalGoals";
import {useGoalAStore} from "@/stores/WheelAnalysis/goalA";
import {useGoalBStore} from "@/stores/WheelAnalysis/goalB";
//import {useImageStore} from "@/stores/WheelAnalysis/image";
//import {useGoal11TargetsStore} from "@/stores/WheelAnalysis/goal11Targets";
//import {useChallengeStore} from "@/stores/WheelAnalysis/challenge";
import {useKeywordStore} from "@/stores/WheelAnalysis/keyword";

export default () => {
    const analysisStore = useAnalysisStore()
    const allGoalsStore = useAllGoalsStore()
    const additionalGoalsStore = useAdditionalGoalsStore()
    const criticalGoalsStore = useCriticalGoalsStore()
    const goalAStore = useGoalAStore()
    const goalBStore = useGoalBStore()
    //const imageStore = useImageStore()
    //const goal11TargetsStore = useGoal11TargetsStore()
    //const challengeStore = useChallengeStore()

    const keywordStore = useKeywordStore()



    /*const imageUrlToBase64 = async (url) => {
        //console.log("imageUrlToBase64 called")
        const data = await fetch(url)
        //console.log("fetch done")
        const blob = await data.blob();
        //console.log("blob done:")
        //console.log(blob)
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
            const base64data = reader.result;
            //console.log("base64 done:")
            //console.log(base64data)
            return base64data
        }
    }*/


    return useQuery({
        queryKey: ['wheelAnalysis'],

        queryFn: async () => {
            const headers = {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
            //console.log("headers!")
            const response = await axios.call("get", process.env.VUE_APP_API_ROOT + "api/v1/wheel_analyses/" + analysisStore.wheelAnalysisHexCode, { headers: {headers}})
            //const data = await response.data;
            //console.log("queryFn response")
            console.log(response)
            //console.log(response.data.goal_11_selected_targets)
            //console.log(response.data.goal_11_selected_targets.split(","))

            // GWS: 3/5/24 - removing for now. challenge store wasn't sticking when selecting another
            /*challengeStore.challengeType = response.data.challenge_type
            challengeStore.challengeTypeOther = response.data.challenge_type_other
            challengeStore.challengeStatement = response.data.challenge_statement*/


            /*if (response.data.goal_11_selected_targets !== null) {
                goal11TargetsStore.selectedTargets = response.data.goal_11_selected_targets.split(",");
                goal11TargetsStore.selectedTargets = goal11TargetsStore.selectedTargets.map(j => JSON.parse(j));
            }*/
            //console.log("critical goals (from query):")
            console.log(response.data.selected_critical_goals)
            if (response.data.selected_critical_goals !== null) {
                criticalGoalsStore.selectedCriticalGoals = response.data.selected_critical_goals.split(",").map(j => JSON.parse(j));

                //console.log("new critical goals:")
                //console.log(criticalGoalsStore.selectedCriticalGoals)
            }


            criticalGoalsStore.goalAIndex = response.data.goal_a_index
            criticalGoalsStore.goalBIndex = response.data.goal_b_index
            //console.log("goal A index (from query):")
            //console.log(criticalGoalsStore.goalAIndex)
            if (response.data.goal_a_selected_targets !== null) {
                goalAStore.selectedTargets = response.data.goal_a_selected_targets.split(",").map(j => JSON.parse(j))
            }
            if (response.data.goal_b_selected_targets !== null) {
                goalBStore.selectedTargets = response.data.goal_b_selected_targets.split(",").map(j => JSON.parse(j))
            }

            if (response.data.additional_goals_selected_goals !== null) {
                additionalGoalsStore.selectedGoals = response.data.additional_goals_selected_goals.split(",");
                additionalGoalsStore.selectedGoals = additionalGoalsStore.selectedGoals.map(j => JSON.parse(j));
            }
            if (response.data.additional_goals_selected_targets !== null) {
                console.log(response.data.additional_goals_selected_targets)
                additionalGoalsStore.selectedTargets = JSON.parse(response.data.additional_goals_selected_targets)
                //console.log(additionalGoalsStore.selectedTargets)
            }

            // might not need to pull all goals
            /*if (response.data.all_goals_selected_goals !== null) {
                allGoalsStore.selectedGoals = response.data.all_goals_selected_goals.split(",").map(j => JSON.parse(j))
            }
            if (response.data.all_goals_selected_targets !== null) {
                allGoalsStore.selectedTargets = response.data.all_goals_selected_targets.split(",").map(j => JSON.parse(j))
            }*/


            allGoalsStore.reviewNotes = response.data.notes
            keywordStore.keyword1 = response.data.keyword1
            keywordStore.keyword2 = response.data.keyword2
            keywordStore.keyword3 = response.data.keyword3
            //imageStore.imageFile = response.data.image_url
            //console.log(response.data)
            ////console.log("previewImage:")

            //imageStore.previewImage = await imageUrlToBase64(imageStore.imageFile);
            ////console.log("image!")
            ////console.log(imageStore.previewImage)
            /*const reader = new FileReader();

            if (imageStore.imageFile !== null) {
                reader.onloadend = () => {
                    //console.log(reader.result);
                    imageStore.previewImage = reader.result;
                };
                reader.readAsDataURL(imageStore.imageFile);
            }
*/
            return response;
        },
        //queryFn: () => axios.call("get", process.env.VUE_APP_API_ROOT + "api/v1/user_infos/" + userInfoStore.userInfoId),
        //select: (response) =>  response.data , // extract data from Axios response
        staleTime: 1000
    })
}
