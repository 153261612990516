import { defineStore } from 'pinia'
import {useCriticalGoalsStore} from "@/stores/WheelAnalysis/criticalGoals";
import useWheelAnalysisQuery from "@/composables/useWheelAnalysisQuery";
import {useAnalysisStore} from "@/stores/Setup/analysis";
import axios from "axios";
//import axios from "axios";

export const useAllGoalsStore = defineStore('allGoals', {
    state: () => {
        return {
            primaryGoalsViewMode: null,
            reviewNotes: null,
            selectedGoals: [false,false,false,false,false,false,false,false,false,false,true,false,false,false,false,false,false],
            selectedTargets: [[false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false],
                [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false]]
        }
    },
    getters: {
    },
    actions: {
        updateSelectedGoals() {
            let criticalGoalsStore = useCriticalGoalsStore()
            this.selectedGoals = criticalGoalsStore.selectedCriticalGoals
        },
        async fetchWheelAnalysis() {
            const { data }   = await useWheelAnalysisQuery()
            console.log(data)
        },
        reviewNotesChanged() {
            const analysisStore = useAnalysisStore()
            if (analysisStore.wheelAnalysisId !== null) {  // TODO: add header with token
                const challengeData = { "notes": this.reviewNotes}
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/wheel_analyses/' + analysisStore.wheelAnalysisId  + '/notes', challengeData).then(response => {
                    console.log(response)
                })
            }
        },
        selectedGoalsChanged() {
            /*if (this.selectedGoals !== null) {  // TODO: add header with token
                const data = { "all_goals_selected_goals": this.selectedGoals, "all_goals_selected_targets": this.selectedTargets }
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/wheel_analyses/' + this.userInfoId + '/all_goals_selected_goals', data).then(response => {
                    console.log(response)
                })
            }*/

        },
        selectedTargetsChanged() {
            /*if (this.selectedTargets !== null) {  // TODO: add header with token
                const data = { "all_goals_selected_goals": this.selectedGoals, "all_goals_selected_targets": this.selectedTargets }
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/wheel_analyses/' + this.userInfoId + '/all_goals_selected_targets', data).then(response => {
                    console.log(response)
                })
            }*/

        },
    },
    persist: true
})