import { defineStore } from 'pinia'

export const useAnalysisStore = defineStore('analysis', {
    state: () => {
        return {
            analysisType: 'Wheel',
            wheelAnalysisId: null,
            wheelAnalysisHexCode: null,
            crosswalkAnalysisId: null,
            crosswalkAnalysisHexCode: null,
            analysisId: null,
            creatingWheelAnalysis: false,
            creatingCrosswalkAnalysis: false,

        }
    },
    getters: {
    },
    actions: {

    },
    persist: true
})