import { defineStore } from 'pinia'
import useWheelAnalysisQuery from "@/composables/useWheelAnalysisQuery";
import axios from "axios";
import {useAnalysisStore} from "@/stores/Setup/analysis";

export const useKeywordStore = defineStore('keyword', {
    state: () => {
        return {
            keyword1: "",
            keyword2: "",
            keyword3: ""
        }
    },
    getters: {
    },
    actions: {
        async fetchWheelAnalysis() {
            await useWheelAnalysisQuery()
            //console.log(data)
        },
        keyword1Changed() {
            const analysisStore = useAnalysisStore()
            if (this.keyword1 !== null) {  // TODO: add header with token
                const data = { "keyword1": this.keyword1, "keyword2": this.keyword2,  "keyword3": this.keyword3 }
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/wheel_analyses/'+ analysisStore.wheelAnalysisId + '/keyword1', data).then(response => {
                    console.log(response)
                })
            }
        },
        keyword2Changed() {
            const analysisStore = useAnalysisStore()
            if (this.keyword2 !== null) {  // TODO: add header with token
                const data = { "keyword1": this.keyword1, "keyword2": this.keyword2,  "keyword3": this.keyword3 }
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/wheel_analyses/' + analysisStore.wheelAnalysisId + '/keyword2', data).then(response => {
                    console.log(response)
                })
            }
        },
        keyword3Changed() {
            const analysisStore = useAnalysisStore()
            if (this.keyword3 !== null) {  // TODO: add header with token
                const data = { "keyword1": this.keyword1, "keyword2": this.keyword2,  "keyword3": this.keyword3 }
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/wheel_analyses/' + analysisStore.wheelAnalysisId + '/keyword3', data).then(response => {
                    console.log(response)
                })
            }
        }
    },
    persist: true
})