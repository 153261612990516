<template>
  <div class="CrosswalkLegend">
    <div class="legend-body">
      <div class="legend-inner-header " style="font-weight:500">Status of Strategic Priorities:<br/> Is the priority fully implemented?</div>
      <div class="legend-inner-body p-5" style="margin-top:-20px">
        <div class="row p-2">
          <div class="col-2" style="border: 1px solid rgba(0,0,0,.3); background-color: rgb(72, 180, 126)"></div>
          <div class="offset-1 col-1" ></div>
          <div class="col-8" style="text-align:left">Yes</div>
        </div>
        <div class="row p-2">
          <div class="col-2" style="border: 1px solid rgba(0,0,0,.3); background-color: white"></div>
          <div class="offset-1 col-1"></div>
          <div class="col-8"  style="text-align:left">No</div>
        </div>
        <div class="row p-2">
          <div class="col-2" style="border: 1px solid rgba(0,0,0,.3); background-color: rgb(255, 248, 173)"></div>
          <div class="offset-1 col-1"></div>
          <div class="col-8"  style="text-align:left">Developing</div>
        </div>
        <div class="row p-2">
          <div class="col-2" style="border: 1px solid rgba(0,0,0,.3); background-color: rgb(197, 5, 12)"></div>
          <div class="offset-1 col-1"></div>
          <div class="col-8"  style="text-align:left">For Discussion</div>
        </div>
      </div>
    </div>
    <div class="legend-body" >
      <div class="legend-inner-heade p-2" style="font-weight:500">Strategic Priorities</div>
      <div class="legend-inner-body" style="text-align:left">
        <div v-for="(strategy, index) in allStrategyDetails"  v-bind:key="index" class="px-5 py-2" >
          <div class="" style="font-weight: 500; float:left;" >{{strategy.keyword}}: </div> {{strategy.name}}
          <div v-if="strategy.subStrategies.length > 0" class="legend-inner-inner-body offset-1 py-1">
            Sub-strategies:
            <div v-for="(substrategy, subindex) in strategy.subStrategies" v-bind:key="subindex">
              <ul>
                <li>{{substrategy.name}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script src="./_CrosswalkLegend.js" lang="js"></script>
<style src="./_CrosswalkLegend.scss" lang="scss"></style>



