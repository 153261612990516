<template>
  <MDBNavbar expand="lg" light bg="light" container>
    <MDBNavbarBrand href="#"><img
        src="/images/SDG Wheel_Transparent_WEB.png"
        height="50"
        alt=""
        loading="lazy"
    /><a href="/" style="color:black"> SDG 360 Thinking</a></MDBNavbarBrand>
    <!-- Toggle button -->
    <MDBNavbarToggler
        target="#navbarRightAlignExample"
        @click="collapse5 = !collapse5"
    ></MDBNavbarToggler>
    <!-- Collapsible wrapper -->

    <MDBCollapse v-model="collapse5" id="navbarRightAlignExample">
      <MDBNavbarNav right class="mb-8 mb-lg-0">

        <MDBNavbarItem to="/" >
          About
        </MDBNavbarItem>

        <MDBNavbarItem to="/get-started" >
          Get Started
        </MDBNavbarItem>

        <MDBNavbarItem v-if="loggedIn" to="/my-analyses" >
          My Analyses
        </MDBNavbarItem>

        <MDBNavbarItem v-if="loggedIn" to="/about-me" >
          My Profile
        </MDBNavbarItem>

        <MDBNavbarItem v-if="loggedIn" to="/logout" @click="logout">
          Logout
        </MDBNavbarItem>

        <MDBNavbarItem v-if="!loggedIn" to="/register" >
          Sign Up
        </MDBNavbarItem>

        <MDBNavbarItem v-if="!loggedIn" to="/login" inactive >
          Log in
        </MDBNavbarItem>

      </MDBNavbarNav>
    </MDBCollapse>
    <!-- Collapsible wrapper -->

  </MDBNavbar>
</template>

<script>
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse
}

  from 'mdb-vue-ui-kit';
import { ref } from 'vue';
import {useUserInfoStore} from "@/stores/Setup/userInfo";
import {useAllGoalsStore} from "@/stores/WheelAnalysis/allGoals";
import {useAdditionalGoalsStore} from "@/stores/WheelAnalysis/additionalGoals";
import {useCriticalGoalsStore} from "@/stores/WheelAnalysis/criticalGoals";
import {useGoalAStore} from "@/stores/WheelAnalysis/goalA";
import {useGoalBStore} from "@/stores/WheelAnalysis/goalB";
import {useImageStore} from "@/stores/WheelAnalysis/image";
import {useGoal11TargetsStore} from "@/stores/WheelAnalysis/goal11Targets";
import {useChallengeStore} from "@/stores/WheelAnalysis/challenge";
import {useCrosswalkChallengeStore} from "@/stores/CrossWalkAnalysis/challenge";
import {useKeywordStore} from "@/stores/WheelAnalysis/keyword";
import {useStrategyDetailsStore} from "@/stores/CrossWalkAnalysis/strategyDetails";
import {useAnalysisStore} from "@/stores/Setup/analysis";
import router from "@/router";

export default {
  name: 'menuNav,',
  components: {
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse
  },
  setup() {

    const collapse5 = ref(false);
    const dropdown1 = ref(false);
    const dropdown2 = ref(false);
    const dropdown3 = ref(false);
    const dropdown4 = ref(false);
    const userInfoStore = useUserInfoStore()


    return {
      collapse5,
      dropdown1,
      dropdown2,
      dropdown3,
      dropdown4,
      userInfoStore
    }
  },
  methods: {
    logout() {
      this.userInfoStore.$reset()
      const allGoalsStore = useAllGoalsStore()
      allGoalsStore.$reset()
      const analysisStore = useAnalysisStore()
      analysisStore.$reset()
      const additionalGoalsStore = useAdditionalGoalsStore()
      additionalGoalsStore.$reset()
      const criticalGoalsStore = useCriticalGoalsStore()
      criticalGoalsStore.$reset()
      const goalAStore = useGoalAStore()
      goalAStore.$reset()
      const goalBStore = useGoalBStore()
      goalBStore.$reset()
      const imageStore = useImageStore()
      imageStore.$reset()
      const goal11TargetsStore = useGoal11TargetsStore()
      goal11TargetsStore.$reset()
      const userInfoStore = useUserInfoStore()
      userInfoStore.$reset()
      const challengeStore = useChallengeStore()
      challengeStore.$reset()
      const crosswalkChallengeStore = useCrosswalkChallengeStore()
      crosswalkChallengeStore.$reset()
      const keywordStore = useKeywordStore()
      keywordStore.$reset()
      const strategyDetailsStore = useStrategyDetailsStore()
      strategyDetailsStore.$reset()
      router.push("/get-started")

    }
  },
  computed: {
    loggedIn() {
      return !!this.userInfoStore.userId;
    }
  },
  watch: {
    '$route'() {
      this.collapse5 = false
    }
  }
}
</script>
