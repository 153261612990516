import { useQuery } from '@tanstack/vue-query'
import axios from "axios";
import {useCrosswalkChallengeStore} from "@/stores/CrossWalkAnalysis/challenge";
import {useStrategyDetailsStore} from "@/stores/CrossWalkAnalysis/strategyDetails";
import {useAnalysisStore} from "@/stores/Setup/analysis";

export default () => {
    const challengeStore = useCrosswalkChallengeStore()
    const analysisStore = useAnalysisStore()
    const strategyDetailsStore = useStrategyDetailsStore()

    return useQuery({
        queryKey: ['crosswalkAnalysis'],

        queryFn: async () => {
            const response = await axios.call("get", process.env.VUE_APP_API_ROOT + "api/v1/crosswalk_analyses/" + analysisStore.crosswalkAnalysisHexCode)
            //const data = await response.data;
            //console.log("queryFn response")
            console.log(response)
            challengeStore.challengeType = response.data.challenge_type
            challengeStore.planUrl = response.data.plan_url
            strategyDetailsStore.matrixType = response.data.matrix_type
            strategyDetailsStore.goalNumber = response.data.goal_number

            if (response.data.selected_strategies !== null)
                strategyDetailsStore.selectedStrategies = JSON.parse(response.data.selected_strategies)
            if (response.data.selected_substrategies !== null)
                strategyDetailsStore.selectedSubstrategies = JSON.parse(response.data.selected_substrategies)
            if (response.data.selected_targets !== null)
                strategyDetailsStore.selectedTargets = JSON.parse(response.data.selected_targets)
            if (response.data.strategy_notes !== null)
                strategyDetailsStore.strategyNotes = JSON.parse(response.data.strategy_notes)

            strategyDetailsStore.allStrategyDetails = response.data.all_strategy_details

            return response;
        },
        //queryFn: () => axios.call("get", process.env.VUE_APP_API_ROOT + "api/v1/user_infos/" + userInfoStore.userInfoId),
        //select: (response) =>  response.data , // extract data from Axios response
        staleTime: 1000
    })
}
