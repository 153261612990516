<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css" integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA==" crossorigin="anonymous" referrerpolicy="no-referrer" />
  <menuNav></menuNav>
  <div v-if="analysisStore.analysisType==='Wheel'">
    <!--<VueAwesomeSideBar
      v-model:miniMenu="miniMenu"
      v-model:collapsed="collapsed"
      :menu="sideMenuWheel"
      vueRouterEnabel
      ref="SideBarA"
    ></VueAwesomeSideBar>-->
    <div :style="containerStyle()">
    <router-view />
    </div>
  </div>
  <div v-if="analysisStore.analysisType==='CrossWalk'">
    <!--<VueAwesomeSideBar
        v-model:miniMenu="miniMenu"
        v-model:collapsed="collapsed"
        :menu="sideMenuCrossWalk"
        vueRouterEnabel
        ref="SideBarB"
    ></VueAwesomeSideBar>-->
    <div :style="containerStyle()">
    <router-view />
    </div>
  </div>

<footerDiv v-if="!$route.path.includes('/wheel') && !$route.path.includes('/crosswalk')"></footerDiv>
</template>

<script>
import {useAllGoalsStore} from "@/stores/WheelAnalysis/allGoals";
import {useAdditionalGoalsStore} from "@/stores/WheelAnalysis/additionalGoals";
import {useCriticalGoalsStore} from "@/stores/WheelAnalysis/criticalGoals";
import {useGoalAStore} from "@/stores/WheelAnalysis/goalA";
import {useGoalBStore} from "@/stores/WheelAnalysis/goalB";
import {useImageStore} from "@/stores/WheelAnalysis/image";
import {useGoal11TargetsStore} from "@/stores/WheelAnalysis/goal11Targets";
import {useUserInfoStore} from "@/stores/Setup/userInfo";
import {useChallengeStore} from "@/stores/WheelAnalysis/challenge";
import {useCrosswalkChallengeStore} from "@/stores/CrossWalkAnalysis/challenge";
import {useKeywordStore} from "@/stores/WheelAnalysis/keyword";
import {useStrategyDetailsStore} from "@/stores/CrossWalkAnalysis/strategyDetails";
import {useAnalysisStore} from "@/stores/Setup/analysis";
import menuNav from "@/components/Menu/Menu";
import footerDiv from "@/components/Footer/Footer";
import axios from 'axios'



import router from "@/router";
import { ref } from 'vue';



export default {
  name: 'App',
  components: {menuNav},
  setup() {

  },
  mounted() {
    this.SideBarA = ref(null)
    this.SideBarB = ref(null)
  },
  created () {
    this.analysisStore = useAnalysisStore()

  },
  data() {
    return {
      dropdown1: null,
      analysisStore: null,
      collapsed: false,
      miniMenu: false
    }
  },
  methods: {
    containerStyle() {

      //console.log("containerstyle")
      //console.log(this.SideBarA)
      //if ($ref !== undefined) {
        if (this.SideBarA !== undefined) {
          //console.log("Sidebar width:")
          //console.log(this.SideBarA.clientWidth)
          if (this.SideBarA.clientWidth > 200 || this.SideBarB.clientWidth > 200) {
            return {
              //"margin-left": "200px"
              "margin-left": "0px"
            };
          } else {
            return {
              //"margin-left": "50px"
              "margin-left": "0px"
            };
          }
        } else {
          return {
            //"margin-left": "200px"
            "margin-left": "0px"
          };
        }
      /*} else {
          return {
            "margin-left": "300px"
          };
        }*/

    },
    clickWheelAnalysis() {
      //console.log("Wheel!")
      this.analysisStore.analysisType = "Wheel"

      router.push("/wheel/new")

    },
    clickCrossWalkAnalysis() {
      //console.log("CrossWalk!")
      this.analysisStore.analysisType = "CrossWalk"
      router.push("/crosswalk/new")
    },
    saveData() {
      const userInfoStore = useUserInfoStore()

      //console.log(userInfoStore.firstName)
      const userInfoData = {
        first_name: userInfoStore.firstName,
        last_name: userInfoStore.lastName,
        email: userInfoStore.email,
        job_title: userInfoStore.jobTitle,
        city: userInfoStore.city,
        state: userInfoStore.state,
        org_name: userInfoStore.orgName
      }

      axios.post(process.env.VUE_APP_API_ROOT + "api/v1/user_infos/create"  , userInfoData, {})
          .then(response => function() {
            //console.log("sent!:" + response)
            const userInfoId = response['id']

            const analysisData = {
              user_info_id: userInfoId
            }

            axios.post(process.env.VUE_APP_API_ROOT + "api/v1/analyses/create", analysisData, {})
                .then(analysisResponse => function() {
                  console.log(analysisResponse)
                  const analysisId = response['id']

                  const strategyDetailsStore = useStrategyDetailsStore()
                  const crosswalkChallengeStore = useCrosswalkChallengeStore()

                  const crosswalkAnalysisData = {
                    user_info_id: userInfoStore.userInfoId,
                    analyses_id: analysisId,
                    selected_strategies: strategyDetailsStore.selectedStrategies,
                    selected_substrategies: strategyDetailsStore.selectedSubstrategies,
                    selected_targets: strategyDetailsStore.selectedTargets,
                    strategy_notes: strategyDetailsStore.strategyNotes,
                    all_strategy_details: strategyDetailsStore.allStrategyDetails,
                    challenge_type: crosswalkChallengeStore.challengeType,
                    plan_url: crosswalkChallengeStore.planUrl
                  }

                  axios.post(process.env.VUE_APP_API_ROOT + "api/v1/crosswalk_analyses/create"  , crosswalkAnalysisData, {})
                      .then(crosswalkAnalysisResponse => function() {
                        console.error("success! " + crosswalkAnalysisResponse)
                      })
                      .catch(error => {
                        console.error("There was an error!", error);
                      });



                  const allGoalsStore = useAllGoalsStore()
                  const additionalGoalsStore = useAdditionalGoalsStore()
                  const criticalGoalsStore = useCriticalGoalsStore()
                  const goalAStore = useGoalAStore()
                  const goalBStore = useGoalBStore()
                 // const imageStore = useImageStore()
                  const goal11TargetsStore = useGoal11TargetsStore()
                  const challengeStore = useChallengeStore()

                  const keywordStore = useKeywordStore()


                  const wheelAnalysisData = {
                    analyses_id: analysisId,
                    challenge_type: challengeStore.challengeType,
                    challenge_statement: challengeStore.challengeStatement,
                    goal_11_selected_targets: goal11TargetsStore.selectedTargets,
                    selected_critical_goals: criticalGoalsStore.selectedCriticalGoals,
                    goal_a_index: criticalGoalsStore.goalAIndex,
                    goal_b_index: criticalGoalsStore.goalBIndex,
                    goal_a_selected_targets: goalAStore.selectedTargets,
                    goal_b_selected_targets: goalBStore.selectedTargets,
                    additional_goals_selected_goals: additionalGoalsStore.selectedGoals,
                    additional_goals_selected_targets: additionalGoalsStore.selectedTargets,
                    all_goals_selected_goals: allGoalsStore.selectedGoals,
                    all_goals_selected_targets: allGoalsStore.selectedTargets,
                    keyword1: keywordStore.keyword1,
                    keyword2: keywordStore.keyword2,
                    keyword3: keywordStore.keyword3,
                    image_file: ""
                  }

                  axios.post(process.env.VUE_APP_API_ROOT + "api/v1/wheel_analyses/create"  , wheelAnalysisData, {})
                      .then(wheelAnalysisResponse => function() {
                        console.error("success! " + wheelAnalysisResponse)
                      })
                      .catch(error => {
                        console.error("There was an error!", error);
                      });

                })
                .catch(error => {
                  console.error("There was an error!", error);
                });


          })
          .catch(error => {
            console.error("There was an error!", error);
          });
    },
    clearData() {
      const allGoalsStore = useAllGoalsStore()
      allGoalsStore.$reset()
      const additionalGoalsStore = useAdditionalGoalsStore()
      additionalGoalsStore.$reset()
      const criticalGoalsStore = useCriticalGoalsStore()
      criticalGoalsStore.$reset()
      const goalAStore = useGoalAStore()
      goalAStore.$reset()
      const goalBStore = useGoalBStore()
      goalBStore.$reset()
      const imageStore = useImageStore()
      imageStore.$reset()
      const goal11TargetsStore = useGoal11TargetsStore()
      goal11TargetsStore.$reset()
      const userInfoStore = useUserInfoStore()
      userInfoStore.$reset()
      const challengeStore = useChallengeStore()
      challengeStore.$reset()
      const crosswalkChallengeStore = useCrosswalkChallengeStore()
      crosswalkChallengeStore.$reset()
      const keywordStore = useKeywordStore()
      keywordStore.$reset()
      const strategyDetailsStore = useStrategyDetailsStore()
      strategyDetailsStore.$reset()
      location.reload();

    }
  }
}
</script>

<script setup>

// eslint-disable-next-line no-unused-vars
import { MDBNavbar, MDBNavbarBrand, MDBNavbarItem, MDBNavbarNav, MDBNavbarToggler, MDBDropdownMenu, MDBDropdown, MDBDropdownItem, MDBDropdownToggle, MDBBtn, MDBIcon, MDBTooltip } from 'mdb-vue-ui-kit';
//import { ref } from 'vue'

//const dropdown2 = ref(false)
//const SideBarA = ref(null)
//const SideBarB = ref(null)



// side menu for Wheel Analysis
/*const sideMenuWheel = [
  {
    header: 'Background'
  },
  {
    name: 'Using the SDG 360 Planner',
    icon: { text: '' , class: 'material-icons-outlined' },
    href: '/using-tool'
  },
  {
    name: 'About Me',
    icon: { text: '' , class: 'material-icons-outlined' },
    href: '/about-me',
  },
  {
    name: 'About My Organization',
    icon: { text: '' , class: 'material-icons-outlined' },
    href: '/about-org',
  },
  {
    header: 'Wheel Analysis'
  },
  {
    name: 'The Challenge',
    icon: { class: 'material-icons-outlined', text: '' },
    href: '/challenge',

  },
  {
    name: 'Critical Goals',
    icon: { class: 'material-icons-outlined', text: '' },
    href: '/critical-goals',
    children: [
      {
        name: 'Goal A',
        icon: { class: 'material-icons-outlined', text: '' },
        href: '/goal-a',
      },
      {
        name: 'Goal B',
        icon: { class: 'material-icons-outlined', text: '' },
        href: '/goal-b',
      },
    ]

  },
  {
    name: 'Additional Goals',
    icon: { class: 'material-icons-outlined', text: '' },
    href: '/additional-goals',

  },
  {
    name: 'Images',
    icon: { class: 'material-icons-outlined', text: '' },
    href: '/image',

  },
  {
    name: 'Keywords',
    icon: { class: 'material-icons-outlined', text: '' },
    href: '/keywords',

  },
  {
    name: 'Wheel Visualization',
    icon: { class: 'material-icons-outlined', text: '' },
    href: '/wheel',

  },
]*/

/*const sideMenuCrossWalk = [
  {
    header: 'Background'
  },
  {
    name: 'Using the SDG 360 Planner',
    icon: { text: '' , class: 'material-icons-outlined' },
    href: '/using-tool'
  },
  {
    name: 'About Me',
    icon: { text: '' , class: 'material-icons-outlined' },
    href: '/about-me',
  },
  {
    name: 'About My Organization',
    icon: { text: '' , class: 'material-icons-outlined' },
    href: '/about-org',
  },
  {
  header: 'Matrix Analysis'
},
  {
    name: 'Challenge',
    icon: { text: '' , class: 'material-icons-outlined' },
    href: '/crosswalk-challenge',
  },
  {
    name: 'Matrix Type',
    icon: { text: '' , class: 'material-icons-outlined' },
    href: '/matrix-type',
  },
  {
    name: 'Strategies',
    icon: { text: '' , class: 'material-icons-outlined' },
    href: '/strategy-setup',
  },
  {
    name: 'Matrix',
    icon: { class: 'material-icons-outlined', text: '' },
    href: '/matrix',

  },
]*/
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.vas-menu {
  top: 60px !important;
  padding-bottom: 60px;
}

.bg-dark {
  background-color: red !important;
}

.navbar-brand {
  color: white !important;

}

.navbar {
  position: fixed !important;
  width: 100% !important;
}
</style>
