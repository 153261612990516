// @vue/component
import {useChallengeStore} from "@/stores/WheelAnalysis/challenge";
import {storeToRefs} from "pinia/dist/pinia";

export default {
  name: 'AnalysisName',

  components: {},

  mixins: [],

  props: {},

  setup() {
    const challengeStore = useChallengeStore()

    const { name } =  storeToRefs(challengeStore)
    const { editMode } =  storeToRefs(challengeStore)

    return { challengeStore, name, editMode }

  },

  computed: {},

  watch: {
  },

  mounted () {
    this.challengeStore.fetchWheelAnalysis()
  },

  methods: {}
}
