import { defineStore } from 'pinia'
import useCrosswalkAnalysisQuery from "@/composables/useCrosswalkAnalysisQuery";
import {useAnalysisStore} from "@/stores/Setup/analysis";
import axios from "axios";
import {useUserInfoStore} from "@/stores/Setup/userInfo";
import {useStrategyDetailsStore} from "@/stores/CrossWalkAnalysis/strategyDetails";


export const useCrosswalkChallengeStore = defineStore('crosswalk_challenge', {
    state: () => {
        return {
            challengeType:'',
            planUrl: '',
            name: '',
            editMode: null
        }
    },
    getters: {
    },
    actions: {
        async fetchCrosswalkAnalysis() {
             await useCrosswalkAnalysisQuery()
            //console.log(data)
        },
        challengeTypeChanged() {
            const analysisStore = useAnalysisStore()

            if (analysisStore.crosswalkAnalysisId !== null) {  // TODO: add header with token
                const challengeData = { "challenge_type": this.challengeType }
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/crosswalk_analyses/' + analysisStore.crosswalkAnalysisId + '/challenge_type', challengeData).then(response => {
                    console.log(response)
                })
            }

        },
        nameChanged() {
            const analysisStore = useAnalysisStore()
            if (analysisStore.crosswalkAnalysisId !== null) {  // TODO: add header with token
                const challengeData = { "name": this.name}
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/crosswalk_analyses/' + analysisStore.crosswalkAnalysisId  + '/name', challengeData).then(response => {
                    console.log(response)
                })
            }

        },
        planUrlChanged() {
            const analysisStore = useAnalysisStore()
            if (this.planUrl !== null) { // TODO: add header with token
                const challengeData = { "plan_url": this.planUrl }
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/crosswalk_analyses/' + analysisStore.crosswalkAnalysisId + '/plan_url', challengeData).then(response => {
                    console.log(response)
                })
            }

        },
        exitedField() {
            // When a field has lost focus, if there is no current record, create it
            // (subsequent data will be updated on changing of any field)
            const analysisStore = useAnalysisStore()
            const userInfoStore = useUserInfoStore()
            const strategyDetailsStore = useStrategyDetailsStore()

            if (analysisStore.crosswalkAnalysisId === null  && !analysisStore.creatingCrosswalkAnalysis) {

                analysisStore.creatingCrosswalkAnalysis = true
                const hexCode = [...Array(10)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
                //console.log(hexCode)
                //console.log("goalNumber:" + String(strategyDetailsStore.goalNumber))
                const crosswalkAnalysisData = {
                    user_info_id: userInfoStore.userInfoId,
                    analyses_id: userInfoStore.userInfoId,
                    matrix_type: strategyDetailsStore.matrixType,
                    goal_number: String(strategyDetailsStore.goalNumber),
                    selected_strategies: JSON.stringify(strategyDetailsStore.selectedStrategies),
                    selected_substrategies: JSON.stringify(strategyDetailsStore.selectedSubstrategies),
                    selected_targets: JSON.stringify(strategyDetailsStore.selectedTargets),
                    strategy_notes: JSON.stringify(strategyDetailsStore.strategyNotes),
                    all_strategy_details: strategyDetailsStore.allStrategyDetails,
                    challenge_type: this.challengeType,
                    plan_url: this.planUrl,
                    hex_code: hexCode,
                    name: this.name
                }
                /*const crosswalkAnalysisData = {  "all_strategy_details": [{name:"", keyword: "", subStrategies:[]},{name:"",  keyword: "", subStrategies:[]}],
                    "hex_code": hexCode,
                    "name": this.name
                }*/

                /*"challenge_type": "", "plan_url": "", "analyses_id": analysisStore.analysisId,
                    "matrix_type": "", "goal_number": "",
                    "selected_strategies": "[[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false],[false,false,false,false,false,false]]",
                    "selected_substrategies": "[[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]]",
                    "selected_targets": "[[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]],[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]]",
                    "strategy_notes": "[[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[],[]]",
                    "all_strategy_details": '[{name:"", keyword: "", subStrategies:[]},{name:"",  keyword: "", subStrategies:[]}]'
                */
                //console.log("sending crosswalk create data:")
                //console.log(crosswalkAnalysisData)
                analysisStore.crosswalkAnalysisHexCode = hexCode
                // TODO: add header with token
                axios.post(process.env.VUE_APP_API_ROOT + 'api/v1/crosswalk_analyses/create', crosswalkAnalysisData).then(response => {
                    console.log(response)
                    analysisStore.crosswalkAnalysisId = response.data.id
                    analysisStore.creatingCrosswalkAnalysis = false
                }).catch(error => {
                    this.error = error.message;
                    console.error("There was an error!", error);
                    analysisStore.creatingCrosswalkAnalysis = false
                })
            }
        }


    },
    persist: true
})