<template>
    <div class="Challenge">

      <!--<div id="title-header-div" >-->
        <!--<br>-->
        <!--<h1>The Challenge:</h1><h2>Using the SDGs to Review and Improve Planning</h2>-->
        <!--<br>-->
      <!--</div>-->
      <div id="form-div">

      <div class="row justify-content-center align-items-center px-3">
          <span>What kind of challenge are you exploring today:</span>

        <div class="col-md-9">
          <MDBRadio
              style="text-align:left"
              label="Comprehensive Plan"
              name="challengeType"
              v-model="challengeType"
              value="Comprehensive Plan"
              @blur = "crosswalkChallengeStore.challengeTypeChanged"
              :disabled = "!editMode"
          />
          <MDBRadio
              style="text-align:left"
              label="Current Strategic Plan"
              name="challengeType"
              v-model="challengeType"
              value="Current Strategic Plan"
              @blur = "crosswalkChallengeStore.challengeTypeChanged"
              :disabled = "!editMode"

          />
          <MDBRadio
              label="Implementation Plan"
              name="challengeType"
              v-model="challengeType"
              value="Implementation Plan"
              @blur = "crosswalkChallengeStore.challengeTypeChanged"
              :disabled = "!editMode"
          />
          <MDBRadio
              label="Sustainability Plan"
              name="challengeType"
              v-model="challengeType"
              value="Sustainability Plan"
              @blur = "crosswalkChallengeStore.challengeTypeChanged"
              :disabled = "!editMode"
          />
          <MDBRadio
              label="Other"
              name="challengeType"
              v-model="challengeType"
              value="Other"
              @blur = "crosswalkChallengeStore.challengeTypeChanged"
              :disabled = "!editMode"
          />
        </div>

        <div class="row justify-content-center align-items-center">
          <div class="col-md-12">
        Link to a digital version of the plan (if available):
          <MDBTextarea label="" rows="1" v-model="planUrl" @blur = "crosswalkChallengeStore.planUrlChanged"
                       @change = "crosswalkChallengeStore.planUrlChanged"
                       :disabled = "!editMode"/>
          </div>
        </div>
      </div>

      <!--<div class="prev-next">
        <a href="/about-org" class="btn btn-primary">Previous</a>
        <a href="/matrix-type" class="btn btn-primary">Next</a>
      </div>-->
    </div>
    </div>

  <router-view />
</template>

<script src="./_ChallengeView.js" lang="js"></script>
<style src="./_ChallengeView.less" lang="less" scoped></style>
