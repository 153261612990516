import {useUserInfoStore} from "@/stores/Setup/userInfo";
import {storeToRefs} from "pinia/dist/pinia";

export default {
  name: 'AboutMe',

  components: {},

  mixins: [],

  props: {},

  setup() {
    const userInfoStore = useUserInfoStore()

    const { firstName } =  storeToRefs(userInfoStore)
    const { lastName } =  storeToRefs(userInfoStore)
    const { email } =  storeToRefs(userInfoStore)
    const { city } =  storeToRefs(userInfoStore)
    const { jobTitle } =  storeToRefs(userInfoStore)
    const { state } =  storeToRefs(userInfoStore)
    const { editMode } = storeToRefs(userInfoStore)

    return { userInfoStore, firstName, lastName, email, city, jobTitle, state, editMode }

  },


  computed: {},

  watch: {
  },


  mounted () {
    this.userInfoStore.fetchUserInfo()
  },

  methods: {
  }
}
