<template>
    <div class="AboutMe">
      <!--<div id="title-header-div" >
        <br>
        <h1>About Me</h1>
        <br>
      </div>-->
      <div id="form-div">
        <div class="row justify-content-center align-items-center">
          <div class="col-md-12">
            <i class="icon icon-profile"></i>
            <label for="first-name">
              Name
            </label>
          </div>
          <div class="col-md-5">
            <input
                v-model="firstName"
                type="text"
                id="first-name"
                name="firstName"
                @blur="userInfoStore.exitedField"
                @change="userInfoStore.firstNameChanged"
                value
                placeholder="First Name"
                :disabled = "!editMode"
            />
          </div>

          <div class="col-md-6">
            <input
                v-model="lastName"
                type="text"
                id="last-name"
                name="lastName"
                @blur = "userInfoStore.exitedField"
                @change = "userInfoStore.lastNameChanged"
                value
                placeholder="Last Name"
                :disabled = "!editMode"
            />
          </div>
        </div>

        <div class="row justify-content-center align-items-center">
          <div class="col-md-12">
            <i class="icon icon-mail"></i>
            <label for="email">
              Email
            </label>
          </div>
          <div class="col-md-11">
            <input v-model="email" type="email" name="email" id="email" value placeholder="Email" @blur = "userInfoStore.exitedField" @change = "userInfoStore.emailChanged" :disabled = "!editMode"/>
          </div>
        </div>

        <div class="row justify-content-center align-items-center">
          <div class="col-md-12">
            <i class="icon icon-mail"></i>
            <label for="jobTitle">
              Job Title
            </label>
          </div>
          <div class="col-md-11">
            <input v-model="jobTitle" type="text" name="jobTitle" placeholder="Job Title" id="jobTitle" value @blur = "userInfoStore.exitedField" @change = "userInfoStore.jobTitleChanged" :disabled = "!editMode"/>
          </div>
        </div>

        <!-- Password -->
        <!--<div class="row justify-content-center align-items-center">
          <div class=" el-col-md-4">
            <i class="icon icon-lock"></i>
            <label for="password">
              Password*
            </label>
          </div>
          <div class="el-col-md-6">
            <fg-input
                v-model="password"
                type="password"
                id="password"
                name="password"
                value
            />
            <p style="font-size: 12px;">Password must be at least 10 characters long.</p>
          </div>
        </div>

        <div class="row justify-content-center align-items-center">
          <div class=" el-col-md-4">
            <i class="icon icon-lock"></i>
            <label for="passwordConfirm">
              Confirm Password*
            </label>
          </div>
          <div class="el-col-md-6">
            <fg-input
                v-model="passwordConfirm"
                type="password"
                id="passwordConfirm"
                name="passwordConfirm"
                value
            />
          </div>
        </div>-->

        <!-- Country -->
        <!--<div class="row justify-content-center align-items-center">
          <div class=" col-md-4">
            <i class="icon icon-world"></i>
            <label >Country</label>
          </div>

          <div class="col-md-6">
            <select
                id="country"
                v-model="country"
                @change="countryChange($event.target.value)"
                style="width:100%"
                filterable
            >
              <el-option
                  v-for="country in countryOptionsFiltered"
                  v-bind:key="country.key"
                  :value="country.key"
                  :label="country.value"
              >
                {{ country.value }}
              </el-option>
            </select>
          </div>
        </div>-->


        <div class="row justify-content-center align-items-center">
          <div class="col-md-12">
            <i class="icon icon-new-construction"></i>
            <label >City & State</label>
          </div>
          <div class=" col-md-7">
            <input v-model="city" type="text" id="city" name="city" value placeholder="City" style="margin-right:10px; margin-top:10px" @blur = "userInfoStore.exitedField" @change = "userInfoStore.cityChanged" :disabled = "!editMode"/>
          </div>
          <div class=" col-md-4">
            <input v-model="state" type="text" id="state" name="state" value placeholder="State" style="margin-right:10px; margin-top:10px" @blur = "userInfoStore.exitedField" @change = "userInfoStore.stateChanged" :disabled = "!editMode"/>
          </div>

        </div>


      </div>

      <!--<div class="prev-next">
        <a href="/using-tool" class="btn btn-primary">Previous</a>
        <a href="/about-org" class="btn btn-primary">Next</a>
      </div>-->
    </div>

  <router-view />
</template>

<script src="./_AboutMe.js" lang="js"></script>
<style src="./_AboutMe.less" lang="less" scoped></style>
