import { defineStore } from 'pinia'
import useWheelAnalysisQuery from "@/composables/useWheelAnalysisQuery";
import axios from "axios";
import {useAnalysisStore} from "@/stores/Setup/analysis";

export const useGoal11TargetsStore = defineStore('goal11Targets', {
    state: () => {
        return {
            selectedTargets: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
            targets: [
                {
                    "id": 11.1,
                    "number": 11.1,
                    "name": "Quality safe housing for all"
                },
                {
                    "id": 11.2,
                    "number": 11.2,
                    "name": "Safe, affordable, accessible and sustainable transport "
                },
                {
                    "id": 11.3,
                    "number": 11.3,
                    "name": "Integrated and sustainable city planning "
                },
                {
                    "id": "11.4",
                    "number": "11.4",
                    "name": "Protect  cultural and natural heritage"
                },
                {
                    "id": "11.5",
                    "number": "11.5",
                    "name": "Reduce dealth and loss caused by disasters, including water-related disasters."
                },
                {
                    "id": "11.6",
                    "number": "11.6",
                    "name": "Reduce the adverse impact of city on air quality and waste "
                },
                {
                    "id": "11.7",
                    "number": "11.7",
                    "name": "Access to safe, inclusive and accessible, green and public spaces"
                },
                {
                    "id": "11.a",
                    "number": "11.a",
                    "name": "Support  urban, peri-urban and rural cooperation"
                },
                {
                    "id": "11.b",
                    "number": "11.b",
                    "name": "Adopt  plans for mitigation and adaptation to climate change and disasters"
                },
                {
                    "id": "11.c",
                    "number": "11.c",
                    "name": "Build sustainable and resilient buildings "
                }
            ]
        }
    },
    getters: {
    },
    actions: {
        async fetchWheelAnalysis() {
            await useWheelAnalysisQuery()
            //console.log(data)
        },
        selectedTargetsChanged() {
            const analysisStore = useAnalysisStore()
            if (analysisStore.wheelAnalysisId  !== null && this.selectedTargets !== ""  && this.selectedTargets !== null && this.selectedTargets !== []) {  // TODO: add header with token
                const data = { "goal_11_selected_targets": [this.selectedTargets].toString()}
                //console.log(this.selectedTargets)
                //console.log(this.selectedTargets.length)
                //console.log(data)
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/wheel_analyses/' + analysisStore.wheelAnalysisId + '/goal_11_selected_targets', data).then(response => {
                    console.log(response)
                })
            }
        },

    },
    persist: true
})