import { defineStore } from 'pinia'
import useUserInfoQuery from "@/composables/useUserInfoQuery";
import axios from "axios";
import {useAnalysisStore} from "@/stores/Setup/analysis";


export const useUserInfoStore = defineStore('userInfo', {

    state: () => {
        return {
            firstName: '',
            lastName: '',
            email: '',
            jobTitle: '',
            city: '',
            state: '',
            orgName: '',
            token: '',
            userId: null,
            userInfoId: null,
            id: null,
            userWarnedAboutLogin: null,
            editMode: null
        }
    },
    // could also be defined as
    // state: () => ({ count: 0 })
    getters: {
        fullName(state) {
            return state.firstName + " " + state.lastName
        }
    },
    actions: {
        async fetchUserInfo() {
                await useUserInfoQuery()
                //console.log(data)
        },
        firstNameChanged() {
            if (this.userInfoId !== null) {  // TODO: add header with token
                const userInfoData = { "first_name": this.firstName, "last_name": this.lastName, "email": this.email, "job_title": this.jobTitle, "city": this.city, "state": this.state, "org_name": this.orgName}
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/user_infos/' + this.userInfoId + '/first_name', userInfoData).then(response => {
                    //console.log("first name changed via put!")
                    console.log(response)
                })
            }

        },
        lastNameChanged() {
            if (this.userInfoId !== null) { // TODO: add header with token
                const userInfoData = { "first_name": this.firstName, "last_name": this.lastName, "email": this.email, "job_title": this.jobTitle, "city": this.city, "state": this.state, "org_name": this.orgName}
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/user_infos/' + this.userInfoId + '/last_name', userInfoData).then(response => {
                    console.log(response)
                })
            }

        },
        emailChanged() {
            if (this.userInfoId !== null) { // TODO: add header with token
                const userInfoData = { "first_name": this.firstName, "last_name": this.lastName, "email": this.email, "job_title": this.jobTitle, "city": this.city, "state": this.state, "org_name": this.orgName}
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/user_infos/' + this.userInfoId + '/email', userInfoData).then(response => {
                    console.log(response)
                })
            }
        },
        jobTitleChanged() {
            if (this.userInfoId !== null) { // TODO: add header with token
                const userInfoData = { "first_name": this.firstName, "last_name": this.lastName, "email": this.email, "job_title": this.jobTitle, "city": this.city, "state": this.state, "org_name": this.orgName}
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/user_infos/' + this.userInfoId + '/job_title', userInfoData).then(response => {
                    console.log(response)
                })
            }
        },
        cityChanged() {
            if (this.userInfoId !== null) { // TODO: add header with token
                const userInfoData = { "first_name": this.firstName, "last_name": this.lastName, "email": this.email, "job_title": this.jobTitle, "city": this.city, "state": this.state, "org_name": this.orgName}
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/user_infos/' + this.userInfoId + '/city', userInfoData).then(response => {
                    console.log(response)
                })
            }
        },
        stateChanged() {
            if (this.userInfoId !== null) { // TODO: add header with token
                const userInfoData = { "first_name": this.firstName, "last_name": this.lastName, "email": this.email, "job_title": this.jobTitle, "city": this.city, "state": this.state, "org_name": this.orgName}
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/user_infos/' + this.userInfoId + '/city', userInfoData).then(response => {
                    console.log(response)
                })
            }
        },
        orgNameChanged() {
            if (this.userInfoId !== null) { // TODO: add header with token
                const userInfoData = { "first_name": this.firstName, "last_name": this.lastName, "email": this.email, "job_title": this.jobTitle, "city": this.city, "state": this.state, "org_name": this.orgName}
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/user_infos/' + this.userInfoId + '/org_name', userInfoData).then(response => {
                    console.log(response)
                })
            }
        },
        exitedField() {
            // When a field has lost focus, if there is no current user info record, create it
            // (subsequent data will be updated on changing of any field)
            if (this.userInfoId === null) {
                let userId = this.userId
                if (userId === null)
                    userId = this.id

                const userInfoData = {
                    "first_name": this.firstName,
                    "last_name": this.lastName,
                    "email": this.email,
                    "job_title": this.jobTitle,
                    "city": this.city,
                    "state": this.state,
                    "org_name": this.orgName,
                    "users_id": userId
                }

                //console.log(userInfoData)
                // TODO: add header with token
                axios.post(process.env.VUE_APP_API_ROOT + 'api/v1/user_infos/create', userInfoData).then(response => {
                    //console.log("first name changed post!")
                    console.log(response)
                    this.userInfoId = response.data.id


                    // create new analysis record for this user info record (only one analysis per user info)
                    axios.post(process.env.VUE_APP_API_ROOT + 'api/v1/analyses/create', userInfoData).then(response => {
                        const analysisStore = useAnalysisStore()
                        analysisStore.analysisId = response.data.id
                    }).catch(error => {
                        this.error = error.message;
                        console.error("There was an error!", error);
                    })



                }).catch(error => {
                    this.error = error.message;
                    console.error("There was an error!", error);

                })
            }
        }


    },
    persist: true
})