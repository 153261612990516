<template>
    <div class="GoalDetails">
          <div class="row justify-content-left align-items-left">
            <div class="col-md-12">
              <br/>
              <div v-show="currentDetailType==='goal'">
                <div class="">
                <div class="offset-md-2 col-md-8">
                <img :src="'/images/E-WEB-Goal-' + String(currentGoalId).padStart(2, '0')  + '.png'" style="height:120px; width:auto; ">
                </div>
                <div class="offset-1 col-md-10" style="text-align:left">
                  <br/>
                  <strong>Goal {{ currentGoalId }}:</strong> {{ goalDescription}}
                  <br/>
                  <br/>
                  <MDBCheckbox :disabled = "!editMode" v-model="selectedCriticalGoals[currentGoalId-1]" id="critical-goal" label="Critical Goal" size="small" />
                </div>
                </div>
                <br/>
                <div class="" >
                <a :href="'https://sdgs.un.org/goals/goal'+ currentGoalId" target="_blank" class="btn btn-secondary">More Info</a>
                </div>
                <br/>
                <!--<div class="">
                  <div class="offset-1 col-md-11" style="text-align:left">
                    Related Challenges:<br/>
                    Related Keywords:
                  </div>
                </div>-->
              </div>
              <div v-show="currentDetailType==='target'" >
                <div class="offset-2 col-md-8">
                  <img :src="'/images/GOAL_' + String(currentGoalId) + '_TARGET_'  + currentTargetId?.toUpperCase()  + '.png'" style="height:120px; width:auto; " :class="targetIndex"/>
                </div>
                <div class="offset-1 col-md-11" style="text-align:left">
                  <br/>
                <strong>Target {{ currentTargetId }}:</strong> {{ targetDescription}}
                </div>
                <br/>
                <div class="">
                  <div class="offset-1 col-md-11" style="text-align:left">
                    <strong>Indicators</strong>
                    <div v-for="indicator in indicators" :key="indicator.id">
                      <strong>{{ indicator.id }}:</strong> {{indicator.name}}
                    </div>
                  </div>
                </div>
                <br/>
                <br/>
                <!--<div class="row">
                  <div class="offset-1 col-md-11" style="text-align:left">
                    Related Challenges:<br/>
                    Related Keywords:
                  </div>
                </div>-->
              </div>


            </div>
          </div>

    </div>
</template>

<script src="./_GoalDetails.js" lang="js"></script>
<style src="./_GoalDetails.less" lang="less"></style>


