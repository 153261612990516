<template>
  <MDBFooter bg="#eae9e9" :text="['center', 'white']" >

    <div class="footer-nav-container">
    </div>
    <br/>
    <div class="footer-crest-container">
      <div class="footer-uw-crest" style="color:white">

        <div class="row" style="margin-left: auto;
    margin-right: auto;
    display: inline-flex;">
        <div class="col-lg-3 p-2" style="margin-top:30px; min-width:300px">
          <img class="logo" src="/images/SDG Wheel_Transparent_WEB.png" style="height:40px;margin-top:-5px; ">
          <span style="font-size:24px; ">SDG 360 Thinking</span><br/>
        </div>
        <div class="col-lg-2 p-2">
        <img class="logo" src="/images/uw-logo.png" style="height:80px;"><br/>
        </div>
        </div>

      </div>

      <br/>
      <span style="font-size:20px">
      <a href="https://datascience.wisc.edu/institute/">Data Science Institute, University of Wisconsin-Madison</a>
      </span>

      <!-- Copyright -->
      <div
          class="text-center p-4"
          style="font-size: 12px"
      >
        Website feedback, questions or accessibility issues: <a href="mailto:sdg360@datascience.wisc.edu">sdg360@datascience.wisc.edu</a>.<br><br>
        Copyright &copy; 2023 <a href="https://www.wisconsin.edu/regents/" target="_blank">The Board of Regents of the University of Wisconsin System</a>
      </div>
    </div>



</MDBFooter>
  <!-- Global site tag (gtag.js) - Google Analytics -->

  <!--<script async src="https://www.googletagmanager.com/gtag/js?id=UA-146616978-1"></script>

  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'UA-146616978-1');
  </script>-->

</template>

<script>
  import { MDBFooter } from 'mdb-vue-ui-kit';
    export default {
        name: 'footerDiv',
        components: {
          MDBFooter,
        }
    };
</script>
