import { defineStore } from 'pinia'

export const useGoalDetailsStore = defineStore('goalDetails', {
    state: () => {
        return {
            currentDetailType: "goal",
            currentGoalId: null,
            currentTargetId: null,
            currentGoalDetail: null,
            currentTargetDetail: null,
            allGoalDetails: [{id: "1", name:"1: No Poverty", description:"End poverty in all its forms everywhere"},
                {id: "2", name:"2: Zero Hunger", description:"End hunger, achieve food security and improved nutrition and promote sustainable agriculture"},
                {id: "3", name:"3: Good Health and Well-Being", "description":"Ensure healthy lives and promote well-being for all at all ages"},
                {id: "4", name:"4: Quality Education", "description":"Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all", },
                {id: "5", name:"5: Gender Equality", "description":"Achieve gender equality and empower all women and girls"},
                {id: "6", name:"6: Clean Water and Sanitation", "description":"Ensure availability and sustainable management of water and sanitation for all"},
                {id: "7", name:"7: Affordable and Clean Energy", "description":"Ensure access to affordable, reliable, sustainable and modern energy for all"},
                {id: "8", name:"8: Decent Work and Economic Growth", "description":"Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all"},
                {id: "9", name:"9: Industry, Innovation and Infrastructure", "description":"Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation"},
                {id: "10", name:"10: Reduced Inequalities", "description":"Reduce inequality within and among countries"},
                {"id":"11", "number": "11","name": "11: Sustainable Cities and Communities", "description": "Make cities and human settlements inclusive, safe, resilient and sustainable"},
                {id: "12", name:"12: Responsible Consumption and Production", "description":"Ensure sustainable consumption and production patterns"},
                {id: "13", name:"13: Climate Action", "description":"Take urgent action to combat climate change and its impacts"},
                {id: "14", name:"14: Life Below Water", "description":"Conserve and sustainably use the oceans, seas and marine resources for sustainable development"},
                {id: "15", name:"15: Life On Land", "description":"Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss"},
                {id: "16", name:"16: Peace, Justice and Strong Institutions", "description":"Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels"},
                {id: "17", name:"17: Partnerships for the Goals", "description":"Strengthen the means of implementation and revitalize the Global Partnership for Sustainable Development"}],
            allTargetDetails: [
                {
                    "id": "1.1",
                    "number": "1.1",
                    "name": "By 2030, eradicate extreme poverty for all people everywhere, currently measured as people living on less than $1.25 a day",
                    "indicators": [
                        {
                            "id": "1.1.1",
                            "number": "1.1.1",
                            "name": "Proportion of population below the international poverty line, by sex, age, employment status and geographical location (urban/rural)"
                        }
                    ]
                },
                {
                    "id": "1.2",
                    "number": "1.2",
                    "name": "By 2030, reduce at least by half the proportion of men, women and children of all ages living in poverty in all its dimensions according to national definitions",
                    "indicators": [
                        {
                            "id": "1.2.1",
                            "number": "1.2.1",
                            "name": "Proportion of population living below the national poverty line, by sex and age"
                        },
                        {
                            "id": "1.2.2",
                            "number": "1.2.2",
                            "name": "Proportion of men, women and children of all ages living in poverty in all its dimensions according to national definitions"
                        }
                    ]
                },
                {
                    "id": "1.3",
                    "number": "1.3",
                    "name": "Implement nationally appropriate social protection systems and measures for all, including floors, and by 2030 achieve substantial coverage of the poor and the vulnerable",
                    "indicators": [
                        {
                            "id": "1.3.1",
                            "number": "1.3.1",
                            "name": "Proportion of population covered by social protection floors/systems, by sex, distinguishing children, unemployed persons, older persons, persons with disabilities, pregnant women, newborns, work-injury victims and the poor and the vulnerable"
                        }
                    ]
                },
                {
                    "id": "1.4",
                    "number": "1.4",
                    "name": "By 2030, ensure that all men and women, in particular the poor and the vulnerable, have equal rights to economic resources, as well as access to basic services, ownership and control over land and other forms of property, inheritance, natural resources, appropriate new technology and financial services, including microfinance",
                    "indicators": [
                        {
                            "id": "1.4.1",
                            "number": "1.4.1",
                            "name": "Proportion of population living in households with access to basic services"
                        },
                        {
                            "id": "1.4.2",
                            "number": "1.4.2",
                            "name": "Proportion of total adult population with secure tenure rights to land, (a) with legally recognized documentation, and (b) who perceive their rights to land as secure, by sex and type of tenure"
                        }
                    ]
                },
                {
                    "id": "1.5",
                    "number": "1.5",
                    "name": "By 2030, build the resilience of the poor and those in vulnerable situations and reduce their exposure and vulnerability to climate-related extreme events and other economic, social and environmental shocks and disasters",
                    "indicators": [
                        {
                            "id": "1.5.1",
                            "number": "1.5.1",
                            "name": "Number of deaths, missing persons and directly affected persons attributed to disasters per 100,000 population"
                        },
                        {
                            "id": "1.5.2",
                            "number": "1.5.2",
                            "name": "Direct economic loss attributed to disasters in relation to global gross domestic product (GDP)"
                        },
                        {
                            "id": "1.5.3",
                            "number": "1.5.3",
                            "name": "Number of countries that adopt and implement national disaster risk reduction strategies in line with the Sendai Framework for Disaster Risk Reduction 2015-2030"
                        }
                    ]
                },
                {
                    "id": "1.a",
                    "number": "1.a",
                    "name": "Ensure significant mobilization of resources from a variety of sources, including through enhanced development cooperation, in order to provide adequate and predictable means for developing countries, in particular least developed countries, to implement programmes and policies to end poverty in all its dimensions",
                    "indicators": [
                        {
                            "id": "1.a.1",
                            "number": "1.a.1",
                            "name": "Proportion of domestically generated resources allocated by the government directly to poverty reduction programmes"
                        },
                        {
                            "id": "1.a.2",
                            "number": "1.a.2",
                            "name": "Proportion of total government spending on essential services (education, health and social protection)"
                        }
                    ]
                },
                {
                    "id": "1.b",
                    "number": "1.b",
                    "name": "Create sound policy frameworks at the national, regional and international levels, based on pro-poor and gender-sensitive development strategies, to support accelerated investment in poverty eradication actions",
                    "indicators": [
                        {
                            "id": "1.b.1",
                            "number": "1.b.1",
                            "name": "Proportion of government recurrent and capital spending to sectors that disproportionately benefit women, the poor and vulnerable groups"
                        },
                        {
                            "id": "1.b.2",
                            "number": "1.b.2",
                            "name": "Number of countries with sustainable debt service, defined as the number of countries where the ratio of the total external debt service to the total exports (goods and services) is less than or equal to 30 percent"
                        }
                    ]
                },
                {
                    "id": "1.c",
                    "number": "1.c",
                    "name": "Ensure significant mobilization of resources from a variety of sources, including through enhanced development cooperation, to provide adequate and predictable means for developing countries, in particular least developed countries, to implement programmes and policies to end poverty in all its dimensions",
                    "indicators": [
                        {
                            "id": "1.c.1",
                            "number": "1.c.1",
                            "name": "Proportion of population covered by social protection floors/systems, by sex, distinguishing children, unemployed persons, older persons, persons with disabilities, pregnant women, newborns, work-injury victims and the poor and the vulnerable"
                        }
                    ]
                },
                {
                        "id": "2.1",
                        "number": "2.1",
                        "name": "By 2030, end hunger and ensure access by all people, in particular the poor and people in vulnerable situations, including infants, to safe, nutritious and sufficient food all year round",
                        "indicators": [
                            {
                                "id": "2.1.1",
                                "number": "2.1.1",
                                "name": "Prevalence of undernourishment in the population, based on the Food and Agriculture Organization (FAO) methodology"
                            },
                            {
                                "id": "2.1.2",
                                "number": "2.1.2",
                                "name": "Prevalence of moderate or severe food insecurity in the population, based on the Food and Agriculture Organization (FAO) methodology"
                            }
                        ]
                    },
                    {
                        "id": "2.2",
                        "number": "2.2",
                        "name": "By 2030, end all forms of malnutrition, including achieving, by 2025, the internationally agreed targets on stunting and wasting in children under 5 years of age, and address the nutritional needs of adolescent girls, pregnant and lactating women, and older persons",
                        "indicators": [
                            {
                                "id": "2.2.1",
                                "number": "2.2.1",
                                "name": "Prevalence of stunting (height for age <-2 standard deviation from the median of the World Health Organization (WHO) Child Growth Standards) among children under 5 years of age"
                            },
                            {
                                "id": "2.2.2",
                                "number": "2.2.2",
                                "name": "Prevalence of malnutrition (weight for height >+2 or <-2 standard deviation from the median of the WHO Child Growth Standards) among children under 5 years of age, by type (wasting and overweight)"
                            },
                            {
                                "id": "2.2.3",
                                "number": "2.2.3",
                                "name": "Proportion of the population with access to safe, nutritious and sufficient food all year round"
                            }
                        ]
                    },
                    {
                        "id": "2.3",
                        "number": "2.3",
                        "name": "By 2030, double the agricultural productivity and incomes of small-scale food producers, in particular women, indigenous peoples, family farmers, pastoralists and fishers, including through secure and equal access to land, other productive resources and inputs, knowledge, financial services, markets and opportunities for value addition and non-farm employment",
                        "indicators": [
                            {
                                "id": "2.3.1",
                                "number": "2.3.1",
                                "name": "Volume of production per labour unit by classes of farming/pastoral/forestry enterprise size"
                            },
                            {
                                "id": "2.3.2",
                                "number": "2.3.2",
                                "name": "Average income of small-scale food producers, by sex and indigenous status"
                            }
                        ]
                    },
                    {
                        "id": "2.4",
                        "number": "2.4",
                        "name": "By 2030, ensure sustainable food production systems and implement resilient agricultural practices that increase productivity and production, that help maintain ecosystems, that strengthen capacity for adaptation to climate change, extreme weather, drought, flooding and other disasters, and that progressively improve land and soil quality",
                        "indicators": [
                            {
                                "id": "2.4.1",
                                "number": "2.4.1",
                                "name": "Proportion of agricultural area under productive and sustainable agriculture"
                            },
                            {
                                "id": "2.4.2",
                                "number": "2.4.2",
                                "name": "Proportion of total agricultural population with ownership or secure rights over agricultural land, by sex; and share of women among owners or rights-bearers of agricultural land, by type of tenure"
                            }
                        ]
                    },
                    {
                        "id": "2.5",
                        "number": "2.5",
                        "name": "By 2020, maintain the genetic diversity of seeds, cultivated plants and farmed and domesticated animals and their related wild species, including through soundly managed and diversified seed and plant banks at the national, regional and international levels, and promote access to and fair and equitable sharing of benefits arising from the utilization of genetic resources and associated traditional knowledge, as internationally agreed",
                        "indicators": [
                            {
                                "id": "2.5.1",
                                "number": "2.5.1",
                                "name": "Number of plant and animal genetic resources for food and agriculture secured in either medium or long-term conservation facilities"
                            }
                        ]
                    },
                    {
                        "id": "2.a",
                        "number": "2.a",
                        "name": "Increase investment, including through enhanced international cooperation, in rural infrastructure, agricultural research and extension services, technology development and plant and livestock gene banks to enhance agricultural productive capacity in developing countries, in particular least developed countries",
                        "indicators": [
                            {
                                "id": "2.a.1",
                                "number": "2.a.1",
                                "name": "The agriculture orientation index for government expenditures"
                            }
                        ]
                    },
                    {
                        "id": "2.b",
                        "number": "2.b",
                        "name": "Correct and prevent trade restrictions and distortions in world agricultural markets, including through the parallel elimination of all forms of agricultural export subsidies and all export measures with equivalent effect, in accordance with the mandate of the Doha Development Round",
                        "indicators": [
                            {
                                "id": "2.b.1",
                                "number": "2.b.1",
                                "name": "Producer Support Estimate (PSE) as a percentage of the value of agricultural production"
                            }
                        ]
                    },
                    {
                        "id": "2.c",
                        "number": "2.c",
                        "name": "Adopt measures to ensure the proper functioning of food commodity markets and their derivatives, and facilitate timely access to market information, including on food reserves, in order to help limit extreme food price volatility",
                        "indicators": [
                            {
                                "id": "2.c.1",
                                "number": "2.c.1",
                                "name": "Indicator of food price anomalies"
                            }
                        ]
                    },
                {
                    "id": "3.1",
                    "number": "3.1",
                    "name": "By 2030, reduce the global maternal mortality ratio to less than 70 per 100,000 live births",
                    "indicators": [
                        {
                            "id": "3.1.1",
                            "number": "3.1.1",
                            "name": "Maternal mortality ratio"
                        }
                    ]
                },
                {
                    "id": "3.2",
                    "number": "3.2",
                    "name": "By 2030, end preventable deaths of newborns and children under 5 years of age, with all countries aiming to reduce neonatal mortality to at least as low as 12 per 1,000 live births and under-5 mortality to at least as low as 25 per 1,000 live births",
                    "indicators": [
                        {
                            "id": "3.2.1",
                            "number": "3.2.1",
                            "name": "Under-5 mortality rate"
                        },
                        {
                            "id": "3.2.2",
                            "number": "3.2.2",
                            "name": "Neonatal mortality rate"
                        }
                    ]
                },
                {
                    "id": "3.3",
                    "number": "3.3",
                    "name": "By 2030, end the epidemics of AIDS, tuberculosis, malaria and neglected tropical diseases and combat hepatitis, water-borne diseases and other communicable diseases",
                    "indicators": [
                        {
                            "id": "3.3.1",
                            "number": "3.3.1",
                            "name": "Number of new HIV infections per 1,000 uninfected population, by sex, age and key populations"
                        },
                        {
                            "id": "3.3.2",
                            "number": "3.3.2",
                            "name": "Tuberculosis incidence per 1,000 population"
                        },
                        {
                            "id": "3.3.3",
                            "number": "3.3.3",
                            "name": "Malaria incidence per 1,000 population"
                        },
                        {
                            "id": "3.3.4",
                            "number": "3.3.4",
                            "name": "Hepatitis B incidence per 100,000 population"
                        },
                        {
                            "id": "3.3.5",
                            "number": "3.3.5",
                            "name": "Number of people requiring interventions against neglected tropical diseases"
                        },
                        {
                            "id": "3.3.6",
                            "number": "3.3.6",
                            "name": "Number of deaths caused by waterborne diseases"
                        }
                    ]
                },
                {
                    "id": "3.4",
                    "number": "3.4",
                    "name": "By 2030, reduce by one third premature mortality from non-communicable diseases through prevention and treatment and promote mental health and well-being",
                    "indicators": [
                        {
                            "id": "3.4.1",
                            "number": "3.4.1",
                            "name": "Mortality rate attributed to cardiovascular disease, cancer, diabetes or chronic respiratory disease"
                        },
                        {
                            "id": "3.4.2",
                            "number": "3.4.2",
                            "name": "Suicide mortality rate"
                        }
                    ]
                },
                {
                    "id": "3.5",
                    "number": "3.5",
                    "name": "Strengthen the prevention and treatment of substance abuse, including narcotic drug abuse and harmful use of alcohol",
                    "indicators": [
                        {
                            "id": "3.5.1",
                            "number": "3.5.1",
                            "name": "Coverage of treatment interventions (pharmacological, psychosocial and rehabilitation and aftercare services) for substance use disorders"
                        }
                    ]
                },
                {
                    "id": "3.6",
                    "number": "3.6",
                    "name": "By 2020, halve the number of global deaths and injuries from road traffic accidents",
                    "indicators": [
                        {
                            "id": "3.6.1",
                            "number": "3.6.1",
                            "name": "Death rate due to road traffic injuries"
                        }
                    ]
                },
                {
                    "id": "3.7",
                    "number": "3.7",
                    "name": "By 2030, ensure universal access to sexual and reproductive health-care services, including for family planning, information and education, and the integration of reproductive health into national strategies and programmes",
                    "indicators": [
                        {
                            "id": "3.7.1",
                            "number": "3.7.1",
                            "name": "Proportion of women of reproductive age (aged 15-49 years) who have their need for family planning satisfied with modern methods"
                        },
                        {
                            "id": "3.7.2",
                            "number": "3.7.2",
                            "name": "Adolescent birth rate (aged 10-14 years; aged 15-19 years) per 1,000 women in that age group"
                        },
                        {
                            "id": "3.7.3",
                            "number": "3.7.3",
                            "name": "Percentage of women aged 15-49 years subjected to sexual violence by persons other than an intimate partner in the previous 12 months"
                        },
                        {
                            "id": "3.7.4",
                            "number": "3.7.4",
                            "name": "Number of new HIV infections per 1,000 uninfected population, by sex, age and key populations"
                        }
                    ]
                },
                {
                    "id": "3.8",
                    "number": "3.8",
                    "name": "Achieve universal health coverage, including financial risk protection, access to quality essential health-care services and access to safe, effective, quality and affordable essential medicines and vaccines for all",
                    "indicators": [
                        {
                            "id": "3.8.1",
                            "number": "3.8.1",
                            "name": "Coverage of essential health services (defined as the average coverage of essential services based on tracer interventions that include reproductive, maternal, newborn and child health, infectious diseases, non-communicable diseases and service capacity and access, among the general and the most disadvantaged population)"
                        },
                        {
                            "id": "3.8.2",
                            "number": "3.8.2",
                            "name": "Proportion of population with large household expenditures on health as a share of total household expenditure or income"
                        }
                    ]
                },
                {
                    "id": "3.9",
                    "number": "3.9",
                    "name": "By 2030, substantially reduce the number of deaths and illnesses from hazardous chemicals and air, water and soil pollution and contamination",
                    "indicators": [
                        {
                            "id": "3.9.1",
                            "number": "3.9.1",
                            "name": "Mortality rate attributed to household and ambient air pollution"
                        },
                        {
                            "id": "3.9.2",
                            "number": "3.9.2",
                            "name": "Mortality rate attributed to unsafe water, unsafe sanitation and lack of hygiene (exposure to unsafe Water, Sanitation and Hygiene for All (WASH) services)"
                        },
                        {
                            "id": "3.9.3",
                            "number": "3.9.3",
                            "name": "Mortality rate attributed to unintentional poisoning"
                        }
                    ]
                },
                {
                    "id": "3.a",
                    "number": "3.a",
                    "name": "Strengthen the implementation of the World Health Organization Framework Convention on Tobacco Control in all countries, as appropriate",
                    "indicators": [
                        {
                            "id": "3.a.1",
                            "number": "3.a.1",
                            "name": "Age-standardized prevalence of current tobacco use among persons aged 15 years and older"
                        }
                    ]
                },
                {
                    "id": "3.b",
                    "number": "3.b",
                    "name": "Support the research and development of vaccines and medicines for the communicable and non-communicable diseases that primarily affect developing countries, provide access to affordable essential medicines and vaccines, in accordance with the Doha Declaration on the TRIPS Agreement and Public Health, which affirms the right of developing countries to use to the full the provisions in the Agreement on Trade-Related Aspects of Intellectual Property Rights regarding flexibilities to protect public health, and, in particular, provide access to medicines for all",
                    "indicators": [
                        {
                            "id": "3.b.1",
                            "number": "3.b.1",
                            "name": "Proportion of the population with access to affordable medicines and vaccines on a sustainable basis"
                        }
                    ]
                },
                {
                    "id": "3.c",
                    "number": "3.c",
                    "name": "Substantially increase health financing and the recruitment, development, training and retention of the health workforce in developing countries, especially in least developed countries and small island developing States",
                    "indicators": [
                        {
                            "id": "3.c.1",
                            "number": "3.c.1",
                            "name": "Health worker density and distribution"
                        }
                    ]
                },
                {
                    "id": "3.d",
                    "number": "3.d",
                    "name": "Strengthen the capacity of all countries, in particular developing countries, for early warning, risk reduction and management of national and global health risks",
                    "indicators": [
                        {
                            "id": "3.d.1",
                            "number": "3.d.1",
                            "name": "International Health Regulations (IHR) capacity and health emergency preparedness"
                        }
                    ]
                },
                {
                    "id": "4.1",
                    "number": "4.1",
                    "name": "By 2030, ensure that all girls and boys complete free, equitable and quality primary and secondary education leading to relevant and effective learning outcomes",
                    "indicators": [
                        {
                            "id": "4.1.1",
                            "number": "4.1.1",
                            "name": "Proportion of children and young people: (a) in grades 2/3; (b) at the end of primary; and (c) at the end of lower secondary achieving at least a minimum proficiency level in (i) reading and (ii) mathematics, by sex"
                        }
                    ]
                },
                {
                    "id": "4.2",
                    "number": "4.2",
                    "name": "By 2030, ensure that all girls and boys have access to quality early childhood development, care, and pre-primary education so that they are ready for primary education",
                    "indicators": [
                        {
                            "id": "4.2.1",
                            "number": "4.2.1",
                            "name": "Proportion of children under 5 years of age who are developmentally on track in health, learning, and psychosocial well-being, by sex"
                        }
                    ]
                },
                {
                    "id": "4.3",
                    "number": "4.3",
                    "name": "By 2030, ensure equal access for all women and men to affordable and quality technical, vocational, and tertiary education, including university",
                    "indicators": [
                        {
                            "id": "4.3.1",
                            "number": "4.3.1",
                            "name": "Participation rate of youth and adults in formal and non-formal education and training in the previous 12 months, by sex"
                        }
                    ]
                },
                {
                    "id": "4.4",
                    "number": "4.4",
                    "name": "By 2030, substantially increase the number of youth and adults who have relevant skills, including technical and vocational skills, for employment, decent jobs, and entrepreneurship",
                    "indicators": [
                        {
                            "id": "4.4.1",
                            "number": "4.4.1",
                            "name": "Proportion of youth and adults with information and communications technology (ICT) skills, by type of skill"
                        }
                    ]
                },
                {
                    "id": "4.5",
                    "number": "4.5",
                    "name": "By 2030, eliminate gender disparities in education and ensure equal access to all levels of education and vocational training for the vulnerable, including persons with disabilities, indigenous peoples, and children in vulnerable situations",
                    "indicators": [
                        {
                            "id": "4.5.1",
                            "number": "4.5.1",
                            "name": "Parity indices (female/male, rural/urban, bottom/top wealth quintile, and others such as disability status, indigenous peoples, and conflict-affected, as data become available) for all education indicators on this list that can be disaggregated"
                        }
                    ]
                },
                {
                    "id": "4.6",
                    "number": "4.6",
                    "name": "By 2030, ensure that all youth and a substantial proportion of adults, both men and women, achieve literacy and numeracy",
                    "indicators": [
                        {
                            "id": "4.6.1",
                            "number": "4.6.1",
                            "name": "Percentage of population in a given age group achieving at least a fixed level of proficiency in functional (a) literacy and (b) numeracy skills, by sex"
                        }
                    ]
                },
                {
                    "id": "4.7",
                    "number": "4.7",
                    "name": "By 2030, ensure that all learners acquire the knowledge and skills needed to promote sustainable development, including, among others, through education for sustainable development and sustainable lifestyles, human rights, gender equality, promotion of a culture of peace and non-violence, global citizenship, and appreciation of cultural diversity and of culture's contribution to sustainable development",
                    "indicators": [
                        {
                            "id": "4.7.1",
                            "number": "4.7.1",
                            "name": "Extent to which (i) global citizenship education and (ii) education for sustainable development are mainstreamed in (a) national education policies; (b) curricula; (c) teacher education; and (d) student assessment"
                        }
                    ]
                },
                {
                    "id": "4.a",
                    "number": "4.a",
                    "name": "Build and upgrade education facilities that are child, disability, and gender sensitive and provide safe, nonviolent, inclusive, and effective learning environments for all",
                    "indicators": [
                        {
                            "id": "4.a.1",
                            "number": "4.a.1",
                            "name": "Proportion of schools with access to: (a) electricity; (b) the Internet for pedagogical purposes; (c) computers for pedagogical purposes; (d) adapted infrastructure and materials for students with disabilities; (e) basic drinking water; (f) single-sex basic sanitation facilities; and (g) basic handwashing facilities (as per the WASH indicator definitions)"
                        }
                    ]
                },
                {
                    "id": "4.b",
                    "number": "4.b",
                    "name": "By 2020, substantially expand globally the number of scholarships available to developing countries, in particular least developed countries, small island developing States, and African countries, for enrolment in higher education, including vocational training and information and communications technology, technical, engineering, and scientific programs, in developed countries and other developing countries",
                    "indicators": [
                        {
                            "id": "4.b.1",
                            "number": "4.b.1",
                            "name": "Volume of official development assistance flows for scholarships by sector and type of study"
                        }
                    ]
                },
                {
                    "id": "4.c",
                    "number": "4.c",
                    "name": "By 2030, substantially increase the supply of qualified teachers, including through international cooperation for teacher training in developing countries, especially least developed countries and small island developing States",
                    "indicators": [
                        {
                            "id": "4.c.1",
                            "number": "4.c.1",
                            "name": "Proportion of teachers in: (a) pre-primary; (b) primary; (c) lower secondary; and (d) upper secondary education who have received at least the minimum organized teacher training (e.g., pedagogical training) pre-service or in-service required for teaching at the relevant level in a given country"
                        }
                    ]
                },
                {
                    "id": "4.d",
                    "number": "4.d",
                    "name": "By 2030, substantially increase the number of youth and adults who have relevant skills, including technical and vocational skills, for employment, decent jobs, and entrepreneurship",
                    "indicators": [
                        {
                            "id": "4.d.1",
                            "number": "4.d.1",
                            "name": "Proportion of youth and adults with information and communications technology (ICT) skills, by type of skill"
                        }
                    ]
                },
                {
                    "id": "5.1",
                    "number": "5.1",
                    "name": "End all forms of discrimination against all women and girls everywhere",
                    "indicators": [
                        {
                            "id": "5.1.1",
                            "number": "5.1.1",
                            "name": "Whether or not legal frameworks are in place to promote, enforce, and monitor equality and non-discrimination on the basis of sex"
                        }
                    ]
                },
                {
                    "id": "5.2",
                    "number": "5.2",
                    "name": "Eliminate all forms of violence against all women and girls in the public and private spheres, including trafficking and sexual and other types of exploitation",
                    "indicators": [
                        {
                            "id": "5.2.1",
                            "number": "5.2.1",
                            "name": "Proportion of ever-partnered women and girls aged 15 years and older subjected to physical, sexual, or psychological violence by a current or former intimate partner in the previous 12 months, by form of violence and by age"
                        },
                        {
                            "id": "5.2.2",
                            "number": "5.2.2",
                            "name": "Proportion of women and girls aged 15 years and older subjected to sexual violence by persons other than an intimate partner, in the previous 12 months, by age and place of occurrence"
                        },
                        {
                            "id": "5.2.3",
                            "number": "5.2.3",
                            "name": "Proportion of women and girls aged 15 years and older subjected to violence by a non-partner, in the previous 12 months, by age and by violence type"
                        }
                    ]
                },
                {
                    "id": "5.3",
                    "number": "5.3",
                    "name": "Eliminate all harmful practices, such as child, early and forced marriage and female genital mutilation",
                    "indicators": [
                        {
                            "id": "5.3.1",
                            "number": "5.3.1",
                            "name": "Proportion of women aged 20-24 years who were married or in a union before age 15 and before age 18"
                        },
                        {
                            "id": "5.3.2",
                            "number": "5.3.2",
                            "name": "Proportion of girls and women aged 15-49 years who have undergone female genital mutilation/cutting, by age"
                        }
                    ]
                },
                {
                    "id": "5.4",
                    "number": "5.4",
                    "name": "Recognize and value unpaid care and domestic work through the provision of public services, infrastructure and social protection policies and the promotion of shared responsibility within the household and the family as nationally appropriate",
                    "indicators": [
                        {
                            "id": "5.4.1",
                            "number": "5.4.1",
                            "name": "Proportion of time spent on unpaid domestic and care work, by sex, age and location"
                        },
                        {
                            "id": "5.4.2",
                            "number": "5.4.2",
                            "name": "Proportion of women and girls aged 10 years and older who make their own informed decisions regarding sexual relations, contraceptive use and reproductive health care"
                        }
                    ]
                },
                {
                    "id": "5.5",
                    "number": "5.5",
                    "name": "Ensure women's full and effective participation and equal opportunities for leadership at all levels of decision-making in political, economic and public life",
                    "indicators": [
                        {
                            "id": "5.5.1",
                            "number": "5.5.1",
                            "name": "Proportion of seats held by women in (a) national parliaments and (b) local governments"
                        },
                        {
                            "id": "5.5.2",
                            "number": "5.5.2",
                            "name": "Proportion of women in managerial positions"
                        }
                    ]
                },
                {
                    "id": "5.6",
                    "number": "5.6",
                    "name": "Ensure universal access to sexual and reproductive health and reproductive rights as agreed in accordance with the Programme of Action of the International Conference on Population and Development and the Beijing Platform for Action and the outcome documents of their review conferences",
                    "indicators": [
                        {
                            "id": "5.6.1",
                            "number": "5.6.1",
                            "name": "Proportion of women aged 15-49 years who make their own informed decisions regarding sexual relations, contraceptive use and reproductive health care"
                        },
                        {
                            "id": "5.6.2",
                            "number": "5.6.2",
                            "name": "Number of countries with laws and regulations that guarantee full and equal access to women and men aged 15 years and older to sexual and reproductive health care, information and education"
                        }
                    ]
                },
                {
                    "id": "5.a",
                    "number": "5.a",
                    "name": "Undertake reforms to give women equal rights to economic resources, as well as access to ownership and control over land and other forms of property, financial services, inheritance and natural resources, in accordance with national laws",
                    "indicators": [
                        {
                            "id": "5.a.1",
                            "number": "5.a.1",
                            "name": "Proportion of total agricultural population with ownership or secure rights over agricultural land, by sex; and by type of tenure"
                        }
                    ]
                },
                {
                    "id": "5.b",
                    "number": "5.b",
                    "name": "Enhance the use of enabling technology, in particular information and communications technology, to promote the empowerment of women",
                    "indicators": [
                        {
                            "id": "5.b.1",
                            "number": "5.b.1",
                            "name": "Proportion of individuals who own a mobile telephone, by sex"
                        }
                    ]
                },
                {
                    "id": "5.c",
                    "number": "5.c",
                    "name": "Adopt and strengthen sound policies and enforceable legislation for the promotion of gender equality and the empowerment of all women and girls at all levels",
                    "indicators": [
                        {
                            "id": "5.c.1",
                            "number": "5.c.1",
                            "name": "Proportion of countries with systems to track and make public allocations for gender equality and women's empowerment"
                        }
                    ]
                },
                    {
                        "id": "6.1",
                        "number": "6.1",
                        "name": "By 2030, achieve universal and equitable access to safe and affordable drinking water for all",
                        "indicators": [
                            {
                                "id": "6.1.1",
                                "number": "6.1.1",
                                "name": "Proportion of population using safely managed drinking water services"
                            },
                            {
                                "id": "6.1.2",
                                "number": "6.1.2",
                                "name": "Proportion of population with access to at least basic sanitation services, including a handwashing facility with soap and water"
                            }
                        ]
                    },
                    {
                        "id": "6.2",
                        "number": "6.2",
                        "name": "By 2030, achieve access to adequate and equitable sanitation and hygiene for all and end open defecation, paying special attention to the needs of women and girls and those in vulnerable situations",
                        "indicators": [
                            {
                                "id": "6.2.1",
                                "number": "6.2.1",
                                "name": "Proportion of population using safely managed sanitation services, including a handwashing facility with soap and water"
                            },
                            {
                                "id": "6.2.2",
                                "number": "6.2.2",
                                "name": "Proportion of schools with access to basic sanitation services, including single-sex facilities for girls and boys"
                            }
                        ]
                    },
                    {
                        "id": "6.3",
                        "number": "6.3",
                        "name": "By 2030, improve water quality by reducing pollution, eliminating dumping and minimizing release of hazardous chemicals and materials, halving the proportion of untreated wastewater and substantially increasing recycling and safe reuse globally",
                        "indicators": [
                            {
                                "id": "6.3.1",
                                "number": "6.3.1",
                                "name": "Proportion of wastewater safely treated"
                            },
                            {
                                "id": "6.3.2",
                                "number": "6.3.2",
                                "name": "Proportion of bodies of water with good ambient water quality"
                            }
                        ]
                    },
                    {
                        "id": "6.4",
                        "number": "6.4",
                        "name": "By 2030, substantially increase water-use efficiency across all sectors and ensure sustainable withdrawals and supply of freshwater to address water scarcity and substantially reduce the number of people suffering from water scarcity",
                        "indicators": [
                            {
                                "id": "6.4.1",
                                "number": "6.4.1",
                                "name": "Change in water-use efficiency over time"
                            },
                            {
                                "id": "6.4.2",
                                "number": "6.4.2",
                                "name": "Level of water stress: freshwater withdrawal as a proportion of available freshwater resources"
                            }
                        ]
                    },
                    {
                        "id": "6.5",
                        "number": "6.5",
                        "name": "By 2030, implement integrated water resources management at all levels, including through transboundary cooperation as appropriate",
                        "indicators": [
                            {
                                "id": "6.5.1",
                                "number": "6.5.1",
                                "name": "Degree of integrated water resources management implementation"
                            }
                        ]
                    },
                    {
                        "id": "6.6",
                        "number": "6.6",
                        "name": "By 2020, protect and restore water-related ecosystems, including mountains, forests, wetlands, rivers, aquifers, and lakes",
                        "indicators": [
                            {
                                "id": "6.6.1",
                                "number": "6.6.1",
                                "name": "Change in the extent of water-related ecosystems over time"
                            }
                        ]
                    },
                        {
                            "id": "7.1",
                            "number": "7.1",
                            "name": "By 2030, ensure universal access to affordable, reliable, and modern energy services",
                            "indicators": [
                                {
                                    "id": "7.1.1",
                                    "number": "7.1.1",
                                    "name": "Proportion of population with access to electricity"
                                },
                                {
                                    "id": "7.1.2",
                                    "number": "7.1.2",
                                    "name": "Proportion of population with primary reliance on clean fuels and technology"
                                }
                            ]
                        },
                        {
                            "id": "7.2",
                            "number": "7.2",
                            "name": "By 2030, increase substantially the share of renewable energy in the global energy mix",
                            "indicators": [
                                {
                                    "id": "7.2.1",
                                    "number": "7.2.1",
                                    "name": "Renewable energy share in the total final energy consumption"
                                }
                            ]
                        },
                        {
                            "id": "7.3",
                            "number": "7.3",
                            "name": "By 2030, double the global rate of improvement in energy efficiency",
                            "indicators": [
                                {
                                    "id": "7.3.1",
                                    "number": "7.3.1",
                                    "name": "Energy intensity measured in terms of primary energy and GDP"
                                }
                            ]
                        },
                        {
                            "id": "7.a",
                            "number": "7.a",
                            "name": "By 2030, enhance international cooperation to facilitate access to clean energy research and technology, including renewable energy, energy efficiency, and advanced and cleaner fossil-fuel technology, and promote investment in energy infrastructure and clean energy technology",
                            "indicators": [
                                {
                                    "id": "7.a.1",
                                    "number": "7.a.1",
                                    "name": "International financial flows to developing countries in support of clean energy research and development and renewable energy production, including in hybrid systems"
                                }
                            ]
                        },
                        {
                            "id": "7.b",
                            "number": "7.b",
                            "name": "By 2030, expand infrastructure and upgrade technology for supplying modern and sustainable energy services for all in developing countries, in particular least developed countries, small island developing States, and landlocked developing countries, in accordance with their respective programmes of support",
                            "indicators": [
                                {
                                    "id": "7.b.1",
                                    "number": "7.b.1",
                                    "name": "Investments in energy efficiency as a proportion of GDP and the amount of foreign direct investment in financial transfer for infrastructure and technology to sustainable development services"
                                }
                            ]
                        },
                {
                    "id": "8.1",
                    "number": "8.1",
                    "name": "Sustain per capita economic growth in accordance with national circumstances and, in particular, at least 7 percent gross domestic product growth per annum in the least developed countries"
                },
                {
                    "id": "8.2",
                    "number": "8.2",
                    "name": "Achieve higher levels of economic productivity through diversification, technological upgrading, and innovation, including through a focus on high-value added and labour-intensive sectors"
                },
                {
                    "id": "8.3",
                    "number": "8.3",
                    "name": "Promote development-oriented policies that support productive activities, decent job creation, entrepreneurship, creativity and innovation, and encourage the formalization and growth of micro-, small- and medium-sized enterprises, including through access to financial services"
                },
                {
                    "id": "8.4",
                    "number": "8.4",
                    "name": "Improve progressively, through 2030, global resource efficiency in consumption and production and endeavour to decouple economic growth from environmental degradation, in accordance with the 10-year framework of programmes on sustainable consumption and production, with developed countries taking the lead"
                },
                {
                    "id": "8.5",
                    "number": "8.5",
                    "name": "By 2030, achieve full and productive employment and decent work for all women and men, including for young people and persons with disabilities, and equal pay for work of equal value"
                },
                {
                    "id": "8.6",
                    "number": "8.6",
                    "name": "By 2020, substantially reduce the proportion of youth not in employment, education or training"
                },
                {
                    "id": "8.7",
                    "number": "8.7",
                    "name": "Take immediate and effective measures to eradicate forced labour, end modern slavery and human trafficking and secure the prohibition and elimination of the worst forms of child labour, including recruitment and use of child soldiers, and by 2025 end child labour in all its forms"
                },
                {
                    "id": "8.8",
                    "number": "8.8",
                    "name": "Protect labour rights and promote safe and secure working environments for all workers, including migrant workers, in particular women migrants, and those in precarious employment"
                },
                {
                    "id": "8.9",
                    "number": "8.9",
                    "name": "By 2030, devise and implement policies to promote sustainable tourism that creates jobs and promotes local culture and products"
                },
                {
                    "id": "8.10",
                    "number": "8.10",
                    "name": "Strengthen the capacity of domestic financial institutions to encourage and expand access to banking, insurance and financial services for all"
                },
                {
                    "id": "8.a",
                    "number": "8.a",
                    "name": "Increase Aid for Trade support for developing countries, in particular least developed countries, including through the Enhanced Integrated Framework for Trade-Related Technical Assistance to Least Developed Countries"
                },
                {
                    "id": "8.b",
                    "number": "8.b",
                    "name": "By 2020, develop and operationalize a global strategy for youth employment and implement the Global Jobs Pact of the International Labour Organization"
                },
                {
                    "id": "9.1",
                    "number": "9.1",
                    "name": "Develop quality, reliable, sustainable and resilient infrastructure, including regional and transborder infrastructure, to support economic development and human well-being, with a focus on affordable and equitable access for all",
                    "indicators": [
                        {
                            "id": "9.1.1",
                            "number": "9.1.1",
                            "name": "Proportion of the rural population who live within 2 km of an all-season road"
                        },
                        {
                            "id": "9.1.2",
                            "number": "9.1.2",
                            "name": "Passenger and freight volumes, by mode of transport"
                        },
                        {
                            "id": "9.1.3",
                            "number": "9.1.3",
                            "name": "Proportion of population covered by mobile networks, by technology"
                        }
                    ]
                },
                {
                    "id": "9.2",
                    "number": "9.2",
                    "name": "Promote inclusive and sustainable industrialization and, by 2030, significantly raise industry’s share of employment and gross domestic product, in line with national circumstances, and double its share in least developed countries",
                    "indicators": [
                        {
                            "id": "9.2.1",
                            "number": "9.2.1",
                            "name": "Manufacturing value added as a proportion of GDP and per capita"
                        },
                        {
                            "id": "9.2.2",
                            "number": "9.2.2",
                            "name": "Manufacturing employment as a proportion of total employment"
                        },
                        {
                            "id": "9.2.3",
                            "number": "9.2.3",
                            "name": "Proportion of small-scale industries in total industry value added"
                        }
                    ]
                },
                {
                    "id": "9.3",
                    "number": "9.3",
                    "name": "Increase the access of small-scale industrial and other enterprises, in particular in developing countries, to financial services, including affordable credit, and their integration into value chains and markets",
                    "indicators": [
                        {
                            "id": "9.3.1",
                            "number": "9.3.1",
                            "name": "Proportion of small-scale industries in total industry value added"
                        },
                        {
                            "id": "9.3.2",
                            "number": "9.3.2",
                            "name": "Proportion of small-scale industries with a loan or line of credit"
                        }
                    ]
                },
                {
                    "id": "9.4",
                    "number": "9.4",
                    "name": "By 2030, upgrade infrastructure and retrofit industries to make them sustainable, with increased resource-use efficiency and greater adoption of clean and environmentally sound technologies and industrial processes, with all countries taking action in accordance with their respective capabilities",
                    "indicators": [
                        {
                            "id": "9.4.1",
                            "number": "9.4.1",
                            "name": "CO2 emission per unit of value added"
                        },
                        {
                            "id": "9.4.2",
                            "number": "9.4.2",
                            "name": "Material footprint per capita"
                        },
                        {
                            "id": "9.4.3",
                            "number": "9.4.3",
                            "name": "Number of research and development workers per million people"
                        }
                    ]
                },
                {
                    "id": "9.5",
                    "number": "9.5",
                    "name": "Enhance scientific research, upgrade the technological capabilities of industrial sectors in all countries, in particular developing countries, including, by 2030, encouraging innovation and substantially increasing the number of research and development workers per 1 million people and public and private research and development spending",
                    "indicators": [
                        {
                            "id": "9.5.1",
                            "number": "9.5.1",
                            "name": "Research and development expenditure as a proportion of GDP"
                        },
                        {
                            "id": "9.5.2",
                            "number": "9.5.2",
                            "name": "Researchers (in full-time equivalent) per million inhabitants"
                        },
                        {
                            "id": "9.5.3",
                            "number": "9.5.3",
                            "name": "Proportion of medium and high-tech industry value added in total value added"
                        }
                    ]
                },
                {
                    "id": "9.a",
                    "number": "9.a",
                    "name": "Facilitate sustainable and resilient infrastructure development in developing countries through enhanced financial, technological and technical support to African countries, least developed countries, landlocked developing countries and small island developing States",
                    "indicators": [
                        {
                            "id": "9.a.1",
                            "number": "9.a.1",
                            "name": "Total official international support (official development assistance plus other official flows) to infrastructure"
                        },
                        {
                            "id": "9.a.2",
                            "number": "9.a.2",
                            "name": "Proportion of total bilateral, sector-allocable official development assistance for promoting the development, transfer, dissemination and diffusion of environmentally sound technologies"
                        },
                        {
                            "id": "9.a.3",
                            "number": "9.a.3",
                            "name": "Proportion of domestic budget funded by domestic taxes"
                        }
                    ]
                },
                {
                    "id": "9.b",
                    "number": "9.b",
                    "name": "Support domestic technology development, research and innovation in developing countries, including by ensuring a conducive policy environment for, inter alia, industrial diversification and value addition to commodities",
                    "indicators": [
                        {
                            "id": "9.b.1",
                            "number": "9.b.1",
                            "name": "Total research and development expenditure in public and private sectors as a proportion of GDP"
                        },
                        {
                            "id": "9.b.2",
                            "number": "9.b.2",
                            "name": "Medium and high-tech industry value added as a proportion of total value added"
                        }
                    ]
                },
                {
                    "id": "9.c",
                    "number": "9.c",
                    "name": "Significantly increase access to information and communications technology and strive to provide universal and affordable access to the Internet in least developed countries by 2020",
                    "indicators": [
                        {
                            "id": "9.c.1",
                            "number": "9.c.1",
                            "name": "Proportion of population covered by a mobile network, by technology"
                        },
                        {
                            "id": "9.c.2",
                            "number": "9.c.2",
                            "name": "Fixed Internet broadband subscriptions per 100 inhabitants, by speed"
                        },
                        {
                            "id": "9.c.3",
                            "number": "9.c.3",
                            "name": "Proportion of individuals using the Internet"
                        }
                    ]
                },
                {
                    "id": "9.d",
                    "number": "9.d",
                    "name": "Enhance international support for implementing effective and targeted capacity-building in developing countries to support national plans to implement all the sustainable development goals, including through North-South, South-South and triangular cooperation",
                    "indicators": [
                        {
                            "id": "9.d.1",
                            "number": "9.d.1",
                            "name": "Proportion of population with access to electricity"
                        },
                        {
                            "id": "9.d.2",
                            "number": "9.d.2",
                            "name": "Proportion of population with primary reliance on clean fuels and technology"
                        }
                    ]
                },
                {
                    "id": "10.1",
                    "number": "10.1",
                    "name": "By 2030, progressively achieve and sustain income growth of the bottom 40 per cent of the population at a rate higher than the national average",
                    "indicators": [
                        {
                            "id": "10.1.1",
                            "number": "10.1.1",
                            "name": "Growth rates of household expenditure or income per capita among the bottom 40 per cent of the population and the total population"
                        },
                        {
                            "id": "10.1.2",
                            "number": "10.1.2",
                            "name": "Proportion of population living below the national poverty line, by sex and age"
                        },
                        {
                            "id": "10.1.3",
                            "number": "10.1.3",
                            "name": "Shared prosperity: the bottom 40 per cent's share of income or consumption"
                        }
                    ]
                },
                {
                    "id": "10.2",
                    "number": "10.2",
                    "name": "By 2030, empower and promote the social, economic and political inclusion of all, irrespective of age, sex, disability, race, ethnicity, origin, religion or economic or other status",
                    "indicators": [
                        {
                            "id": "10.2.1",
                            "number": "10.2.1",
                            "name": "Proportion of people living below 50 per cent of median income, by age, sex and persons with disabilities"
                        },
                        {
                            "id": "10.2.2",
                            "number": "10.2.2",
                            "name": "Proportion of countries that have achieved significant progress in the implementation of programmes to reduce inequalities"
                        },
                        {
                            "id": "10.2.3",
                            "number": "10.2.3",
                            "name": "Total asset index"
                        }
                    ]
                },
                {
                    "id": "10.3",
                    "number": "10.3",
                    "name": "Ensure equal opportunity and reduce inequalities of outcome, including by eliminating discriminatory laws, policies and practices and promoting appropriate legislation, policies and action in this regard",
                    "indicators": [
                        {
                            "id": "10.3.1",
                            "number": "10.3.1",
                            "name": "Proportion of population reporting having personally felt discriminated against or harassed in the previous 12 months on the basis of a ground of discrimination prohibited under international human rights law"
                        },
                        {
                            "id": "10.3.2",
                            "number": "10.3.2",
                            "name": "Proportion of countries with systems to track and make public allocations for gender equality and women's empowerment"
                        }
                    ]
                },
                {
                    "id": "10.4",
                    "number": "10.4",
                    "name": "Adopt policies, especially fiscal, wage and social protection policies, and progressively achieve greater equality",
                    "indicators": [
                        {
                            "id": "10.4.1",
                            "number": "10.4.1",
                            "name": "Labour share of GDP, comprising wages and social protection transfers"
                        },
                        {
                            "id": "10.4.2",
                            "number": "10.4.2",
                            "name": "Proportion of informal employment in non-agriculture employment, by sex"
                        },
                        {
                            "id": "10.4.3",
                            "number": "10.4.3",
                            "name": "Income growth rate of the bottom 40 per cent of the population"
                        }
                    ]
                },
                {
                    "id": "10.5",
                    "number": "10.5",
                    "name": "Improve the regulation and monitoring of global financial markets and institutions and strengthen the implementation of such regulations",
                    "indicators": [
                        {
                            "id": "10.5.1",
                            "number": "10.5.1",
                            "name": "Financial Soundness Indicators"
                        },
                        {
                            "id": "10.5.2",
                            "number": "10.5.2",
                            "name": "Proportion of financial and non-financial corporations that operate in accordance with recognized sustainability standards"
                        },
                        {
                            "id": "10.5.3",
                            "number": "10.5.3",
                            "name": "Number of countries that adopt and implement investment promotion regimes for least developed countries"
                        }
                    ]
                },
                {
                    "id": "10.6",
                    "number": "10.6",
                    "name": "Ensure enhanced representation and voice for developing countries in decision-making in global international economic and financial institutions in order to deliver more effective, credible, accountable and legitimate institutions",
                    "indicators": [
                        {
                            "id": "10.6.1",
                            "number": "10.6.1",
                            "name": "Proportion of members and voting rights of developing countries in international organizations"
                        }
                    ]
                },
                {
                    "id": "10.7",
                    "number": "10.7",
                    "name": "Facilitate orderly, safe, regular and responsible migration and mobility of people, including through the implementation of planned and well-managed migration policies",
                    "indicators": [
                        {
                            "id": "10.7.1",
                            "number": "10.7.1",
                            "name": "Recruitment cost borne by employee as a proportion of yearly income earned in country of destination"
                        },
                        {
                            "id": "10.7.2",
                            "number": "10.7.2",
                            "name": "Number of countries that have implemented well-managed migration policies"
                        },
                        {
                            "id": "10.7.3",
                            "number": "10.7.3",
                            "name": "Remittance costs as a proportion of the amount remitted"
                        }
                    ]
                },
                {
                    "id": "10.a",
                    "number": "10.a",
                    "name": "Implement the principle of special and differential treatment for developing countries, in particular least developed countries, in accordance with World Trade Organization agreements",
                    "indicators": [
                        {
                            "id": "10.a.1",
                            "number": "10.a.1",
                            "name": "Proportion of tariff lines applied to imports from least developed countries and developing countries with zero-tariff"
                        },
                        {
                            "id": "10.a.2",
                            "number": "10.a.2",
                            "name": "Net official development assistance, total and to least developed countries, as a proportion of the Organization for Economic Cooperation and Development (OECD) Development Assistance Committee donors' gross national income (GNI)"
                        }
                    ]
                },
                {
                    "id": "10.b",
                    "number": "10.b",
                    "name": "Encourage official development assistance and financial flows, including foreign direct investment, to States where the need is greatest, in particular least developed countries, African countries, small island developing States and landlocked developing countries, in accordance with their national plans and programmes",
                    "indicators": [
                        {
                            "id": "10.b.1",
                            "number": "10.b.1",
                            "name": "Total resource flows for development, by recipient and donor countries and type of flow"
                        },
                        {
                            "id": "10.b.2",
                            "number": "10.b.2",
                            "name": "Proportion of bilateral official development assistance of OECD-DAC donors that is untied"
                        },
                        {
                            "id": "10.b.3",
                            "number": "10.b.3",
                            "name": "Degree of comprehensive policy coverage by the debt service of their exports of least developed countries and small island developing States, including through the generalized system of preferences and duty-free treatment"
                        }
                    ]
                },
                {
                    "id": "10.c",
                    "number": "10.c",
                    "name": "By 2030, reduce to less than 3 per cent the transaction costs of migrant remittances and eliminate remittance corridors with costs higher than 5 per cent",
                    "indicators": [
                        {
                            "id": "10.c.1",
                            "number": "10.c.1",
                            "name": "Remittance costs as a proportion of the amount remitted"
                        }
                    ]
                },
                {
                    "id": "11.1",
                    "number": "11.1",
                    "name": "By 2030, ensure access for all to adequate, safe and affordable housing and basic services and upgrade slums",
                    "indicators": [
                        {
                            "id": "11.1.1",
                            "number": "11.1.1",
                            "name": "Proportion of urban population living in slums, informal settlements or inadequate housing"
                        },
                        {
                            "id": "11.1.2",
                            "number": "11.1.2",
                            "name": "Proportion of population that has convenient access to public transport, by sex, age and persons with disabilities"
                        },
                        {
                            "id": "11.1.3",
                            "number": "11.1.3",
                            "name": "Proportion of population with access to safe, affordable and sustainable transport systems, by sex, age and persons with disabilities"
                        },
                        {
                            "id": "11.1.4",
                            "number": "11.1.4",
                            "name": "Annual mean levels of fine particulate matter (e.g. PM2.5 and PM10) in cities (population weighted)"
                        }
                    ]
                },
                {
                    "id": "11.2",
                    "number": "11.2",
                    "name": "By 2030, provide access to safe, affordable, accessible and sustainable transport systems for all, improving road safety, notably by expanding public transport, with special attention to the needs of those in vulnerable situations, women, children, persons with disabilities and older persons",
                    "indicators": [
                        {
                            "id": "11.2.1",
                            "number": "11.2.1",
                            "name": "Proportion of population that has convenient access to public transport, by sex, age and persons with disabilities"
                        },
                        {
                            "id": "11.2.2",
                            "number": "11.2.2",
                            "name": "Total annual road traffic deaths per 100,000 population"
                        },
                        {
                            "id": "11.2.3",
                            "number": "11.2.3",
                            "name": "Proportion of population with access to safe, affordable and sustainable transport systems, by sex, age and persons with disabilities"
                        },
                        {
                            "id": "11.2.4",
                            "number": "11.2.4",
                            "name": "Number of sustainable cities and human settlements, by indicator"
                        }
                    ]
                },
                {
                    "id": "11.3",
                    "number": "11.3",
                    "name": "By 2030, enhance inclusive and sustainable urbanization and capacity for participatory, integrated and sustainable human settlement planning and management in all countries",
                    "indicators": [
                        {
                            "id": "11.3.1",
                            "number": "11.3.1",
                            "name": "Ratio of land consumption rate to population growth rate"
                        },
                        {
                            "id": "11.3.2",
                            "number": "11.3.2",
                            "name": "Proportion of cities with a direct participation structure of civil society in urban planning and management that operate regularly and democratically"
                        },
                        {
                            "id": "11.3.3",
                            "number": "11.3.3",
                            "name": "Proportion of countries adopting and implementing national disaster risk reduction strategies in line with the Sendai Framework for Disaster Risk Reduction 2015-2030"
                        }
                    ]
                },
                {
                    "id": "11.4",
                    "number": "11.4",
                    "name": "Strengthen efforts to protect and safeguard the world’s cultural and natural heritage",
                    "indicators": [
                        {
                            "id": "11.4.1",
                            "number": "11.4.1",
                            "name": "Total expenditure (public and private) per capita spent on the preservation, protection and conservation of all cultural and natural heritage, by type of heritage (cultural, natural, mixed and World Heritage Centre designation), level of government (national, regional and local/municipal), type of expenditure (operating expenditure/investment) and type of private funding (donations in kind, private non-profit sector and sponsorship)"
                        },
                        {
                            "id": "11.4.2",
                            "number": "11.4.2",
                            "name": "Number of deaths, missing persons and directly affected persons attributed to disasters per 100,000 population"
                        },
                        {
                            "id": "11.4.3",
                            "number": "11.4.3",
                            "name": "Direct economic loss attributed to disasters in relation to global gross domestic product (GDP)"
                        }
                    ]
                },
                {
                    "id": "11.5",
                    "number": "11.5",
                    "name": "By 2030, significantly reduce the number of deaths and the number of people affected and substantially decrease the direct economic losses relative to global gross domestic product caused by disasters, including water-related disasters, with a focus on protecting the poor and people in vulnerable situations",
                    "indicators": [
                        {
                            "id": "11.5.1",
                            "number": "11.5.1",
                            "name": "Number of deaths, missing persons and directly affected persons attributed to disasters per 100,000 population"
                        },
                        {
                            "id": "11.5.2",
                            "number": "11.5.2",
                            "name": "Direct economic loss in relation to global gross domestic product (GDP), damage to critical infrastructure and number of disruptions to basic services, attributed to disasters"
                        },
                        {
                            "id": "11.5.3",
                            "number": "11.5.3",
                            "name": "Number of countries that adopt and implement national disaster risk reduction strategies in line with the Sendai Framework for Disaster Risk Reduction 2015-2030"
                        }
                    ]
                },
                {
                    "id": "11.6",
                    "number": "11.6",
                    "name": "By 2030, reduce the adverse per capita environmental impact of cities, including by paying special attention to air quality and municipal and other waste management",
                    "indicators": [
                        {
                            "id": "11.6.1",
                            "number": "11.6.1",
                            "name": "Proportion of urban solid waste regularly collected and with adequate final discharge out of total urban solid waste generated, by cities"
                        },
                        {
                            "id": "11.6.2",
                            "number": "11.6.2",
                            "name": "Annual mean levels of fine particulate matter (e.g. PM2.5 and PM10) in cities (population weighted)"
                        },
                        {
                            "id": "11.6.3",
                            "number": "11.6.3",
                            "name": "Percentage of urban solid waste regularly collected and with adequate final discharge out of total urban solid waste generated, by cities"
                        }
                    ]
                },
                {
                    "id": "11.7",
                    "number": "11.7",
                    "name": "By 2030, provide universal access to safe, inclusive and accessible, green and public spaces, in particular for women and children, older persons and persons with disabilities",
                    "indicators": [
                        {
                            "id": "11.7.1",
                            "number": "11.7.1",
                            "name": "Average share of the built-up area of cities that is open space for public use for all, by sex, age and persons with disabilities"
                        },
                        {
                            "id": "11.7.2",
                            "number": "11.7.2",
                            "name": "Proportion of persons victim of physical or sexual harassment, by sex, age, disability status and place of occurrence, in the previous 12 months"
                        },
                        {
                            "id": "11.7.3",
                            "number": "11.7.3",
                            "name": "Average share of the built-up area of cities that is open space for public use for all, by sex, age and persons with disabilities"
                        }
                    ]
                },
                {
                    "id": "11.a",
                    "number": "11.a",
                    "name": "Support positive economic, social and environmental links between urban, peri-urban and rural areas by strengthening national and regional development planning",
                    "indicators": [
                        {
                            "id": "11.a.1",
                            "number": "11.a.1",
                            "name": "Proportion of population living in cities that implement urban and regional development plans integrating population projections and resource needs, by size of city"
                        },
                        {
                            "id": "11.a.2",
                            "number": "11.a.2",
                            "name": "Proportion of cities with a direct participation structure of civil society and/or local governments in urban planning and management that operate regularly and democratically"
                        },
                        {
                            "id": "11.a.3",
                            "number": "11.a.3",
                            "name": "Proportion of countries that have national urban policies or regional development plans that: (a) respond to population dynamics; (b) ensure balanced territorial development; and (c) increase local fiscal space"
                        }
                    ]
                },
                {
                    "id": "11.b",
                    "number": "11.b",
                    "name": "By 2020, substantially increase the number of cities and human settlements adopting and implementing integrated policies and plans towards inclusion, resource efficiency, mitigation and adaptation to climate change, resilience to disasters, and develop and implement, in line with the Sendai Framework for Disaster Risk Reduction 2015-2030, holistic disaster risk management at all levels",
                    "indicators": [
                        {
                            "id": "11.b.1",
                            "number": "11.b.1",
                            "name": "Proportion of local governments that adopt and implement local disaster risk reduction strategies in line with national disaster risk reduction strategies"
                        },
                        {
                            "id": "11.b.2",
                            "number": "11.b.2",
                            "name": "Number of countries that adopt and implement national disaster risk reduction strategies in line with the Sendai Framework for Disaster Risk Reduction 2015-2030"
                        }
                    ]
                },
                {
                    "id": "11.c",
                    "number": "11.c",
                    "name": "Support least developed countries, including through financial and technical assistance, in building sustainable and resilient buildings utilizing local materials",
                    "indicators": [
                        {
                            "id": "11.c.1",
                            "number": "11.c.1",
                            "name": "Proportion of financial support to the least developed countries that is allocated to the construction and retrofitting of sustainable, resilient and resource-efficient buildings utilizing local materials"
                        },
                        {
                            "id": "11.c.2",
                            "number": "11.c.2",
                            "name": "Proportion of cities with a direct participation structure of civil society and/or local governments in urban planning and management that operate regularly and democratically"
                        },
                        {
                            "id": "11.c.3",
                            "number": "11.c.3",
                            "name": "Proportion of countries that have national urban policies or regional development plans that: (a) respond to population dynamics; (b) ensure balanced territorial development; and (c) increase local fiscal space"
                        }
                    ]
                },
                {
                    "id": "12.1",
                    "number": "12.1",
                    "name": "Implement the 10-year framework of programmes on sustainable consumption and production, all countries taking action, with developed countries taking the lead, taking into account the development and capabilities of developing countries",
                    "indicators": [
                        {
                            "id": "12.1.1",
                            "number": "12.1.1",
                            "name": "Number of countries with sustainable consumption and production (SCP) national action plans or SCP mainstreamed as a priority or a target into national policies"
                        },
                        {
                            "id": "12.1.2",
                            "number": "12.1.2",
                            "name": "Material footprint, material footprint per capita, and material footprint per GDP"
                        }
                    ]
                },
                {
                    "id": "12.2",
                    "number": "12.2",
                    "name": "By 2030, achieve the sustainable management and efficient use of natural resources",
                    "indicators": [
                        {
                            "id": "12.2.1",
                            "number": "12.2.1",
                            "name": "Material footprint, material footprint per capita, and material footprint per GDP"
                        },
                        {
                            "id": "12.2.2",
                            "number": "12.2.2",
                            "name": "Domestic material consumption, domestic material consumption per capita, and domestic material consumption per GDP"
                        }
                    ]
                },
                {
                    "id": "12.3",
                    "number": "12.3",
                    "name": "By 2030, halve per capita global food waste at the retail and consumer levels and reduce food losses along production and supply chains, including post-harvest losses",
                    "indicators": [
                        {
                            "id": "12.3.1",
                            "number": "12.3.1",
                            "name": "Extent of food loss (percentage) in post-harvest, processing, and distribution sectors"
                        },
                        {
                            "id": "12.3.2",
                            "number": "12.3.2",
                            "name": "Extent of waste generated in production and supply chains"
                        },
                        {
                            "id": "12.3.3",
                            "number": "12.3.3",
                            "name": "Global food loss index"
                        }
                    ]
                },
                {
                    "id": "12.4",
                    "number": "12.4",
                    "name": "By 2020, achieve the environmentally sound management of chemicals and all wastes throughout their life cycle, in accordance with agreed international frameworks, and significantly reduce their release to air, water and soil in order to minimize their adverse impacts on human health and the environment",
                    "indicators": [
                        {
                            "id": "12.4.1",
                            "number": "12.4.1",
                            "name": "Number of parties to international multilateral environmental agreements on hazardous waste, and other chemicals that meet their commitments and obligations in transmitting information as required by each relevant agreement"
                        },
                        {
                            "id": "12.4.2",
                            "number": "12.4.2",
                            "name": "Hazardous waste generated per capita and proportion of hazardous waste treated, by type of treatment"
                        }
                    ]
                },
                {
                    "id": "12.5",
                    "number": "12.5",
                    "name": "By 2030, substantially reduce waste generation through prevention, reduction, recycling, and reuse",
                    "indicators": [
                        {
                            "id": "12.5.1",
                            "number": "12.5.1",
                            "name": "National recycling rate, tons of material recycled"
                        },
                        {
                            "id": "12.5.2",
                            "number": "12.5.2",
                            "name": "Amount of waste generated per capita, per household and per GDP"
                        }
                    ]
                },
                {
                    "id": "12.6",
                    "number": "12.6",
                    "name": "Encourage companies, especially large and transnational companies, to adopt sustainable practices and to integrate sustainability information into their reporting cycle",
                    "indicators": [
                        {
                            "id": "12.6.1",
                            "number": "12.6.1",
                            "name": "Number of companies publishing sustainability reports"
                        },
                        {
                            "id": "12.6.2",
                            "number": "12.6.2",
                            "name": "Number of companies that adopt sustainable practices and integrate sustainability information into their reporting cycle"
                        }
                    ]
                },
                {
                    "id": "12.7",
                    "number": "12.7",
                    "name": "Promote public procurement practices that are sustainable, in accordance with national policies and priorities",
                    "indicators": [
                        {
                            "id": "12.7.1",
                            "number": "12.7.1",
                            "name": "Number of countries implementing sustainable public procurement policies and action plans"
                        },
                        {
                            "id": "12.7.2",
                            "number": "12.7.2",
                            "name": "Percentage of procured products and services that are sustainably sourced"
                        }
                    ]
                },
                {
                    "id": "12.8",
                    "number": "12.8",
                    "name": "By 2030, ensure that people everywhere have the relevant information and awareness for sustainable development and lifestyles in harmony with nature",
                    "indicators": [
                        {
                            "id": "12.8.1",
                            "number": "12.8.1",
                            "name": "Extent to which (i) global citizenship education and (ii) education for sustainable development (including climate change education) are mainstreamed in (a) national education policies; (b) curricula; (c) teacher education; and (d) student assessment"
                        }
                    ]
                },
                {
                    "id": "12.a",
                    "number": "12.a",
                    "name": "Support developing countries to strengthen their scientific and technological capacity to move towards more sustainable patterns of consumption and production",
                    "indicators": [
                        {
                            "id": "12.a.1",
                            "number": "12.a.1",
                            "name": "Amount of support to developing countries on research and development for sustainable consumption and production, including environmentally sound technologies"
                        }
                    ]
                },
                {
                    "id": "12.b",
                    "number": "12.b",
                    "name": "Develop and implement tools to monitor sustainable development impacts for sustainable tourism that creates jobs and promotes local culture and products",
                    "indicators": [
                        {
                            "id": "12.b.1",
                            "number": "12.b.1",
                            "name": "Number of sustainable tourism strategies or policies and implemented action plans with agreed monitoring and evaluation tools"
                        },
                        {
                            "id": "12.b.2",
                            "number": "12.b.2",
                            "name": "Total net official development assistance to the tourism sector"
                        }
                    ]
                },
                {
                    "id": "12.c",
                    "number": "12.c",
                    "name": "Rationalize inefficient fossil-fuel subsidies that encourage wasteful consumption by removing market distortions, in accordance with national circumstances, including by restructuring taxation and phasing out those harmful subsidies, where they exist, to reflect their environmental impacts, taking fully into account the specific needs and conditions of developing countries and minimizing the possible adverse impacts on their development in a manner that protects the poor and the affected communities",
                    "indicators": [
                        {
                            "id": "12.c.1",
                            "number": "12.c.1",
                            "name": "Amount of fossil-fuel subsidies per unit of GDP (production and consumption) and as a proportion of total national expenditure on fossil fuels"
                        }
                    ]
                },
                {
                    "id": "13.1",
                    "number": "13.1",
                    "name": "Strengthen resilience and adaptive capacity to climate-related hazards and natural disasters in all countries",
                    "indicators": [
                        {
                            "id": "13.1.1",
                            "number": "13.1.1",
                            "name": "Number of deaths, missing persons and directly affected persons attributed to disasters per 100,000 population"
                        }
                    ]
                },
                {
                    "id": "13.2",
                    "number": "13.2",
                    "name": "Integrate climate change measures into national policies, strategies and planning",
                    "indicators": [
                        {
                            "id": "13.2.1",
                            "number": "13.2.1",
                            "name": "Number of countries that have communicated the strengthening of institutional, systemic and individual capacity-building to implement adaptation, mitigation and technology transfer, and development actions"
                        }
                    ]
                },
                {
                    "id": "13.3",
                    "number": "13.3",
                    "name": "Improve education, awareness-raising and human and institutional capacity on climate change mitigation, adaptation, impact reduction and early warning",
                    "indicators": [
                        {
                            "id": "13.3.1",
                            "number": "13.3.1",
                            "name": "Number of countries that have integrated mitigation, adaptation, impact reduction and early warning into primary, secondary and tertiary curricula"
                        }
                    ]
                },
                {
                    "id": "13.a",
                    "number": "13.a",
                    "name": "Mobilize resources for mitigation of climate change in lower income countries",
                    "indicators": [
                        {
                            "id": "13.a.1",
                            "number": "13.a.1",
                            "name": "Mobilized amount of United States dollars per year starting in 2020 accountable towards the $100 billion commitment"
                        }
                    ]
                },
                {
                    "id": "13.b",
                    "number": "13.b",
                    "name": "Promote mechanisms for raising capacity for effective climate change-related planning and management in least developed countries and small island developing states, including focusing on women, youth and local and marginalized communities",
                    "indicators": [
                        {
                            "id": "13.b.1",
                            "number": "13.b.1",
                            "name": "Number of least developed countries and small island developing states that are receiving specialized support, and amount of support, including finance, technology and capacity-building, for mechanisms for raising capacities for effective climate change-related planning and management"
                        }
                    ]
                },


                {
                    "id": "14.1",
                    "number": "14.1",
                    "name": "By 2025, prevent and significantly reduce marine pollution of all kinds, in particular from land-based activities, including marine debris and nutrient pollution",
                    "indicators": [
                        {
                            "id": "14.1.1",
                            "number": "14.1.1",
                            "name": "Index of coastal eutrophication and floating plastic debris density"
                        }
                    ]
                },
                {
                    "id": "14.2",
                    "number": "14.2",
                    "name": "By 2020, sustainably manage and protect marine and coastal ecosystems to avoid significant adverse impacts, including by strengthening their resilience, and take action for their restoration in order to achieve healthy and productive oceans",
                    "indicators": [
                        {
                            "id": "14.2.1",
                            "number": "14.2.1",
                            "name": "Proportion of national exclusive economic zones managed using ecosystem-based approaches"
                        }
                    ]
                },
                {
                    "id": "14.3",
                    "number": "14.3",
                    "name": "Minimize and address the impacts of ocean acidification, including through enhanced scientific cooperation at all levels",
                    "indicators": [
                        {
                            "id": "14.3.1",
                            "number": "14.3.1",
                            "name": "Average marine acidity (pH) measured at agreed suite of representative sampling stations"
                        }
                    ]
                },
                {
                    "id": "14.4",
                    "number": "14.4",
                    "name": "By 2020, effectively regulate harvesting and end overfishing, illegal, unreported and unregulated fishing and destructive fishing practices and implement science-based management plans, in order to restore fish stocks in the shortest time feasible, at least to levels that can produce maximum sustainable yield as determined by their biological characteristics",
                    "indicators": [
                        {
                            "id": "14.4.1",
                            "number": "14.4.1",
                            "name": "Proportion of fish stocks within biologically sustainable levels"
                        }
                    ]
                },
                {
                    "id": "14.5",
                    "number": "14.5",
                    "name": "By 2020, conserve at least 10 percent of coastal and marine areas, consistent with national and international law and based on the best available scientific information",
                    "indicators": [
                        {
                            "id": "14.5.1",
                            "number": "14.5.1",
                            "name": "Coverage of protected areas in relation to marine areas"
                        }
                    ]
                },
                {
                    "id": "14.6",
                    "number": "14.6",
                    "name": "By 2020, prohibit certain forms of fisheries subsidies which contribute to overcapacity and overfishing, eliminate subsidies that contribute to illegal, unreported and unregulated fishing and refrain from introducing new such subsidies, recognizing that appropriate and effective special and differential treatment for developing and least developed countries should be an integral part of the World Trade Organization fisheries subsidies negotiation",
                    "indicators": [
                        {
                            "id": "14.6.1",
                            "number": "14.6.1",
                            "name": "Progress by countries in the degree of implementation of international instruments aiming to combat illegal, unreported and unregulated fishing"
                        }
                    ]
                },
                {
                    "id": "14.7",
                    "number": "14.7",
                    "name": "By 2030, increase the economic benefits to small island developing states and least developed countries from the sustainable use of marine resources, including through sustainable management of fisheries, aquaculture and tourism",
                    "indicators": [
                        {
                            "id": "14.7.1",
                            "number": "14.7.1",
                            "name": "Sustainable fisheries as a percentage of GDP in small island developing states, least developed countries, and all countries"
                        }
                    ]
                },
                {
                    "id": "14.a",
                    "number": "14.a",
                    "name": "Increase scientific knowledge, develop research capacities and transfer marine technology, taking into account the Intergovernmental Oceanographic Commission Criteria and Guidelines on the Transfer of Marine Technology, in order to improve ocean health and to enhance the contribution of marine biodiversity to the development of developing countries, in particular small island developing states and least developed countries",
                    "indicators": [
                        {
                            "id": "14.a.1",
                            "number": "14.a.1",
                            "name": "Proportion of total research budget allocated to research in the field of marine technology"
                        }
                    ]
                },
                {
                    "id": "14.b",
                    "number": "14.b",
                    "name": "Provide access for small-scale artisanal fishers to marine resources and markets",
                    "indicators": [
                        {
                            "id": "14.b.1",
                            "number": "14.b.1",
                            "name": "Degree of application of a legal/regulatory/policy/institutional framework that recognizes and protects access rights for small-scale fisheries"
                        }
                    ]
                },
                {
                    "id": "14.c",
                    "number": "14.c",
                    "name": "Enhance the conservation and sustainable use of oceans and their resources by implementing international law as reflected in UNCLOS, which provides the legal framework for the conservation and sustainable use of oceans and their resources, as recalled in paragraph 158 of The Future We Want",
                    "indicators": [
                        {
                            "id": "14.c.1",
                            "number": "14.c.1",
                            "name": "Number of countries making progress in ratifying, accepting and implementing through legal, policy and institutional frameworks, ocean-related instruments that implement international law, as reflected in the United Nations Convention on the Law of the Sea, for the conservation and sustainable use of the oceans and their resources"
                        }
                    ]
                },
                {
                    "id": "15.1",
                    "number": "15.1",
                    "name": "By 2020, ensure the conservation, restoration and sustainable use of terrestrial and inland freshwater ecosystems and their services, in particular forests, wetlands, mountains and drylands, in line with obligations under international agreements",
                    "indicators": [
                        {
                            "id": "15.1.1",
                            "number": "15.1.1",
                            "name": "Forest area as a proportion of total land area"
                        },
                        {
                            "id": "15.1.2",
                            "number": "15.1.2",
                            "name": "Proportion of important sites for terrestrial and freshwater biodiversity that are covered by protected areas, by ecosystem type"
                        },
                        {
                            "id": "15.1.3",
                            "number": "15.1.3",
                            "name": "Proportion of mountain ecosystems, including their biodiversity, that are protected"
                        },
                        {
                            "id": "15.1.4",
                            "number": "15.1.4",
                            "name": "Coverage by protected areas of important sites for mountain biodiversity"
                        }
                    ]
                },
                {
                    "id": "15.2",
                    "number": "15.2",
                    "name": "By 2020, promote the implementation of sustainable management of all types of forests, halt deforestation, restore degraded forests and substantially increase afforestation and reforestation globally",
                    "indicators": [
                        {
                            "id": "15.2.1",
                            "number": "15.2.1",
                            "name": "Progress towards sustainable forest management"
                        },
                        {
                            "id": "15.2.2",
                            "number": "15.2.2",
                            "name": "Proportion of forest area within legally established protected areas"
                        },
                        {
                            "id": "15.2.3",
                            "number": "15.2.3",
                            "name": "Proportion of forest area covered by a forest management plan"
                        },
                        {
                            "id": "15.2.4",
                            "number": "15.2.4",
                            "name": "Area of forest certified under an independently verified certification scheme"
                        },
                        {
                            "id": "15.2.5",
                            "number": "15.2.5",
                            "name": "Number of countries that have integrated forest-related issues into national sustainable development strategies and plans"
                        }
                    ]
                },
                {
                    "id": "15.3",
                    "number": "15.3",
                    "name": "By 2030, combat desertification, restore degraded land and soil, including land affected by desertification, drought and floods, and strive to achieve a land degradation-neutral world",
                    "indicators": [
                        {
                            "id": "15.3.1",
                            "number": "15.3.1",
                            "name": "Proportion of land that is degraded over total land area"
                        },
                        {
                            "id": "15.3.2",
                            "number": "15.3.2",
                            "name": "Proportion of important sites for terrestrial and freshwater biodiversity that are covered by protected areas, by ecosystem type"
                        },
                        {
                            "id": "15.3.3",
                            "number": "15.3.3",
                            "name": "Proportion of land that is degraded over total land area"
                        },
                        {
                            "id": "15.3.4",
                            "number": "15.3.4",
                            "name": "Proportion of land areas that are covered by a spatial plan and are managed in accordance with the plan"
                        }
                    ]
                },
                {
                    "id": "15.4",
                    "number": "15.4",
                    "name": "By 2030, ensure the conservation of mountain ecosystems, including their biodiversity, to enhance their capacity to provide benefits that are essential for sustainable development",
                    "indicators": [
                        {
                            "id": "15.4.1",
                            "number": "15.4.1",
                            "name": "Coverage by protected areas of important sites for mountain biodiversity"
                        },
                        {
                            "id": "15.4.2",
                            "number": "15.4.2",
                            "name": "Mountain Green Cover Index"
                        }
                    ]
                },
                {
                    "id": "15.5",
                    "number": "15.5",
                    "name": "Take urgent and significant action to reduce the degradation of natural habitats, halt the loss of biodiversity and, by 2020, protect and prevent the extinction of threatened species",
                    "indicators": [
                        {
                            "id": "15.5.1",
                            "number": "15.5.1",
                            "name": "Red List Index"
                        },
                        {
                            "id": "15.5.2",
                            "number": "15.5.2",
                            "name": "Direct economic value generated by coastal ecosystems per unit area"
                        },
                        {
                            "id": "15.5.3",
                            "number": "15.5.3",
                            "name": "Coverage by protected areas of important sites for species"
                        }
                    ]
                },
                {
                    "id": "15.6",
                    "number": "15.6",
                    "name": "Promote fair and equitable sharing of the benefits arising from the utilization of genetic resources and promote appropriate access to such resources, as internationally agreed",
                    "indicators": [
                        {
                            "id": "15.6.1",
                            "number": "15.6.1",
                            "name": "Number of countries that have adopted legislative, administrative and policy frameworks to ensure fair and equitable sharing of benefits"
                        },
                        {
                            "id": "15.6.2",
                            "number": "15.6.2",
                            "name": "Financial flows from the implementation of the Nagoya Protocol"
                        }
                    ]
                },
                {
                    "id": "15.7",
                    "number": "15.7",
                    "name": "Take urgent action to end poaching and trafficking of protected species of flora and fauna and address both demand and supply of illegal wildlife products",
                    "indicators": [
                        {
                            "id": "15.7.1",
                            "number": "15.7.1",
                            "name": "Proportion of traded wildlife that was poached or illicitly trafficked"
                        },
                        {
                            "id": "15.7.2",
                            "number": "15.7.2",
                            "name": "Number of wildlife-related crimes detected and prosecuted"
                        }
                    ]
                },
                {
                    "id": "15.8",
                    "number": "15.8",
                    "name": "By 2020, introduce measures to prevent the introduction and significantly reduce the impact of invasive alien species on land and water ecosystems and control or eradicate the priority species",
                    "indicators": [
                        {
                            "id": "15.8.1",
                            "number": "15.8.1",
                            "name": "Proportion of countries adopting relevant national legislation and adequately resourcing the prevention or control of invasive alien species"
                        },
                        {
                            "id": "15.8.2",
                            "number": "15.8.2",
                            "name": "Amount of financial resources allocated to prevent or control invasive alien species"
                        }
                    ]
                },
                {
                    "id": "15.9",
                    "number": "15.9",
                    "name": "By 2020, integrate ecosystem and biodiversity values into national and local planning, development processes, poverty reduction strategies and accounts",
                    "indicators": [
                        {
                            "id": "15.9.1",
                            "number": "15.9.1",
                            "name": "Progress towards national targets established in accordance with Aichi Biodiversity Target 2 of the Strategic Plan for Biodiversity 2011-2020"
                        },
                        {
                            "id": "15.9.2",
                            "number": "15.9.2",
                            "name": "Proportion of local administrative units with established and operational policies and procedures for participation of local communities in formal decision-making processes on biodiversity"
                        }
                    ]
                },
                {
                    "id": "15.a",
                    "number": "15.a",
                    "name": "Mobilize and significantly increase financial resources from all sources to conserve and sustainably use biodiversity and ecosystems",
                    "indicators": [
                        {
                            "id": "15.a.1",
                            "number": "15.a.1",
                            "name": "Official development assistance and public expenditure on conservation and sustainable use of biodiversity and ecosystems"
                        },
                        {
                            "id": "15.a.2",
                            "number": "15.a.2",
                            "name": "Amount of biodiversity financing from all sources"
                        }
                    ]
                },
                {
                    "id": "15.b",
                    "number": "15.b",
                    "name": "Mobilize significant resources from all sources and at all levels to finance sustainable forest management and provide adequate incentives to developing countries to advance such management, including for conservation and reforestation",
                    "indicators": [
                        {
                            "id": "15.b.1",
                            "number": "15.b.1",
                            "name": "Official development assistance and public expenditure on conservation and sustainable use of biodiversity and ecosystems"
                        },
                        {
                            "id": "15.b.2",
                            "number": "15.b.2",
                            "name": "Amount of biodiversity financing from all sources"
                        }
                    ]
                },
                {
                    "id": "15.c",
                    "number": "15.c",
                    "name": "Enhance global support for efforts to combat poaching and trafficking of protected species, including by increasing the capacity of local communities to pursue sustainable livelihood opportunities",
                    "indicators": [
                        {
                            "id": "15.c.1",
                            "number": "15.c.1",
                            "name": "Proportion of traded wildlife that was poached or illicitly trafficked"
                        },
                        {
                            "id": "15.c.2",
                            "number": "15.c.2",
                            "name": "Number of countries adopting relevant national legislation and adequately resourcing the prevention or control of invasive alien species"
                        }
                    ]
                },
                {
                    "id": "16.1",
                    "number": "16.1",
                    "name": "Significantly reduce all forms of violence and related death rates everywhere",
                    "indicators": [
                        {
                            "id": "16.1.1",
                            "number": "16.1.1",
                            "name": "Number of victims of intentional homicide per 100,000 population, by sex and age"
                        },
                        {
                            "id": "16.1.2",
                            "number": "16.1.2",
                            "name": "Conflict-related deaths per 100,000 population, by sex, age, and cause"
                        }
                    ]
                },
                {
                    "id": "16.2",
                    "number": "16.2",
                    "name": "End abuse, exploitation, trafficking, and all forms of violence against and torture of children",
                    "indicators": [
                        {
                            "id": "16.2.1",
                            "number": "16.2.1",
                            "name": "Proportion of children aged 1-17 years who experienced any physical punishment and/or psychological aggression by caregivers in the past month"
                        },
                        {
                            "id": "16.2.2",
                            "number": "16.2.2",
                            "name": "Number of victims of human trafficking per 100,000 population, by sex, age, and form of exploitation"
                        }
                    ]
                },
                {
                    "id": "16.3",
                    "number": "16.3",
                    "name": "Promote the rule of law at the national and international levels and ensure equal access to justice for all",
                    "indicators": [
                        {
                            "id": "16.3.1",
                            "number": "16.3.1",
                            "name": "Proportion of victims of violence in the previous 12 months who reported their victimization to competent authorities or other officially recognized conflict resolution mechanisms"
                        },
                        {
                            "id": "16.3.2",
                            "number": "16.3.2",
                            "name": "Unsentenced detainees as a proportion of overall prison population"
                        }
                    ]
                },
                {
                    "id": "16.4",
                    "number": "16.4",
                    "name": "By 2030, significantly reduce illicit financial and arms flows, strengthen the recovery and return of stolen assets, and combat all forms of organized crime",
                    "indicators": [
                        {
                            "id": "16.4.1",
                            "number": "16.4.1",
                            "name": "Total value of inward and outward illicit financial flows (in current United States dollars)"
                        },
                        {
                            "id": "16.4.2",
                            "number": "16.4.2",
                            "name": "Proportion of seized, found, or surrendered arms whose illicit origin or context has been traced or established by a competent authority in accordance with national regulations and international instruments"
                        }
                    ]
                },
                {
                    "id": "16.5",
                    "number": "16.5",
                    "name": "Substantially reduce corruption and bribery in all their forms",
                    "indicators": [
                        {
                            "id": "16.5.1",
                            "number": "16.5.1",
                            "name": "Proportion of persons who had at least one contact with a public official and who paid a bribe to a public official, or were asked for a bribe by those public officials, during the previous 12 months"
                        },
                        {
                            "id": "16.5.2",
                            "number": "16.5.2",
                            "name": "Proportion of businesses that had at least one contact with a public official and that paid a bribe to a public official, or were asked for a bribe by those public officials, during the previous 12 months"
                        }
                    ]
                },
                {
                    "id": "16.6",
                    "number": "16.6",
                    "name": "Develop effective, accountable, and transparent institutions at all levels",
                    "indicators": [
                        {
                            "id": "16.6.1",
                            "number": "16.6.1",
                            "name": "Proportion of population satisfied with their last experience of public services"
                        },
                        {
                            "id": "16.6.2",
                            "number": "16.6.2",
                            "name": "Proportion of businesses that had at least one contact with a public official and that paid a bribe to a public official, or were asked for a bribe by those public officials, during the previous 12 months"
                        }
                    ]
                },
                {
                    "id": "16.7",
                    "number": "16.7",
                    "name": "Ensure responsive, inclusive, participatory, and representative decision-making at all levels",
                    "indicators": [
                        {
                            "id": "16.7.1",
                            "number": "16.7.1",
                            "name": "Proportions of positions (by sex, age, persons with disabilities, and population groups) in public institutions (national and local legislatures, public service, and judiciary) compared to national distributions"
                        },
                        {
                            "id": "16.7.2",
                            "number": "16.7.2",
                            "name": "Proportion of population who believe decision-making is inclusive and responsive, by sex, age, disability, and population group"
                        }
                    ]
                },
                {
                    "id": "16.8",
                    "number": "16.8",
                    "name": "Broaden and strengthen the participation of developing countries in the institutions of global governance",
                    "indicators": [
                        {
                            "id": "16.8.1",
                            "number": "16.8.1",
                            "name": "Proportion of members and voting rights of developing countries in international organizations"
                        },
                        {
                            "id": "16.8.2",
                            "number": "16.8.2",
                            "name": "Total official development assistance grants (excluding scholarships) per annum, as a proportion of gross national income"
                        }
                    ]
                },
                {
                    "id": "16.9",
                    "number": "16.9",
                    "name": "By 2030, provide legal identity for all, including birth registration",
                    "indicators": [
                        {
                            "id": "16.9.1",
                            "number": "16.9.1",
                            "name": "Proportion of children under 5 years of age whose births have been registered with a civil authority, by age"
                        },
                        {
                            "id": "16.9.2",
                            "number": "16.9.2",
                            "name": "Proportion of population who hold a recognized identification document, by sex and age"
                        }
                    ]
                },
                {
                    "id": "16.10",
                    "number": "16.10",
                    "name": "Ensure public access to information and protect fundamental freedoms, in accordance with national legislation and international agreements",
                    "indicators": [
                        {
                            "id": "16.10.1",
                            "number": "16.10.1",
                            "name": "Number of verified cases of killing, kidnapping, enforced disappearance, arbitrary detention and torture of journalists, associated media personnel, trade unionists and human rights advocates in the previous 12 months"
                        },
                        {
                            "id": "16.10.2",
                            "number": "16.10.2",
                            "name": "Number of countries that adopt and implement constitutional, statutory and/or policy guarantees for public access to information"
                        }
                    ]
                },
                {
                    "id": "16.a",
                    "number": "16.a",
                    "name": "Strengthen relevant national institutions, including through international cooperation, for building capacity at all levels, in particular in developing countries, to prevent violence and combat terrorism and crime",
                    "indicators": [
                        {
                            "id": "16.a.1",
                            "number": "16.a.1",
                            "name": "Existence of independent national human rights institutions in compliance with the Paris Principles"
                        },
                        {
                            "id": "16.a.2",
                            "number": "16.a.2",
                            "name": "Existence of independent national security complaints mechanisms"
                        }
                    ]
                },
                {
                    "id": "16.b",
                    "number": "16.b",
                    "name": "Promote and enforce non-discriminatory laws and policies for sustainable development",
                    "indicators": [
                        {
                            "id": "16.b.1",
                            "number": "16.b.1",
                            "name": "Proportion of population reporting having personally felt discriminated against or harassed in the previous 12 months on the basis of a ground of discrimination prohibited under international human rights law"
                        },
                        {
                            "id": "16.b.2",
                            "number": "16.b.2",
                            "name": "Number of countries with anti-discrimination legislation and mechanisms in place for proactive identification, investigation and prosecution of discriminatory acts"
                        }
                    ]
                },
                {
                    "id": "16.c",
                    "number": "16.c",
                    "name": "Enhance the global partnership for sustainable development, complemented by multi-stakeholder partnerships that mobilize and share knowledge, expertise, technology, and financial resources",
                    "indicators": [
                        {
                            "id": "16.c.1",
                            "number": "16.c.1",
                            "name": "Proportions of positions in public institutions (national and local legislatures, public service, and judiciary) compared to national distributions, by sex, age, persons with disabilities, and population groups"
                        },
                        {
                            "id": "16.c.2",
                            "number": "16.c.2",
                            "name": "Proportion of countries that (a) have conducted at least one population and housing census in the last 10 years; and (b) have achieved 100 per cent birth registration and 80 per cent death registration"
                        }
                    ]
                },
                {
                    "id": "17.1",
                    "number": "17.1",
                    "name": "Strengthen domestic resource mobilization, including through international support to developing countries, to improve domestic capacity for tax and other revenue collection",
                    "indicators": [
                        {
                            "id": "17.1.1",
                            "number": "17.1.1",
                            "name": "Total government revenue as a proportion of GDP, by source"
                        },
                        {
                            "id": "17.1.2",
                            "number": "17.1.2",
                            "name": "Proportion of domestic budget funded by domestic taxes"
                        },
                        {
                            "id": "17.1.3",
                            "number": "17.1.3",
                            "name": "Proportion of population with access to basic services"
                        }
                    ]
                },
                {
                    "id": "17.2",
                    "number": "17.2",
                    "name": "Developed countries to implement fully their official development assistance commitments, including the commitment by many developed countries to achieve the target of 0.7 percent of gross national income for official development assistance (ODA/GNI) to developing countries and 0.15 to 0.20 percent of ODA/GNI to least developed countries; ODA providers are encouraged to consider setting a target to provide at least 0.20 percent of ODA/GNI to least developed countries",
                    "indicators": [
                        {
                            "id": "17.2.1",
                            "number": "17.2.1",
                            "name": "Net official development assistance, total and to the least developed countries, as a proportion of the Organization for Economic Cooperation and Development (OECD) Development Assistance Committee donors' gross national income (GNI)"
                        },
                        {
                            "id": "17.2.2",
                            "number": "17.2.2",
                            "name": "Proportion of total bilateral, sector-allocable official development assistance of the Organization for Economic Cooperation and Development (OECD) Development Assistance Committee donors to the agriculture sector"
                        }
                    ]
                },
                {
                    "id": "17.3",
                    "number": "17.3",
                    "name": "Mobilize additional financial resources for developing countries from multiple sources",
                    "indicators": [
                        {
                            "id": "17.3.1",
                            "number": "17.3.1",
                            "name": "Foreign direct investment (FDI), official development assistance (ODA), and South-South cooperation as a proportion of total domestic budget"
                        },
                        {
                            "id": "17.3.2",
                            "number": "17.3.2",
                            "name": "Volume of remittances (in United States dollars) as a proportion of total GDP"
                        },
                        {
                            "id": "17.3.3",
                            "number": "17.3.3",
                            "name": "Proportion of domestic budget funded by domestic taxes"
                        }
                    ]
                },
                {
                    "id": "17.4",
                    "number": "17.4",
                    "name": "Assist developing countries in attaining long-term debt sustainability through coordinated policies aimed at fostering debt financing, debt relief, and debt restructuring, as appropriate, and address the external debt of highly indebted poor countries to reduce debt distress",
                    "indicators": [
                        {
                            "id": "17.4.1",
                            "number": "17.4.1",
                            "name": "Debt service as a proportion of exports of goods and services"
                        },
                        {
                            "id": "17.4.2",
                            "number": "17.4.2",
                            "name": "Total number of countries that have reached a sustainable level of debt as a result of debt relief initiatives"
                        }
                    ]
                },
                {
                    "id": "17.5",
                    "number": "17.5",
                    "name": "Adopt and implement investment promotion regimes for least developed countries",
                    "indicators": [
                        {
                            "id": "17.5.1",
                            "number": "17.5.1",
                            "name": "Number of countries that have implemented investment promotion regimes for least developed countries"
                        }
                    ]
                },
                {
                    "id": "17.6",
                    "number": "17.6",
                    "name": "Enhance North-South, South-South, and triangular regional and international cooperation on and access to science, technology, and innovation and enhance knowledge sharing on mutually agreed terms, including through improved coordination among existing mechanisms, particularly at the United Nations level, and through a global technology facilitation mechanism",
                    "indicators": [
                        {
                            "id": "17.6.1",
                            "number": "17.6.1",
                            "name": "Number of science and/or technology cooperation agreements and programmes between countries, by type of cooperation"
                        }
                    ]
                },
                {
                    "id": "17.7",
                    "number": "17.7",
                    "name": "Promote the development, transfer, dissemination, and diffusion of environmentally sound technologies to developing countries on favorable terms, including on concessional and preferential terms, as mutually agreed",
                    "indicators": [
                        {
                            "id": "17.7.1",
                            "number": "17.7.1",
                            "name": "Total amount of approved funding for developing countries to promote the development, transfer, dissemination, and diffusion of environmentally sound technologies"
                        }
                    ]
                },
                {
                    "id": "17.8",
                    "number": "17.8",
                    "name": "Fully operationalize the technology bank and science, technology, and innovation capacity-building mechanism for least developed countries by 2017 and enhance the use of enabling technology, in particular information and communications technology",
                    "indicators": [
                        {
                            "id": "17.8.1",
                            "number": "17.8.1",
                            "name": "Proportion of individuals using the Internet"
                        }
                    ]
                },
                {
                    "id": "17.9",
                    "number": "17.9",
                    "name": "Enhance capacity to implement the SDGS, including North-South, South-South and triangular cooperation",
                    "indicators": [
                        {
                            "id": "17.9.1",
                            "number": "17.9.1",
                            "name": "Dollar value of financial and technical assistance (including through North-South, South-South, and triangular cooperation) committed to developing countries"
                        }
                    ]
                },
                {
                    "id": "17.10",
                    "number": "17.10",
                    "name": "Promote a universal, rules-based, open, non-discriminatory, and equitable multilateral trading system under the World Trade Organization, including through the conclusion of negotiations under its Doha Development Agenda",
                    "indicators": [
                        {
                            "id": "17.10.1",
                            "number": "17.10.1",
                            "name": "World Trade Organization (WTO) agreements fully implemented (members)"
                        }
                    ]
                },
                {
                    "id": "17.11",
                    "number": "17.11",
                    "name": "Significantly increase the exports of developing countries, in particular with a view to doubling the least developed countries' share of global exports by 2020",
                    "indicators": [
                        {
                            "id": "17.11.1",
                            "number": "17.11.1",
                            "name": "Developing countries' and least developed countries' share of global exports"
                        }
                    ]
                },
                {
                    "id": "17.12",
                    "number": "17.12",
                    "name": "Realize timely implementation of duty-free and quota-free market access on a lasting basis for all least developed countries, consistent with World Trade Organization decisions, including by ensuring that preferential rules of origin applicable to imports from least developed countries are transparent and simple, and contribute to facilitating market access",
                    "indicators": [
                        {
                            "id": "17.12.1",
                            "number": "17.12.1",
                            "name": "Average tariffs faced by developing countries, least developed countries, and small island developing states"
                        }
                    ]
                },
                {
                    "id": "17.13",
                    "number": "17.13",
                    "name": "Enhance global macroeconomic stability, including through policy coordination and policy coherence",
                    "indicators": [
                        {
                            "id": "17.13.1",
                            "number": "17.13.1",
                            "name": "Macroeconomic dashboard"
                        }
                    ]
                },
                {
                    "id": "17.14",
                    "number": "17.14",
                    "name": "Enhance policy coherence for sustainable development",
                    "indicators": [
                        {
                            "id": "17.14.1",
                            "number": "17.14.1",
                            "name": "Number of countries with mechanisms in place to enhance policy coherence of sustainable development"
                        }
                    ]
                },
                {
                    "id": "17.15",
                    "number": "17.15",
                    "name": "Respect each country's policy space and leadership to establish and implement policies for poverty eradication and sustainable development",
                    "indicators": [
                        {
                            "id": "17.15.1",
                            "number": "17.15.1",
                            "name": "Extent of use of country-owned results frameworks and planning tools by providers of development cooperation"
                        }
                    ]
                },
                {
                    "id": "17.16",
                    "number": "17.16",
                    "name": "Enhance the Global Partnership for Sustainable Development, complemented by multi-stakeholder partnerships that mobilize and share knowledge, expertise, technology, and financial resources, to support the achievement of the Sustainable Development Goals in all countries, in particular developing countries",
                    "indicators": [
                        {
                            "id": "17.16.1",
                            "number": "17.16.1",
                            "name": "Number of countries reporting progress in multi-stakeholder development effectiveness monitoring frameworks that support the achievement of the Sustainable Development Goals"
                        }
                    ]
                },
                {
                    "id": "17.17",
                    "number": "17.17",
                    "name": "Encourage and promote effective public, public-private, and civil society partnerships, building on the experience and resourcing strategies of partnerships",
                    "indicators": [
                        {
                            "id": "17.17.1",
                            "number": "17.17.1",
                            "name": "Number of countries with mechanisms in place to enhance policy coherence of sustainable development"
                        },
                        {
                            "id": "17.17.2",
                            "number": "17.17.2",
                            "name": "Amount (in United States dollars) of United Nations Development Assistance Framework (UNDAF) resources mobilized for development partners"
                        }
                    ]
                },
                {
                    "id": "17.18",
                    "number": "17.18",
                    "name": "By 2020, enhance capacity-building support to developing countries, including for least developed countries and small island developing states, to increase significantly the availability of high-quality, timely, and reliable data disaggregated by income, gender, age, race, ethnicity, migratory status, disability, geographic location, and other characteristics relevant in national contexts",
                    "indicators": [
                        {
                            "id": "17.18.1",
                            "number": "17.18.1",
                            "name": "Proportion of sustainable development indicators produced at the national level with full disaggregation when relevant to the target, in accordance with the Fundamental Principles of Official Statistics"
                        }
                    ]
                },
                {
                    "id": "17.19",
                    "number": "17.19",
                    "name": "By 2030, build on existing initiatives to develop measurements of progress on sustainable development that complement gross domestic product, and support statistical capacity-building in developing countries",
                    "indicators": [
                        {
                            "id": "17.19.1",
                            "number": "17.19.1",
                            "name": "Dollar value of all resources made available to strengthen statistical capacity in developing countries"
                        }
                    ]
                },
                {
                    "id": "17.20",
                    "number": "17.20",
                    "name": "Promote the development of a global indicator framework for the Sustainable Development Goals and related targets, taking into account the work of the United Nations and other relevant organizations",
                    "indicators": [
                        {
                            "id": "17.20.1",
                            "number": "17.20.1",
                            "name": "Proportion of indicators with full methodological documentation and data disaggregated by sex, age, race, ethnicity, migratory status, disability, geographic location, and other characteristics relevant in national contexts"
                        }
                    ]
                }


            ]
        }
    },
    getters: {
    },
    persist: true
})