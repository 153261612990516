// @vue/component
import {useStrategyDetailsStore} from "@/stores/CrossWalkAnalysis/strategyDetails";
import {storeToRefs} from "pinia/dist/pinia";
import { MDBBtn, MDBIcon, MDBTooltip } from 'mdb-vue-ui-kit';
import {useCrosswalkChallengeStore} from "@/stores/CrossWalkAnalysis/challenge";

export default {
  name: 'StrategySetup',

  components: {MDBBtn, MDBIcon, MDBTooltip},

  mixins: [],

  props: {},

  setup() {
    const strategyDetailsStore = useStrategyDetailsStore()
    const challengeStore = useCrosswalkChallengeStore()
    const { editMode } =  storeToRefs(challengeStore)
    const { matrixType } =  storeToRefs(strategyDetailsStore)
    const { goalNumber } =  storeToRefs(strategyDetailsStore)

    return { strategyDetailsStore, matrixType, goalNumber, editMode}

  },

  data () {
    return {
      goals:[
        {
          "id": "1",
          "name": "1: No Poverty",
          "description": "End poverty in all its forms everywhere",
          "targets": [
            {
              "id": "1.1",
              "number": "1.1",
              "name": "Eradicate extreme poverty"
            },
            {
              "id": "1.2",
              "number": "1.2",
              "name": "Reduce poverty by 1/2 or more"
            },
            {
              "id": "1.3",
              "number": "1.3",
              "name": "Establish social protection goals and minimums for all"
            },
            {
              "id": "1.4",
              "number": "1.4",
              "name": "Ensure  equal rights to economic and natural resources, services, and technology "
            },
            {
              "id": "1.5",
              "number": "1.5",
              "name": "Reduce exposure and build the resilience to complex emergencies"
            },
            {
              "id": "1.a",
              "number": "1.a",
              "name": "Ensure resource mobilization "
            },
            {
              "id": "1.b",
              "number": "1.b",
              "name": "Create pro-poor and gender-sensitive policy to eradicate poverty"
            }
          ]
        },
        {
          "id": "2",
          "name": "2: Zero Hunger",
          "description": "End hunger, achieve food security and improved nutrition and promote sustainable agriculture",
          "targets": [
            {
              "id": "2.1",
              "number": "2.1",
              "name": "End hunger"
            },
            {
              "id": "2.2",
              "number": "2.2",
              "name": "End all forms of undernutrition"
            },
            {
              "id": "2.3",
              "number": "2.3",
              "name": "Double agricultural productivity  of small-scale food producers"
            },
            {
              "id": "2.4",
              "number": "2.4",
              "name": "Enhance sustainable food production systems "
            },
            {
              "id": "2.5",
              "number": "2.5",
              "name": "Maintain the genetic diversity of seeds, cultivated plants and farmed and domesticated animals and related wild species"
            },
            {
              "id": "2.a",
              "number": "2.a",
              "name": "Increase investment in rural infrastructure"
            },
            {
              "id": "2.b",
              "number": "2.b",
              "name": "Correct and prevent trade restrictions and distortions"
            },
            {
              "id": "2.c",
              "number": "2.c",
              "name": "Ensure proper functioning of food commodity markets to eliminate price volatility"
            }
          ]
        },
        {
          "id": "3",
          "name": "3: Good Health and Well-Being",
          "description": "Ensure healthy lives and promote well-being for all at all ages",
          "targets": [
            {
              "id": "3.1",
              "number": "3.1",
              "name": "Reduce Maternal Mortality "
            },
            {
              "id": "3.2",
              "number": "3.2",
              "name": "By 2030, end preventable deaths of newborns and children under 5 years of age"
            },
            {
              "id": "3.3",
              "number": "3.3",
              "name": "End epidemics of communicable diseases "
            },
            {
              "id": "3.4",
              "number": "3.4",
              "name": "Reduce premature mortality from non-communicable diseases "
            },
            {
              "id": "3.5",
              "number": "3.5",
              "name": "Prevent and treatment of substance abuse"
            },
            {
              "id": "3.6",
              "number": "3.6",
              "name": "Reduce or eliminate deaths and injuries from road traffic accidents"
            },
            {
              "id": "3.7",
              "number": "3.7",
              "name": "Ensure access to sexual and reproductive health-care services"
            },
            {
              "id": "3.8",
              "number": "3.8",
              "name": "Provide universal health coverage"
            },
            {
              "id": "3.9",
              "number": "3.9",
              "name": "Reduce death and illnesses from pollution "
            },
            {
              "id": "3.a",
              "number": "3.a",
              "name": "Address Tobacco Control and Prevention"
            },
            {
              "id": "3.b",
              "number": "3.b",
              "name": "Support vaccine development "
            },
            {
              "id": "3.c",
              "number": "3.c",
              "name": "Support health workforce development ",
              "description": ""
            },
            {
              "id": "3.d",
              "number": "3.d",
              "name": "Develop effective early warning systems",
              "description": ""
            }
          ]
        },
        {
          "id": "4",
          "name": "4: Quality Education",
          "description": "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all",
          "targets": [
            {
              "id": "4.1",
              "number": "4.1",
              "name": "All children complete high school"
            },
            {
              "id": "4.2",
              "number": "4.2",
              "name": "Access to pre-school"
            },
            {
              "id": "4.3",
              "number": "4.3",
              "name": "Access to affordable and quality higher education "
            },
            {
              "id": "4.4",
              "number": "4.4",
              "name": "Increase employability"
            },
            {
              "id": "4.5",
              "number": "4.5",
              "name": "Eliminate gender disparities in education and ensure equal access"
            },
            {
              "id": "4.6",
              "number": "4.6",
              "name": "Ensure that achieve literacy and numeracy"
            },
            {
              "id": "4.7",
              "number": "4.7",
              "name": "Ensure that people have knowledge and skills needed to promote sustainable development"
            },
            {
              "id": "4.a",
              "number": "4.a",
              "name": "Facilities that are inclusive and safe for all"
            },
            {
              "id": "4.b",
              "number": "4.b",
              "name": "Expand scholarships"
            },
            {
              "id": "4.c",
              "number": "4.c",
              "name": "Increase supply of qualified teacher"
            }
          ]
        },
        {
          "id": "5",
          "name": "5: Gender Equality",
          "description": "Achieve gender equality and empower all women and girls",
          "targets": [
            {
              "id": "5.1",
              "number": "5.1",
              "name": "End all gender discrimination "
            },
            {
              "id": "5.2",
              "number": "5.2",
              "name": "Eliminate gendered violence including trafficking and exploitation"
            },
            {
              "id": "5.3",
              "number": "5.3",
              "name": "Eliminate harmful practices"
            },
            {
              "id": "5.4",
              "number": "5.4",
              "name": "Value unpaid care and domestic work "
            },
            {
              "id": "5.5",
              "number": "5.5",
              "name": "Ensure women's full participation in public life"
            },
            {
              "id": "5.6",
              "number": "5.6",
              "name": "Ensure universal access to sexual and reproductive health care"
            },
            {
              "id": "5.a",
              "number": "5.a",
              "name": "Give women equal rights to resources"
            },
            {
              "id": "5.b",
              "number": "5.b",
              "name": "Use technology to promote equality "
            },
            {
              "id": "5.c",
              "number": "5.c",
              "name": "Adopt gender equity policies "
            }
          ]
        },
        {
          "id": "6",
          "name": "6: Clean Water and Sanitation",
          "description": "Ensure availability and sustainable management of water and sanitation for all",
          "targets": [
            {
              "id": "6.1",
              "number": "6.1",
              "name": "Access to safe and affordable drinking water "
            },
            {
              "id": "6.2",
              "number": "6.2",
              "name": "Access to sanitation and hygiene for all "
            },
            {
              "id": "6.3",
              "number": "6.3",
              "name": " Improve water quality "
            },
            {
              "id": "6.4",
              "number": "6.4",
              "name": "Increase  water-use efficiency"
            },
            {
              "id": "6.5",
              "number": "6.5",
              "name": "Integrated water resources management "
            },
            {
              "id": "6.6",
              "number": "6.6",
              "name": "Protect and restore water-related ecosystems"
            },
            {
              "id": "6.a",
              "number": "6.a",
              "name": "Expand capacity  in water- and sanitation"
            },
            {
              "id": "6.b",
              "number": "6.b",
              "name": "Community participation in water and sanitation "
            }
          ]
        },
        {
          "id": "7",
          "name": "7: Affordable and Clean Energy",
          "description": "Ensure access to affordable, reliable, sustainable and modern energy for all",
          "targets": [
            {
              "id": "7.1",
              "number": "7.1",
              "name": "Access to energy services "
            },
            {
              "id": "7.2",
              "number": "7.2",
              "name": " Increase renewable energy "
            },
            {
              "id": "7.3",
              "number": "7.3",
              "name": "Double energy efficiency"
            },
            {
              "id": "7.a",
              "number": "7.a",
              "name": "Facilitate access to clean energy research and technology"
            },
            {
              "id": "7.b",
              "number": "7.b",
              "name": "Expand energy infrastructure "
            }
          ]
        },
        {
          "id": "8",
          "name": "8: Decent Work and Economic Growth",
          "description": "Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all",
          "targets": [
            {
              "id": "8.1",
              "number": "8.1",
              "name": "sustain per capita growth"
            },
            {
              "id": "8.2",
              "number": "8.2",
              "name": "Pursue diversification, technological upgrading and innovation"
            },
            {
              "id": "8.3",
              "number": "8.3",
              "name": "Job creation policies  "
            },
            {
              "id": "8.4",
              "number": "8.4",
              "name": "Decouple economic growth from environmental degradation"
            },
            {
              "id": "8.5",
              "number": "8.5",
              "name": "Full employment and decent work for all"
            },
            {
              "id": "8.6",
              "number": "8.6",
              "name": "Reduce youth unemployment"
            },
            {
              "id": "8.7",
              "number": "8.7",
              "name": "Eradicate forced labor,  modern slavery and human trafficking "
            },
            {
              "id": "8.8",
              "number": "8.8",
              "name": "Protect labor rights and working environments "
            },
            {
              "id": "8.9",
              "number": "8.9",
              "name": "Promote sustainable tourism "
            },
            {
              "id": "8.10",
              "number": "8.10",
              "name": "Strengthen financial services for all"
            },
            {
              "id": "8.a",
              "number": "8.a",
              "name": "Increase Aid for Trade "
            },
            {
              "id": "8.b",
              "number": "8.b",
              "name": "Develop and operationalize a  strategy for youth employment ",
              "description": ""
            }
          ]
        },
        {
          "id": "9",
          "name": "9: Industry, Innovation and Infrastructure",
          "description": "Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation",
          "targets": [
            {
              "id": "9.1",
              "number": "9.1",
              "name": "Develop resilient infrastructure"
            },
            {
              "id": "9.2",
              "number": "9.2",
              "name": "Promote inclusive and sustainable industrialization "
            },
            {
              "id": "9.3",
              "number": "9.3",
              "name": "Increase  financial services for small businesses"
            },
            {
              "id": "9.4",
              "number": "9.4",
              "name": "Upgrade and retrofit for sustainability"
            },
            {
              "id": "9.5",
              "number": "9.5",
              "name": "Enhance technological capabilities of employment sector"
            },
            {
              "id": "9.a",
              "number": "9.a",
              "name": "Facilitate resilient infrastructure development "
            },
            {
              "id": "9.b",
              "number": "9.b",
              "name": "Support local technology development"
            },
            {
              "id": "9.c",
              "number": "9.c",
              "name": "Increase access to information and communications technology"
            }
          ]
        },
        {
          "id": "10",
          "name": "10: Reduced Inequalities",
          "description": "Reduce inequality within and among countries",
          "targets": [
            {
              "id": "10.1",
              "number": "10.1",
              "name": "Income growth for lower 40%"
            },
            {
              "id": "10.2",
              "number": "10.2",
              "name": " promote the social, economic and political inclusion of all"
            },
            {
              "id": "10.3",
              "number": "10.3",
              "name": "Eliminate discriminatory laws and policies "
            },
            {
              "id": "10.4",
              "number": "10.4",
              "name": "Adopt fiscal, wage and social protection policies"
            },
            {
              "id": "10.5",
              "number": "10.5",
              "name": "Improve the regulation and monitoring of global financial markets "
            },
            {
              "id": "10.6",
              "number": "10.6",
              "name": "Ensure representation  in economic decision-making"
            },
            {
              "id": "10.7",
              "number": "10.7",
              "name": "Support orderly, safe, regular and responsible migration"
            },
            {
              "id": "10.a",
              "number": "10.a",
              "name": "Implement the principle of special and differential treatment for low-resource settings "
            },
            {
              "id": "10.b",
              "number": "10.b",
              "name": "Encourage assistance and financial flows where the need is greatest"
            },
            {
              "id": "10.c",
              "number": "10.c",
              "name": "Reduce transaction costs of migrant remittances to <3%"
            }
          ]
        },
        {
          "id": "11",
          "number": "11",
          "name": "11: Sustainable Cities and Communities",
          "description": "Make cities and human settlements inclusive, safe, resilient and sustainable",
          "targets": [
            {
              "id": "11.1",
              "number": "11.1",
              "name": "Quality safe housing for all"
            },
            {
              "id": "11.2",
              "number": "11.2",
              "name": "Safe, affordable, accessible and sustainable transport "
            },
            {
              "id": "11.3",
              "number": "11.3",
              "name": "Integrated and sustainable city planning "
            },
            {
              "id": "11.4",
              "number": "11.4",
              "name": "Protect  cultural and natural heritage"
            },
            {
              "id": "11.5",
              "number": "11.5",
              "name": "Reduce death and loss caused by disasters, including water-related disasters."
            },
            {
              "id": "11.6",
              "number": "11.6",
              "name": "Reduce the adverse impact of city on air quality and waste "
            },
            {
              "id": "11.7",
              "number": "11.7",
              "name": "Access to safe, inclusive and accessible, green and public spaces"
            },
            {
              "id": "11.a",
              "number": "11.a",
              "name": "Support urban, peri-urban and rural cooperation"
            },
            {
              "id": "11.b",
              "number": "11.b",
              "name": "Adopt plans for mitigation and adaptation to climate change and disasters"
            },
            {
              "id": "11.c",
              "number": "11.c",
              "name": "Build sustainable and resilient buildings "
            }
          ]
        },
        {
          "id": "12",
          "name": "12: Responsible Consumption and Production",
          "description": "Ensure sustainable consumption and production patterns",
          "targets": [
            {
              "id": "12.1",
              "number": "12.1",
              "name": "Implement sustainable consumption and production plans"
            },
            {
              "id": "12.2",
              "number": "12.2",
              "name": "Sustainable management of natural resources"
            },
            {
              "id": "12.3",
              "number": "12.3",
              "name": "Halve per capita food waste "
            },
            {
              "id": "12.4",
              "number": "12.4",
              "name": "Environmentally sound management of chemicals and all wastes "
            },
            {
              "id": "12.5",
              "number": "12.5",
              "name": "Reduce waste generation "
            },
            {
              "id": "12.6",
              "number": "12.6",
              "name": "Encourage companies to adopt sustainable practices and reporting"
            },
            {
              "id": "12.7",
              "number": "12.7",
              "name": "Sustainable public procurement practices"
            },
            {
              "id": "12.8",
              "number": "12.8",
              "name": "Provide information about sustainable development and lifestyles in harmony with nature"
            },
            {
              "id": "12.a",
              "number": "12.a",
              "name": "Strengthen scientific and technological capacity"
            },
            {
              "id": "12.b",
              "number": "12.b",
              "name": "Foster sustainable tourism "
            },
            {
              "id": "12.c",
              "number": "12.c",
              "name": "Rationalize incentives related to fossil-fuel consumption"
            }
          ]
        },
        {
          "id": "13",
          "name": "13: Climate Action",
          "description": "Take urgent action to combat climate change and its impacts",
          "targets": [
            {
              "id": "13.1",
              "number": "13.1",
              "name": "Strengthen adaptive capacity related to climate "
            },
            {
              "id": "13.2",
              "number": "13.2",
              "name": "Integrate climate change measures"
            },
            {
              "id": "13.3",
              "number": "13.3",
              "name": "Improve knowledge about climate change mitigation, adaptation, impact reduction and early warning"
            },
            {
              "id": "13.a",
              "number": "13.a",
              "name": "Mobilize resources for mitigation of climate change in lower income countries"
            },
            {
              "id": "13.b",
              "number": "13.b",
              "name": "Effective climate change-related planning and management"
            }
          ]
        },
        {
          "id": "14",
          "name": "14: Life Below Water",
          "description": "Conserve and sustainably use the oceans, seas and marine resources for sustainable development",
          "targets": [
            {
              "id": "14.1",
              "number": "14.1",
              "name": "Reduce Marine Pollution"
            },
            {
              "id": "14.2",
              "number": "14.2",
              "name": " Manage and protect marine and coastal ecosystems"
            },
            {
              "id": "14.3",
              "number": "14.3",
              "name": "Address ocean acidification"
            },
            {
              "id": "14.4",
              "number": "14.4",
              "name": "Manage harvesting and end overfishing"
            },
            {
              "id": "14.5",
              "number": "14.5",
              "name": "Conserve at least 10 per cent of coastal and marine areas"
            },
            {
              "id": "14.6",
              "number": "14.6",
              "name": "Prohibit  fisheries subsidies which contribute to overcapacity and overfishing"
            },
            {
              "id": "14.7",
              "number": "14.7",
              "name": "Support the welfare of  Small Island communities"
            },
            {
              "id": "14.a",
              "number": "14.a",
              "name": "Increase scientific knowledge and transfer marine technology"
            },
            {
              "id": "14.b",
              "number": "14.b",
              "name": "Support small-scale artisanal fisheries "
            },
            {
              "id": "14.c",
              "number": "14.c",
              "name": "Enhance the conservation and sustainable use of oceans "
            }
          ]
        },
        {
          "id": "15",
          "name": "15: Life On Land",
          "description": "Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss",
          "targets": [
            {
              "id": "15.1",
              "number": "15.1",
              "name": "Conserve and restore life on land"
            },
            {
              "id": "15.2",
              "number": "15.2",
              "name": "Sustainable management of all types of forests"
            },
            {
              "id": "15.3",
              "number": "15.3",
              "name": "Combat desertification"
            },
            {
              "id": "15.4",
              "number": "15.4",
              "name": "Conserve mountain ecosystems"
            },
            {
              "id": "15.5",
              "number": "15.5",
              "name": "Reduce  degradation of natural habitats and biodiversity"
            },
            {
              "id": "15.6",
              "number": "15.6",
              "name": "Ethical utilization of genetic resources "
            },
            {
              "id": "15.7",
              "number": "15.7",
              "name": "End poaching and trafficking of protected species "
            },
            {
              "id": "15.8",
              "number": "15.8",
              "name": " Reduce the impact of invasive alien species "
            },
            {
              "id": "15.9",
              "number": "15.9",
              "name": "Plan with ecosystem and biodiversity "
            },
            {
              "id": "15.a",
              "number": "15.a",
              "name": "Increase investment in conservation"
            },
            {
              "id": "15.b",
              "number": "15.b",
              "name": "Increase investment and incentives for forest management "
            },
            {
              "id": "15.c",
              "number": "15.c",
              "name": "Combat poaching and develop alternative  livelihoods",
              "description": ""
            }
          ]
        },
        {
          "id": "16",
          "name": "16: Peace, Justice and Strong Institutions",
          "description": "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels",
          "targets": [
            {
              "id": "16.1",
              "number": "16.1",
              "name": "Reduce violence "
            },
            {
              "id": "16.2",
              "number": "16.2",
              "name": "End abuse, exploitation, trafficking and all forms of violence against and torture of children"
            },
            {
              "id": "16.3",
              "number": "16.3",
              "name": "Promote the rule of law "
            },
            {
              "id": "16.4",
              "number": "16.4",
              "name": "Reduce illicit financial and arms flows"
            },
            {
              "id": "16.5",
              "number": "16.5",
              "name": "Reduce corruption and bribery in all their forms"
            },
            {
              "id": "16.6",
              "number": "16.6",
              "name": "Effective, accountable and transparent institutions "
            },
            {
              "id": "16.7",
              "number": "16.7",
              "name": "Ensure responsive decision-making "
            },
            {
              "id": "16.8",
              "number": "16.8",
              "name": "Strengthen the participation in state, national and global governance "
            },
            {
              "id": "16.9",
              "number": "16.9",
              "name": "Provide legal identity for all"
            },
            {
              "id": "16.1",
              "number": "16.1",
              "name": " Protect fundamental freedoms"
            },
            {
              "id": "16.a",
              "number": "16.a",
              "name": "Strengthen institutions to prevent violence and combat terrorism and crime"
            },
            {
              "id": "16.b",
              "number": "16.b",
              "name": "Promote and enforce non-discriminatory laws and policies",
              "description": ""
            }
          ]
        },
        {
          "id": "17",
          "name": "17: Partnerships for the Goals",
          "description": "Strengthen the means of implementation and revitalize the Global Partnership for Sustainable Development",
          "targets": [
            {
              "id": "17.1",
              "number": "17.1",
              "name": "Strengthen resource base"
            },
            {
              "id": "17.2",
              "number": "17.2",
              "name": "Development assistance commitments"
            },
            {
              "id": "17.3",
              "number": "17.3",
              "name": "Mobilize resources for development "
            },
            {
              "id": "17.4",
              "number": "17.4",
              "name": "Coordinate policies aimed at fostering debt financing and relief  "
            },
            {
              "id": "17.5",
              "number": "17.5",
              "name": "Investment for least developed countries"
            },
            {
              "id": "17.6",
              "number": "17.6",
              "name": "Engage in global cooperation on and access to science, technology and innovation "
            },
            {
              "id": "17.7",
              "number": "17.7",
              "name": "Promote environmentally sound technologies "
            },
            {
              "id": "17.8",
              "number": "17.8",
              "name": "Science, technology and innovation capacity-building "
            },
            {
              "id": "17.9",
              "number": "17.9",
              "name": "Enhance capacity to implement the SDGS, including North-South, South-South and triangular cooperation"
            },
            {
              "id": "17.1",
              "number": "17.1",
              "name": "Equitable multilateral trading system "
            },
            {
              "id": "17.11",
              "number": "17.11",
              "name": "Increase the exports of developing countries"
            },
            {
              "id": "17.12",
              "number": "17.12",
              "name": "Market Access",
              "description": ""
            },
            {
              "id": "17.13",
              "number": "17.13",
              "name": "Foster stability with economic policy",
              "description": ""
            },
            {
              "id": "17.14",
              "number": "17.14",
              "name": "Sustainable development policy"
            },
            {
              "id": "17.15",
              "number": "17.15",
              "name": "Respect each country’s policy space and leadership "
            },
            {
              "id": "17.16",
              "number": "17.16",
              "name": "Enhance the Global Partnership for Sustainable Development"
            },
            {
              "id": "17.17",
              "number": "17.17",
              "name": "Effective public, public-private and civil society partnerships"
            },
            {
              "id": "17.18",
              "number": "17.18",
              "name": "Improve capacity for complete data systems "
            },
            {
              "id": "17.19",
              "number": "17.19",
              "name": "Develop measures of progress on sustainable development "
            }
          ]
        }
      ]
      ,
    }
  },

  computed: {},

  watch: {
    matrixType: {
      handler() {
        this.strategyDetailsStore.matrixTypeChanged()
      }
    },
    goalNumber: {
      handler() {
        this.strategyDetailsStore.goalNumberChanged()
      }
    },
  },

  mounted () {
    this.strategyDetailsStore.fetchCrosswalkAnalysis()
    this.strategyDetailsStore.showNext = false
    if (this.goalNumber === null || this.goalNumber === undefined || this.goalNumber === "") {
      this.goalNumber = 1
    }
  },

  methods: {}
}
