import { defineStore } from 'pinia'
import useWheelAnalysisQuery from "@/composables/useWheelAnalysisQuery";
import {useAnalysisStore} from "@/stores/Setup/analysis";
import axios from "axios";
//import axios from "axios";
//import {useAnalysisStore} from "@/stores/Setup/analysis";

export const useImageStore = defineStore('image', {
    state: () => {
        return {
            imageFile: null,
            previewImage: null

        }
    },
    getters: {
    },
    actions: {
        async fetchWheelAnalysis() {
            await useWheelAnalysisQuery()
            //console.log(data)
        },
        imageFileChanged() {
            const analysisStore = useAnalysisStore()

            //console.log("image file changed")
            //console.log(this.previewImage)
            //console.log(analysisStore.wheelAnalysisId)
            let fd = new FormData()
            fd.append('image_file', this.previewImage)

            if (this.previewImage !== null) {  // TODO: add header with token
                if (analysisStore.wheelAnalysisId !== null) {  // TODO: add header with token

                    //console.log(this.previewImage)
                    axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/wheel_analyses/' + analysisStore.wheelAnalysisId + '/image_file', fd, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }).then(response => {
                        //console.log("image updated!")
                        console.log(response)
                    }).catch(err => {
                        console.log("error:")
                        console.log(err)
                    })
                }
            }

        },
    },
    persist: true
})