<template>
    <div class="AnalysisName">
      <div id="form-div">

      <div class="row justify-content-center align-items-center px-3" >
          <div class="col-md-10">Select a name for your analysis.</div>

        <div class="col-md-10">
            <input :disabled = "!editMode" label=" " v-model="name"
                      @blur = "challengeStore.nameChanged()"
            />
        </div>
    </div>
        <div class="sidebar-instructions p-4">
          This will let you return to this analysis in the future.
        </div>
      </div>


    </div>


  <router-view />
</template>

<script src="./_AnalysisName.js" lang="js"></script>
<style src="./_AnalysisName.less" lang="less" scoped></style>
