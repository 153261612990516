<template>
  <div class="row justify-content-left align-items-left">
    <div class="col-md-12">

      <div style="background-color: #3b71ca;
    color: white;
    padding: 20px;
    margin-bottom: 20px;
    margin-top: -10px;">{{strategyDetailsStore.allStrategyDetails[strategyDetailsStore.setupStrategyId]?.keyword}}</div>
      <div class="col-md-12 px-3" >
        <div v-if="strategyDetailsStore.allStrategyDetails[strategyDetailsStore.setupStrategyId] !== undefined">
          <MDBInput label="Strategy Name" :disabled = "!editMode" v-model="strategyDetailsStore.allStrategyDetails[strategyDetailsStore.setupStrategyId].keyword" @blur="strategyDetailsStore.exitedField()"  @change="strategyDetailsStore.allStrategyDetailsChanged()"/>
        </div>
      </div>
  <br/>
      <div class="col-md-12 px-3" >
        <div v-if="strategyDetailsStore.allStrategyDetails[strategyDetailsStore.setupStrategyId] !== undefined">
          <MDBTextarea label="Full Strategy" :disabled = "!editMode"  rows="2" v-model="strategyDetailsStore.allStrategyDetails[strategyDetailsStore.setupStrategyId].name" @blur="strategyDetailsStore.exitedField()"  @change="strategyDetailsStore.allStrategyDetailsChanged()"/>
        </div>
      </div>

    <br/>
    <br/>
  <div v-if="strategyDetailsStore.allStrategyDetails[strategyDetailsStore.setupStrategyId] !== undefined">
    <div v-for="(subStrategy, subStrategyIndex) in strategyDetailsStore.allStrategyDetails[strategyDetailsStore.setupStrategyId]['subStrategies']" :key="subStrategyIndex" class="row offset-1 col-md-12">
      <br/>
      <br/>
      <div class=" col-md-9">
        <MDBTextarea rows="2" label="sub-strategy" :disabled = "!editMode" v-model="subStrategy.name" @blur="strategyDetailsStore.exitedField()"  @change="strategyDetailsStore.allStrategyDetailsChanged()"/>
        <br/>
      </div>
      <div class=" col-md-9">
        <div v-if="subStrategy !== undefined">
        <MDBInput label="keyword" :disabled = "!editMode" v-model="subStrategy.keyword" @blur="strategyDetailsStore.exitedField()"  @change="strategyDetailsStore.allStrategyDetailsChanged()"/>
        </div>
        <br/>
        <br/>
      </div>
      <div v-if="editMode" class="col-md-1" style="text-align: left; ">
        <MDBTooltip v-model="removesubtooltip[strategyDetailsStore.setupStrategyId][subStrategyIndex]">
          <template #reference>
            <MDBBtn color="primary" floating @click="removeSubStrategy(strategyDetailsStore.setupStrategyId, subStrategyIndex)" >
              <FontAwesomeIcon icon="fa-solid fa-minus" />
            </MDBBtn>
          </template>
          <template #tip>
            Remove sub-strategy
          </template>
        </MDBTooltip>
        <br/><br/>
      </div>
    </div>
  </div>
    <div v-if="editMode" class="offset-1" style="margin-bottom:20px; text-align:left">
      <MDBTooltip v-model="addsubtooltip[strategyDetailsStore.setupStrategyId]">
        <template #reference>
          <MDBBtn color="primary" floating @click="addSubStrategy(strategyDetailsStore.setupStrategyId)">
            <FontAwesomeIcon icon="fa-solid fa-plus" />
          </MDBBtn>
        </template>
        <template #tip>
          Add sub-strategy
        </template>
      </MDBTooltip>
    </div>
<div><a  v-if="editMode" @click="clickRemoveStrategy" class="btn btn-outline-danger">Remove Strategy</a></div>
      <br>
    </div>
  </div>

</template>

<script src="./_SingleStrategySetup.js" lang="js"></script>
<style src="./_SingleStrategySetup.less" lang="less" scoped></style>
