<template>
    <div class="ChallengeType">
      <div id="form-div">

      <div class="row justify-content-center align-items-center px-3" >
          <div class="col-md-10">Select the type of challenge that you will address.</div>

        <div class="col-md-10">
          <MDBRadio
              style="text-align:left"
              label="Housing"
              name="challengeType"
              v-model="challengeType"
              value="Housing"
              @blur = "challengeStore.challengeTypeChanged"
              :disabled = "!editMode"
          />
          <MDBRadio
              style="text-align:left"
              label="Transportation"
              name="challengeType"
              v-model="challengeType"
              value="Transportation"
              @blur = "challengeStore.challengeTypeChanged"
              :disabled = "!editMode"
          />
          <MDBRadio
              label="Parks and Public Spaces"
              name="challengeType"
              v-model="challengeType"
              value="Parks and Public Spaces"
              @blur = "challengeStore.challengeTypeChanged"
              :disabled = "!editMode"
          />
          <MDBRadio
              label="Public Safety"
              name="challengeType"
              v-model="challengeType"
              value="Public Safety"
              @blur = "challengeStore.challengeTypeChanged"
              :disabled = "!editMode"
          />
          <MDBRadio
              label="Public Health"
              name="challengeType"
              v-model="challengeType"
              value="Public Health"
              @blur = "challengeStore.challengeTypeChanged"
              :disabled = "!editMode"
          />
          <MDBRadio
              label="Education"
              name="challengeType"
              v-model="challengeType"
              value="Education"
              @blur = "challengeStore.challengeTypeChanged"
              :disabled = "!editMode"
          />
          <MDBRadio
              label="Food Safety and Food Security"
              name="challengeType"
              v-model="challengeType"
              value="Food Safety and Food Security"
              @blur = "challengeStore.challengeTypeChanged"
              :disabled = "!editMode"
          />
          <MDBRadio
              label="Water Quality"
              name="challengeType"
              v-model="challengeType"
              value="Water Quality"
              @change = "challengeStore.challengeTypeChanged"
              :disabled = "!editMode"
          />
          <MDBRadio
              label="Waste"
              name="challengeType"
              v-model="challengeType"
              value="Waste"
              @blur = "challengeStore.challengeTypeChanged"
              :disabled = "!editMode"
          />
          <MDBRadio
              label="Disaster Preparedness"
              name="challengeType"
              v-model="challengeType"
              value="Disaster Preparedness"
              @blur = "challengeStore.challengeTypeChanged"
              :disabled = "!editMode"
          />
          <MDBRadio
              label="Economic Development and Zoning "
              name="challengeType"
              v-model="challengeType"
              value="Economic Development and Zoning "
              @blur = "challengeStore.challengeTypeChanged"

              :disabled = "!editMode"
          />
          <MDBRadio
              label="Other:"
              name="challengeType"
              v-model="challengeType"
              value="Other"
              @blur = "challengeStore.challengeTypeChanged"
              :disabled = "!editMode"
          />
            <MDBInput v-if="challengeType==='Other'" label=" " v-model="challengeTypeOther"
                      @blur = "challengeStore.challengeTypeOtherChanged()"
                      :disabled = "!editMode"
            />
        </div>
    </div>
        <div class="sidebar-instructions p-4">
          Challenges should be areas whether there is potential for impact on human wellbeing, increasing equity, and environmental sustainability.
        </div>
      </div>


    </div>


  <router-view />
</template>

<script src="./_ChallengeType.js" lang="js"></script>
<style src="./_ChallengeType.less" lang="less" scoped></style>
