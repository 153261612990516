import { defineStore } from 'pinia'
import useWheelAnalysisQuery from "@/composables/useWheelAnalysisQuery";
import axios from "axios";
import {useAnalysisStore} from "@/stores/Setup/analysis";

export const useGoalAStore = defineStore('goalA', {
    state: () => {
        return {
            selectedTargets: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]
        }
    },
    getters: {
    },
    actions: {
        async fetchWheelAnalysis() {
            await useWheelAnalysisQuery()
            //console.log(data)
        },
        selectedTargetsChanged() {
            const analysisStore = useAnalysisStore()
            if (analysisStore.wheelAnalysisId  !== null && this.selectedTargets !== null) {  // TODO: add header with token
                const data = { "goal_a_selected_targets": [this.selectedTargets].toString()}
                //console.log(this.selectedTargets)
                //console.log("data:" + JSON.stringify(data))
                axios.put(process.env.VUE_APP_API_ROOT + 'api/v1/wheel_analyses/' + analysisStore.wheelAnalysisId +  '/goal_a_selected_targets', data).then(response => {
                    console.log(response)
                })
            }
        },
    },

    persist: true
})