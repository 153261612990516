<template>
    <div class="ImageView">

      <!--<div id="title-header-div" >
        <br>
        <h1>Images</h1>
        <br>
      </div>-->
      <div id="form-div">

        <div class="row justify-content-center align-items-center">
          <div class="col-md-10">
         <!-- <span>(Optional) Please share one or two images that capture the topic you are focusing on in a local and compelling way. While this is not essential for analysis, it will help to localize your visualization, making your presentations and conversations more compelling and relevant to your audience. (This can be added later or modified).</span>-->
            <span>(Optional) Upload and image that reflects this community and challenge</span>

            <br/><br/>
            <MDBFile :disabled = "!editMode" v-model="imageFile" label="Image" @change=uploadImage />
            <br/>
            <img :src="previewImage" class="preview-image"/>

            <br/>
            <br/>
            <br/>
          </div>

          <div class="sidebar-instructions px-4">Making your work locally relevant with images, stories and information is a critical SDG 360 Thinking Strategy. This image can be a starting point for you to localize all aspects of the work as you go forward.</div>
        </div>

        <!--<div class="prev-next">
          <a href="/additional-goals" class="btn btn-primary">Previous</a>
          <a href="/keywords" class="btn btn-primary">Next</a>
        </div>-->
      </div>
    </div>

  <router-view />

</template>

<script src="./_ImageView.js" lang="js"></script>
<style src="./_ImageView.less" lang="less" scoped></style>
